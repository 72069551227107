import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import styles from "./mainBlog.module.css";
import { NavLink, useNavigate } from "react-router-dom";

const MainBlog = ({mainBlogData})=> {

  const navigate = useNavigate()

  return (
    <div className="mt-4">
      <Container>
        <Row>
          <Col md={6}>
            <Image
              onClick={()=>navigate(mainBlogData.url)}
              fluid
              className={styles.img}
              src={mainBlogData.img}
              alt={mainBlogData.title}
            />
          </Col>
          <Col md={6}>
            <div className={styles.desc} >
              <div style={{display:"flex",alignItems:"center",gap:"10px",flexWrap: "wrap"}} >
              {
                mainBlogData.keywords.map((obj,index)=>(
                <Button key={index} className={styles.btn}>{obj}</Button>
                ))
              }
              </div>
              <h4 className={styles.heading}>
                {mainBlogData.title}
              </h4>
              <p>
                {mainBlogData.desc}
              </p>
              <NavLink to={`${mainBlogData.url}`} className={styles.readmore} >Read More</NavLink>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default MainBlog;
