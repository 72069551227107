import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { IoMdMail } from "react-icons/io";
import { IoLocationSharp } from "react-icons/io5";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import styles from "./footer.module.css"
import { NavLink } from 'react-router-dom';
import LogoFooter from '../LogoFooter';

const Footer = ()=> {
  

  return (
    <div 
    style={{
      minHeight: "auto", 
        backgroundImage: `url('/assets/bg-footer.WebP')`, 
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
    }}
    >
        <Container className='mt-5' >
            <Row>
                <Col xs={12} md={3} >
                <LogoFooter />
                </Col>
                <Col md={3} >
                    <div className={styles.service} >
                        <h5 style={{color:"#007fc7"}} >Services</h5>
                        <NavLink to='/business-it-solutions' className={({ isActive }) => isActive ? styles.active : styles.subLink}>Business IT Solutions</NavLink>
                        <NavLink to='/product-engineering' className={({ isActive }) => isActive ? styles.active : styles.subLink}>Product Engineering</NavLink>
                        <NavLink to='/mobility-digital-acceleration' className={({ isActive }) => isActive ? styles.active : styles.subLink}>Mobility & Digital Acceleration</NavLink>
                        <NavLink to='/technology-consulting' className={({ isActive }) => isActive ? styles.active : styles.subLink}>Technology Consulting</NavLink>
                        <NavLink to='/iot-development' className={({ isActive }) => isActive ? styles.active : styles.subLink}>IOT Development</NavLink>
                    </div>
                </Col>
                <Col xs={12} md={3} >
                <div className={styles.company} >
                    <h5 style={{color:"#007fc7"}} >Company</h5>
                    <NavLink to='/about-us' className={({ isActive }) => isActive ? styles.active : styles.subLink}>About Us</NavLink>
                    <NavLink to='/careers' className={({ isActive }) => isActive ? styles.active : styles.subLink}>Careers</NavLink>
                    <NavLink to='/blogs' className={({ isActive }) => isActive ? styles.active : styles.subLink}>Blog & News</NavLink>
                    <NavLink to='/contact-us' className={({ isActive }) => isActive ? styles.active : styles.subLink}>Contact Us</NavLink>
                    <NavLink to='/privacy-policy' className={({ isActive }) => isActive ? styles.active : styles.subLink}>Privacy Policy</NavLink>
                    <NavLink to='/terms-conditions' className={({ isActive }) => isActive ? styles.active : styles.subLink}>Terms and Conditions</NavLink>
                </div>
                </Col>
                <Col xs={12} md={3} >
                    <div className={styles.reachus} >
                    <h5 style={{color:"#007fc7"}} >Reach Us</h5>
                    <h6 style={{fontSize:"15px",marginRight:"4px"}} ><IoMdMail style={{color:"007fc7"}} /> info@orbiosolutions.com</h6>
                    <h6 style={{fontSize:"15px",marginRight:"4px"}} ><IoLocationSharp style={{color:"007fc7"}} /> Bengaluru, Karnataka, India</h6>
                    </div>
                    <div className={`${styles.findus} mt-4`} >
                <h6 className={styles.findheading} >Find us on social media</h6>
                <div style={{display:"flex",gap:"8px"}} >
                <a href="https://www.facebook.com/orbiosolutions" target="_blank" rel="noopener noreferrer" aria-label="facebook">
                  <FaFacebookSquare className={styles.icon} />
                </a>
                <a href="https://www.instagram.com/orbiosolutions/" target="_blank" rel="noopener noreferrer" aria-label="instagram">
                  <FaInstagramSquare className={styles.icon} />
                </a>
                <a href="https://www.linkedin.com/company/orbio-solutions-private-limited" target="_blank" rel="noopener noreferrer" aria-label="linkedin">
                  <FaLinkedin className={styles.icon} />
                </a>
                </div>
                </div>
                </Col>
            </Row>
            <Row className='mt-4'>
                <hr />
            </Row>
            <Row>
                <p className='text-center' >Copyright © 2022. All Rights Reserved.</p>
            </Row>
        </Container>
    </div>
  )
}

export default Footer;