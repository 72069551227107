import React from "react";
import styles from "./healthcare.module.css";
import CommonBannerImage from "../../components/CommonBannerImage";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ContactFormService from "../../components/ContactFormService";
import { Helmet } from "react-helmet-async";

const HealthCare = () => {

    const navigate = useNavigate()

  const softwareServices = [
    {
      id: "1",
      title:
        "Launch your healthcare platform faster:",
        desc:"Skip the complexities of coding and infrastructure with our readily available, customizable solutions."
    },
    {
      id: "2",
      title:
        "Tailor your platform to your vision:",
        desc:"Choose from a variety of modules and features to create a platform that perfectly aligns with your unique concept and patient needs."
    },
    {
      id: "3",
      title:
        "Scale with confidence:",
        desc:"Our solutions are built to handle growing user bases and evolving industry demands, ensuring your platform remains future-proof."
    },
  ];

  return (
    <>
    <Helmet>
        <meta
          name="keywords"
          content="Healthcare software technology consulting and IT solutions provider"
        />
      </Helmet>
      <CommonBannerImage backgroundImageURL="/assets/healthcare-bg.WebP">
        <div className={styles.textContainer}>
          <h1>HealthCare</h1>
          <h6>
          Orbio Solutions – your one-stop-shop for healthcare services, offering solutions to streamline
            operations, automate processes, and enhance patient interactions
          </h6>
        </div>
      </CommonBannerImage>
      <div className="mt-5">
        <Container>
          <div>
            <h4>Uncover limitless opportunities.</h4>
          </div>
          <Row className={`mt-3`} >
            <Col md={6}>
              <p>
              The healthcare sector is going through a
              transformation marked by the integration of cutting-
              edge technologies to improve patient care, streamline
              processes, and improve overall efficiency. For example,
              advanced medical imaging software fueled by Artificial
              Intelligence (AI) is revolutionizing diagnostics, offering
              faster and more accurate analyses that contribute to
              improved patient outcomes.
              </p>
              <p>
              As the healthcare sector embraces digital
              transformation, interoperability and data exchange
              standards are pivotal, promoting seamless
              communication between different healthcare systems
              and facilitating collaborative efforts among healthcare
              providers.
              </p>
            </Col>
            <Col md={6}>
              <Image
                fluid
                src="/assets/healthcareimg.WebP"
                alt="health-care"
                style={{ width: "100%", height: "350px",objectFit:"contain" }}
              />
            </Col>
          </Row>
          <Row className="mt-5">
            <h5>
            We Empower You to:
            </h5>
            {softwareServices.map((item) => (
              <Col key={item.id} md={4} className="d-flex">
                <div style={{ marginBottom: "12px", padding: "4px 4px 0px 0px", width: "100%" }}>
                  <Card className="d-flex flex-column h-100" style={{ border: "1px solid #ccc", backgroundColor: "#f2f2f2", borderRadius: "0px" }}>
                    <div style={{ padding: "20px 10px 10px 10px" }}>
                      <h6 style={{ fontSize: "16px", fontWeight: "bold" }}>{item?.title}</h6>
                      <p style={{ fontSize: "14px" }}>{item.desc}</p>
                    </div>
                  </Card>
                </div>
              </Col>
            ))}

          </Row>
          <Row className="mt-4">
            <h5>Our Services:</h5>
            <Col className={`${styles.ourService} ${styles.service1} ${styles.serviceCol}`} md={4}  >
            <div className={styles.servicefirstSection} >
                <img src="/assets/serviceicons/electronichealth.WebP" alt="img" style={{height:"auto",width:"25px",objectFit:"contain"}} />
                <p>Electronic Health Records (EHR)</p>
                </div>
                <div className={styles.serviceSecondSection} >
                <h6>Electronic Health Records (EHR):</h6>
                <p style={{fontSize:"12px",}} >Securely manage patient data, optimize clinical workflows, and deliver holistic care with our HIPAA-compliant and user-friendly EHR system.</p>
            </div>
            </Col>
            <Col className={`${styles.ourService} ${styles.service2} ${styles.serviceCol}`} md={4}  >
            <div className={styles.servicefirstSection} >
                <img src="/assets/serviceicons/patientengagement.WebP" alt="img" style={{height:"auto",width:"25px",objectFit:"contain"}} />
                <p>Patient Engagement Tools:</p>
                </div>
                <div className={styles.serviceSecondSection} >
                <h6>Patient Engagement Tools: </h6>
                <p style={{fontSize:"12px",}} >Foster active patient participation with self-service portals, medication reminders, and educational resources, leading to improved outcomes and satisfaction. </p>
            </div>
            </Col>
            <Col className={`${styles.ourService} ${styles.service3} ${styles.serviceCol}`} md={4}  >
            <div className={styles.servicefirstSection} >
                <img src="/assets/serviceicons/telehealth.WebP" alt="img" style={{height:"auto",width:"25px",objectFit:"contain"}} />
                <p>Telehealth Solutions</p>
                </div>
                <div className={styles.serviceSecondSection} >
                <h6>Telehealth Solutions:</h6>
                <p style={{fontSize:"12px",}} >Expand your reach, break geographical barriers, and deliver convenient access to care with our secure and integrated telehealth platform.</p>
            </div>
            </Col>
            <Col className={`${styles.ourService} ${styles.service4} ${styles.serviceCol}`} md={4}  >
            <div className={styles.servicefirstSection} >
                <img src="/assets/serviceicons/automatedhms.WebP" alt="img" style={{height:"auto",width:"25px",objectFit:"contain"}} />
                <p>Automated HMS workflow</p>
                </div>
                <div className={styles.serviceSecondSection} >
                <h6>Automated HMS workflow:</h6>
                <p style={{fontSize:"12px",}} >Automate the chaos of healthcare workflow, freeing you to empower your care - one patient, one smile, one life at a time.</p>
            </div>
            </Col>
            <Col className={`${styles.ourService} ${styles.service5} ${styles.serviceCol}`} md={4}  >
            <div className={styles.servicefirstSection} >
                <img src="/assets/serviceicons/dataanalyticshealth.WebP" alt="img" style={{height:"auto",width:"25px",objectFit:"contain"}} />
                <p>Data Analytics and Reporting</p>
                </div>
                <div className={styles.serviceSecondSection} >
                <h6>Data Analytics and Reporting:</h6>
                <p style={{fontSize:"12px",}} >Gain valuable insights into patient trends, resource utilization, and performance metrics to make data-driven decisions and optimize your platform.</p>
            </div>
            </Col>
            <Col className={`${styles.ourService} ${styles.service6} ${styles.serviceCol}`} md={4}  >
            <div className={styles.servicefirstSection} >
                <img src="/assets/serviceicons/customdevelopment.WebP" alt="img" style={{height:"auto",width:"25px",objectFit:"contain"}} />
                <p>Custom Development</p>
                </div>
                <div className={styles.serviceSecondSection} >
                <h6>Custom Development:</h6>
                <p style={{fontSize:"12px",}} >Need something specific? We offer bespoke software development services to bring your unique vision to life and address any complex challenges.</p>
            </div>
            </Col>
          </Row>
          <Row className="mt-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'black', color: 'white', padding: '20px' }}>
            <Col xs={12} sm={10}>
              <h6 className={styles.contactheading}>Contact Orbio Solutions today for a free consultation </h6>
            </Col>
            <Col xs={12} sm={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button className={styles.btn} onClick={() => navigate('/contact-us')}>Contact us now</Button>
            </Col>
          </Row>
          <Row className="mt-5">
            <h5>Why Choose Orbio Solutions?</h5>
          </Row>
          <Row className="mt-3 mb-3">
            <Col md={6} className={`${styles.service1} p-3`} >
                <div style={{display:"flex",justifyContent:"center",flexDirection:"column"}} >
                    <h6 style={{fontWeight:"bold"}} >Experience: </h6>
                    <p style={{fontSize:"15px"}} >We have a proven track record of helping healthcare startups and innovators build successful platforms.</p>
                </div>
            </Col>
            <Col md={6} className={`${styles.service3} p-3`} >
            <div style={{display:"flex",justifyContent:"center",flexDirection:"column"}} >
                    <h6 style={{fontWeight:"bold"}} >Expertise: </h6>
                    <p style={{fontSize:"15px"}} >Our team of healthcare professionals and software developers understand your needs and the industry landscape.</p>
            </div>
            </Col>
            <Col md={6} className={`${styles.service4} p-3`} >
            <div style={{display:"flex",justifyContent:"center",flexDirection:"column"}} >
                    <h6 style={{fontWeight:"bold"}} >Flexibility: </h6>
                    <p style={{fontSize:"15px"}} >We offer a variety of subscription plans and pricing options to fit your budget and stage of development.</p>
            </div>
            </Col>
            <Col md={6} className={`${styles.service6} p-3`} >
            <div style={{display:"flex",justifyContent:"center",flexDirection:"column"}} >
                    <h6 style={{fontWeight:"bold"}} >Support: </h6>
                    <p style={{fontSize:"15px"}} >We're here to guide you every step of the way, from conceptualization to launch and beyond. Ready to build the future of healthcare?</p>
            </div>
            </Col>
          </Row>
        </Container>
        <ContactFormService />
      </div>
    </>
  );
};

export default HealthCare;
