import React from "react";
import styles from "./contactusBannerSection.module.css";
import { Col, Container, Row } from "react-bootstrap";
import CommonBannerImage from "../CommonBannerImage";

const ContactUsBannerSection = ()=> {
  return (
    <CommonBannerImage backgroundImageURL="/assets/contact-us-background.WebP ">
      <div className={styles.textContainer}>
       <Container>
        <Row className={styles.mainRow} >
            <Col md={6} >
                <h1 className={styles.textContainerheading} >Contact Orbio</h1>
            </Col>
            <Col md={6} >
            <Row>
                <Col className="col-6" style={{display:"flex",justifyContent:"flex-start"}}>
                    <span className={styles.spanLeftContent}>Call Sales</span>
                </Col>
                <Col className="col-6" style={{display:"flex",justifyContent:"flex-end"}}>
                <span className={styles.spanRightContent}>+91 98867 67400</span>
                </Col>
            </Row>
                <hr />
            <Row>
            <Col className="col-6" style={{display:"flex",justifyContent:"flex-start"}}>
                    <span className={styles.spanLeftContent}>Email Sales</span>
                </Col>
                <Col className="col-6" style={{display:"flex",justifyContent:"flex-end"}}>
                <a className={styles.spanRightContent} href="mailto:info@orbiosolutions.com">info@orbiosolutions.com</a>
            </Col>
            </Row>
            <hr />
            
            </Col>
        </Row>
        <Row>
          <div style={{color:"white",display:"flex",justifyContent:"flex-end",marginTop:"5%",fontWeight: "bold"}} >
            <p style={{fontSize:"17px"}} >For HR and Careers please email to: <span><a style={{color:"white"}} href="mailto:hr@orbiosolutions.com">hr@orbiosolutions.com</a></span> </p>
          </div>
          </Row>
       </Container>
      </div>
    </CommonBannerImage>
  );
}

export default ContactUsBannerSection;