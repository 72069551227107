import React from "react";
import MainBlog from "../../components/MainBlog";
import BlogCards from "../../components/BlogCards";
import CommonBannerImage from "../../components/CommonBannerImage";
import styles from "./blogPage.module.css";
import { Col, Container, Row } from "react-bootstrap";

function BlogPage() {
  const AllBlogs = [
    {
      id: "0",
      title: "The Future of Robotics & AI",
      desc: "The cosmos of commonality has been terribly taking twists and turns according to the changing dynamics of technological breakthrough . It takes no insight to comprehend that humanity is now about to embrace a new and novel lifestyle that will be powered by algorithm, autonomous and al-driven technologies It Is inescapable reality that A I has grown out of the science fiction realm and is Playing out right now.",
      img: "/assets/Robotic-ai.WebP",
      url: "/blog/future-of-ai-and-robotics",
      keywords: ["Autonomous", "Digitization", "Nanotechnology", "Innovations"],
    },
    {
      id: "1",
      title: "Things To Be Aware of Before Starting  An Ecommerce Business ",
      img: "/assets/Ecommerce-business.WebP",
      url: "/blog/things-to-be-aware-of-before-starting-an-ecommerce-business",
      keywords: [
        "Due Diligence",
        "Market Research",
        "Competitor Analysis",
        "Business Growth",
      ],
    },
    {
      id: "2",
      title: "Big Data Helping Enterprises",
      img: "/assets/Big-Data.WebP",
      url: "/blog/plan-your-project-with-your-software",
      keywords: [
        "Business Intelligence",
        "Cloud Computing",
        "Customer Insights",
        "Data Analytics",
      ],
    },
    {
      id: "3",
      title: "Competitive Data Analytics",
      img: "/assets/data-analytics.WebP",
      url: "/blog/competitive-data-analytics",
      keywords: [
        "Artificial Intelligence",
        "Brand Growth",
        "Competitive Analysis",
        "Price comparison",
      ],
    },
    {
      id: "4",
      title: "Data-Driven Decision Making",
      img: "/assets/datadriven.WebP",
      url: "/blog/data-driven-decision-making",
      keywords: ["Analyzing", "Efficiency", "Leadership", "Innovation"],
    },
    {
      id: "5",
      title: "Driving Sales and Profits: Why your Retail Business Requires a Well-constructed Pricing Strategy?",
      img: "/assets/driving-sales-profits-bg.WebP",
      url: "/blog/driving-sales-and-profits-why-your-retail-business-requires-a-well-constructed-pricing-strategy?",
      keywords: ["Retail Pricing Strategy", "Price Tracking Software", "Pricing Analytics", "Real-time Pricing Insights"],
    },
    {
      id: "6",
      title: "Strategic Pricing Intelligence: Enhancing Business Strategies with Competitor Pricing Analytics",
      img: "/assets/strategic-pricing-intelligence-bg.WebP",
      url: "/blog/strategic-pricing-intelligence-enhancing-business-strategies-with-competitor-pricing-analytics",
      keywords: ["Retail Trends", "AI in Pricing", "Pricing Strategies", "Competitive Advantage"],
    },
    {
      id: "7",
      title: "The rising importance of price management and optimization for online retailers of today and tomorrow.",
      img: "/assets/risingpricemngt-bg.WebP",
      url: "/blog/the-rising-importance-of-price-management-and-optimization-for-online-retailers-of-today-and-tomorrow",
      keywords: ["Price Strategy", "Price Optimization", "Market Positioning", "Intelligent Pricing"],
    },
    {
      id: "8",
      title: "E-Commerce Insights: Trends, Challenges, and Winning Strategies",
      img: "/assets/e-commerce-insights-bg.WebP",
      url: "/blog/e-commerce-insights-trends-challenges-winning-strategies",
      keywords: ["E-commerce", "Online Shopping", "Global reach", "Retail"],
    },
    {
      id: "9",
      title: "Headless WooCommerce: Modern Age’s E-commerce",
      img: "/assets/headless-bg.WebP",
      url: "/blog/headless-woocommerce",
      keywords: ["Headless WooCommerce", "Modern E-commerce", "E-commerce architecture", "Omni-Channel capabilities"],
    }
  ];

  const mainBlogData = AllBlogs[0];
  const remainingBlogData = AllBlogs.slice(1);

  return (
    <div>
      <CommonBannerImage backgroundImageURL="/assets/blog-background.WebP">
        <div className={styles.textContainer}>
          <h1>Blogs</h1>
        </div>
      </CommonBannerImage>
      <MainBlog mainBlogData={mainBlogData} />

      <div className='mt-5' style={{ marginBottom: "60px" }}>
      <Container>
        <Row>
          {remainingBlogData?.map((obj) => (
            <Col md={3} key={obj.id}>
              <BlogCards obj={obj} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
    </div>
  );
}

export default BlogPage;
