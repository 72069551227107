import React from "react";
import styles from "./hoverCard.module.css";
import { Card, Col } from "react-bootstrap";

const HoverCard = ({ cardData }) => {
  return (
    <>
      {cardData?.map((card, index) => (
        <Col md={6} className="mb-3" key={index}>
          <div className={styles.cardContainer}>
            <Card className={styles.card}>
              <div className={styles.imageContainer}>
                <Card.Img
                  variant="top"
                  src={card.imgSrc}
                  alt={card.title}
                  className={styles.cardImg}
                />
              </div>
              <div className={styles.cardSection}>
                <h4 className={styles.cardTitle}>{card.title}</h4>
                <div>
                  <p>{card.description}</p>
                </div>
              </div>
            </Card>
          </div>
        </Col>
      ))}
    </>
  );
};

export default HoverCard;
