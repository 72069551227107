import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import styles from "./serviceListing.module.css";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const ServiceListing = () => {
  const navigate = useNavigate();

  const columnData = [
    {
      title: "Generative AI",
      url: "/generative-ai-service",
      description:
        "Redefine what's possible. Our expert team leverages the power of generative AI to create groundbreaking solutions tailored to your needs. Transforming ideas into innovative applications, we enable seamless automation, personalized experiences, and unparalleled efficiency. Discover how generative AI can drive your business forward with unmatched creativity and precision. Experience a new era of innovation with AI-driven strategies that set you apart.",
    },
    {
      title: "Data Engineering",
      url: "/generative-ai-service",
      description:
        "Our team of data engineering professionals converts raw data into efficient and dependable pipelines, ensuring you receive essential data for user and application needs. We handle seamless integration, optimize storage, and refine processing, making your data ecosystem not just operational but a valuable business asset. Eliminate data bottlenecks and experience a smooth information flow that drives your success. Our expertise guarantees a streamlined, robust data infrastructure that enhances your business capabilities.",
    },
    {
      title: "Data Visualization",
      url: "/data-artificial-intelligence",
      description:
        "Transform your data into impactful visuals that simplify complex information and support informed decisions. Professional data visualization services offer interactive dashboards and comprehensive reports, making it easier to uncover key insights and trends. By presenting data in clear, engaging graphics, these services enhance communication with stakeholders and facilitate strategic growth. Use powerful visualizations to turn data into a valuable asset for driving innovation and achieving your business objectives.",
    },
    {
      title: "ETL",
      url: "/data-artificial-intelligence",
      description:
        "Our skilled team excels in more than just data processing; we specialize in ETL (Extract, Transform, Load) processes. We transform raw data into valuable insights, streamlining the data flow for accuracy and easy access. Our expertise ensures that you receive the essential information needed to boost efficiency, enhance customer satisfaction, and achieve a competitive edge. With our ETL services, you'll gain the critical insights necessary for your business's success.",
    },
  ];

  return (
    <div className={`${styles.backgroundImageRow} mt-5`}>
      <Container fluid>
        <Row>
          {columnData.map((item, index) => (
            <Col
              md={3}
              key={index}
              className={`${styles.mainColumn}`}
              style={{ position: "relative" }}
            >
              <Row>
                <Col md={12} className={`${styles.columnDivider}`}>
                  <h4 className={styles.heading}>{item.title}</h4>
                  <p className={`${styles.desc} ${styles.overflowScroll}`}>
                    {item.description}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className={styles.btncontainer}>
                    <Button
                      onClick={() => navigate(item?.url)}
                      className={styles.viewbtn}
                    >
                      VIEW MORE <IoIosArrowForward />
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default ServiceListing;
