import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import styles from "./contactUsFormSection.module.css";
import ContactForms from "../ContactForms";

const ContactUsFormSection = () => {
  return (
    <div className="mt-5">
      <Container>
        <Row>
          <Col md={6}>
            <div>
              <Container>
                <Row>
                  <div>
                    <h1 className={styles.headingText}>Contact Sales</h1>
                    <p
                      style={{ color: "#6d6d6d", lineHeight: "30px" }}
                      className="mt-3"
                    >
                      Some of the most impactful products and solutions started
                      with a conversation. Let’s talk…
                    </p>
                  </div>
                  <div className={`${styles.iconContainer} mt-3`}>
                    <FaPhoneAlt style={{ fontSize: "20px" }} />
                    <h6 style={{ fontSize: "20px", color: "#6d6d6d" }}>
                      +91 98867 67400
                    </h6>
                  </div>
                  <div className={styles.iconContainer}>
                    <MdEmail style={{ fontSize: "20px" }} />
                    <a
                      className={styles.link}
                      href="mailto:info@orbiosolutions.com"
                    >
                      info@orbiosolutions.com
                    </a>
                  </div>
                  <div className={styles.iconContainer}>
                    <p style={{ fontSize: "14px" }}>
                      For HR and Careers please email to:{" "}
                      <span>
                        <a
                          style={{ color: "black" }}
                          href="mailto:hr@orbiosolutions.com"
                        >
                          hr@orbiosolutions.com
                        </a>
                      </span>{" "}
                    </p>
                  </div>
                </Row>
              </Container>
            </div>
          </Col>
          <Col md={6}>
            <div>
              <Container>
                <Row>
                  <div>
                    <h2 className={styles.formHeadingText}>
                      Products and Solutions Inquiries
                    </h2>
                    <p className={styles.descText}>
                      Have a question about purchasing a product or solution
                      that you are interested in? We’re here to help.
                    </p>
                  </div>
                </Row>
                <Row className="mb-5">
                  <ContactForms />
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactUsFormSection;
