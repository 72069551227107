import { useContext, useState } from "react";
import styles from "./navcanvas.module.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import { HamburgerContext } from "../../context/hamburgerState";
import { RxCross2 } from "react-icons/rx";
import { BsDash } from "react-icons/bs";

import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { FaAngleDown, FaAngleUp } from "react-icons/fa";

const NavCanvas = ()=> {
  const { hamburgerOpen, toggleHamburger } = useContext(HamburgerContext);

  const [servicesOpen, setServicesOpen] = useState(false);
  const [productsOpen, setProductsOpen] = useState(false);
  const [resourceOpen, setResourceOpen] = useState(false);
  const [industryOpen, setIndustry] = useState(false);

  const handleClose = () => {
    toggleHamburger();
  };

  const handleServicesClick = () => {
    setServicesOpen(!servicesOpen);
  };
  const handleProductsClick = () => {
    setProductsOpen(!productsOpen);
  };
  const handleResourcesClick = () => {
    setResourceOpen(!resourceOpen);
  };
  const handleIndustriesClick = () => {
    setIndustry(!industryOpen);
  };

  return (
    <>
      <Offcanvas
        show={hamburgerOpen}
        onHide={handleClose}
        style={{ width: "100vw" }}
        placement="end"
      >
        <Offcanvas.Header
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div onClick={handleClose}>
            <span className={`${styles.pointerCursor} ${styles.closeBtn}`}>
              CLOSE <RxCross2 className={`${styles.crossIcon}`} />
            </span>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {/* <div> */}
          <Container className={`${styles.navlinks}`}>
            <Row>
              <Col>
                <NavLink
                  onClick={handleClose}
                  to="/"
                  className={({ isActive }) =>
                    `${isActive ? `${styles.active}` : ""}`
                  }
                >
                  Home
                </NavLink>
              </Col>
            </Row>
            <Row>
              <Col>
                <NavLink
                  onClick={handleClose}
                  to="/about-us"
                  className={({ isActive }) =>
                    `${isActive ? `${styles.active}` : ""}`
                  }
                >
                  About Us
                </NavLink>
              </Col>
            </Row>
            <Row>
              <Col>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h6
                    style={{ fontSize: "18px" }}
                    className={`${styles.navLink}`}
                  >
                    Our Services
                  </h6>
                  <span
                    onClick={handleServicesClick}
                    className={`${styles.pointerCursor} ${styles.iconBorder}`}
                  >
                    {servicesOpen ? <FaAngleUp /> : <FaAngleDown />}
                  </span>
                </div>
                <div
                  className={`${styles.serviceSubItems} ${
                    servicesOpen ? styles.showSubItems : ""
                  }`}
                >
                  <NavLink
                    onClick={handleClose}
                    to="/business-it-solutions"
                    className={({ isActive }) =>
                      `${isActive ? `${styles.active}` : ""} ${styles.subItem}`
                    }
                  >
                    Business IT Solutions
                  </NavLink>
                  <NavLink
                    onClick={handleClose}
                    to="/product-engineering"
                    className={({ isActive }) =>
                      `${isActive ? `${styles.active}` : ""} ${styles.subItem}`
                    }
                  >
                    Product Engineering
                  </NavLink>
                  <NavLink
                    onClick={handleClose}
                    to="/mobility-digital-acceleration"
                    className={({ isActive }) =>
                      `${isActive ? `${styles.active}` : ""} ${styles.subItem}`
                    }
                  >
                    Mobility & Digital Acceleration
                  </NavLink>
                  <NavLink
                    onClick={handleClose}
                    to="/comprehensive-ai-data"
                    className={({ isActive }) =>
                      `${isActive ? `${styles.active}` : ""} ${styles.subItem}`
                    }
                  >
                    Comprehensive AI & Data Solutions
                  </NavLink>
                  <div>
                    <BsDash />
                    <NavLink
                      onClick={handleClose}
                      to="/data-artificial-intelligence"
                      className={({ isActive }) =>
                        `${isActive ? `${styles.active}` : ""} ${
                          styles.subItem
                        }`
                      }
                    >
                      Data & Artificial Intelligence
                    </NavLink>
                  </div>
                  <div>
                    <BsDash />
                    <NavLink
                      onClick={handleClose}
                      to="/generative-ai-service"
                      className={({ isActive }) =>
                        `${isActive ? `${styles.active}` : ""} ${
                          styles.subItem
                        }`
                      }
                    >
                      Generative AI Service
                    </NavLink>
                  </div>
                  <NavLink
                    onClick={handleClose}
                    to="/cloud-managed-services"
                    className={({ isActive }) =>
                      `${isActive ? `${styles.active}` : ""} ${styles.subItem}`
                    }
                  >
                    Cloud Managed Services
                  </NavLink>
                  <NavLink
                    onClick={handleClose}
                    to="/technology-consulting"
                    className={({ isActive }) =>
                      `${isActive ? `${styles.active}` : ""} ${styles.subItem}`
                    }
                  >
                    Technology Consulting
                  </NavLink>
                  <NavLink
                    onClick={handleClose}
                    to="/digital-security"
                    className={({ isActive }) =>
                      `${isActive ? `${styles.active}` : ""} ${styles.subItem}`
                    }
                  >
                    Digital Security
                  </NavLink>
                  <NavLink
                    onClick={handleClose}
                    to="/iot-development"
                    className={({ isActive }) =>
                      `${isActive ? `${styles.active}` : ""} ${styles.subItem}`
                    }
                  >
                    IOT Development
                  </NavLink>
                  <NavLink
                    onClick={handleClose}
                    to="/quality-engineering"
                    className={({ isActive }) =>
                      `${isActive ? `${styles.active}` : ""} ${styles.subItem}`
                    }
                  >
                    Quality Engineering
                  </NavLink>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h5
                    style={{ fontSize: "18px" }}
                    className={`${styles.navLink}`}
                  >
                    Products
                  </h5>
                  <span
                    onClick={handleProductsClick}
                    className={`${styles.pointerCursor} ${styles.iconBorder}`}
                  >
                    {productsOpen ? <FaAngleUp /> : <FaAngleDown />}
                  </span>
                </div>
                <div
                  className={`${styles.serviceSubItems} ${
                    productsOpen ? styles.showSubItems : ""
                  }`}
                >
                  <NavLink
                    onClick={handleClose}
                    to="/ottixhow"
                    className={({ isActive }) =>
                      `${isActive ? `${styles.active}` : ""} ${styles.subItem}`
                    }
                  >
                    OttixHow
                  </NavLink>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h5
                    style={{ fontSize: "18px" }}
                    className={`${styles.navLink}`}
                  >
                    Resorces
                  </h5>
                  <span
                    onClick={handleResourcesClick}
                    className={`${styles.pointerCursor} ${styles.iconBorder}`}
                  >
                    {resourceOpen ? <FaAngleUp /> : <FaAngleDown />}
                  </span>
                </div>
                <div
                  className={`${styles.serviceSubItems} ${
                    resourceOpen ? styles.showSubItems : ""
                  }`}
                >
                  <NavLink
                    onClick={handleClose}
                    to="/blogs"
                    className={({ isActive }) =>
                      `${isActive ? `${styles.active}` : ""} ${styles.subItem}`
                    }
                  >
                    Blogs
                  </NavLink>
                  <NavLink
                    onClick={handleClose}
                    to="/info-graphics"
                    className={({ isActive }) =>
                      `${isActive ? `${styles.active}` : ""} ${styles.subItem}`
                    }
                  >
                    Info Graphics
                  </NavLink>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h5
                    style={{ fontSize: "18px" }}
                    className={`${styles.navLink}`}
                  >
                    Industries
                  </h5>
                  <span
                    onClick={handleIndustriesClick}
                    className={`${styles.pointerCursor} ${styles.iconBorder}`}
                  >
                    {industryOpen ? <FaAngleUp /> : <FaAngleDown />}
                  </span>
                </div>
                <div
                  className={`${styles.serviceSubItems} ${
                    industryOpen ? styles.showSubItems : ""
                  }`}
                >
                  <NavLink
                    onClick={handleClose}
                    to="/blogs"
                    className={({ isActive }) =>
                      `${isActive ? `${styles.active}` : ""} ${styles.subItem}`
                    }
                  >
                    Blogs
                  </NavLink>
                  <NavLink
                    onClick={handleClose}
                    to="/info-graphics"
                    className={({ isActive }) =>
                      `${isActive ? `${styles.active}` : ""} ${styles.subItem}`
                    }
                  >
                    Info Graphics
                  </NavLink>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <NavLink
                  onClick={handleClose}
                  to="/contact-us"
                  className={({ isActive }) =>
                    `${isActive ? `${styles.active}` : ""}`
                  }
                >
                  Contact Us
                </NavLink>
              </Col>
            </Row>
            <Row>
              <Col>
                <NavLink
                  onClick={handleClose}
                  to="/careers"
                  className={({ isActive }) =>
                    `${isActive ? `${styles.active}` : ""}`
                  }
                >
                  Careers
                </NavLink>
              </Col>
            </Row>
          </Container>
          {/* </div> */}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default NavCanvas;
