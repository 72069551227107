import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import styles from './productVideoSection.module.css';
import { useNavigate } from 'react-router-dom';

const ProductVideoSection = ()=> {
  
  const navigate = useNavigate()
  
  return (
    <div className="mt-5 pb-5 pt-5" style={{background:"#282c33"}} >
      <Container>
        <Row>
          <Col className={styles.firstContainer} md={6}>
            <div>
              <h2 style={{ color: 'white', fontWeight: 'bold' }}>
              Why OttixHow
              </h2>
              <ul className={styles.orderList}>
                <li>Maximise product sales and customer satisfaction through pricing that is backed by intelligent data and informed decision-making</li>
                <li>Get first-hand competitor information to perfectly price your products aligned with your dynamic pricing strategies</li>
                <li>Benefit from deep-rooted product-level competitor price comparisons that tell you how exactly your product price point aligns in the race</li>
                <li>Focus on improving your under-performing products and optimising your high-performing products</li>
                <li>Dissect products by analysing ratings, reviews and descriptions to uncover excellence in insights</li>
              </ul>
              <div style={{ textAlign: 'center' }}>
                <Button onClick={()=>navigate('/contact-us')} className={styles.btn}>Try it now</Button>
              </div>
            </div>
          </Col>
          <Col md={6} >
          <img
              src="/assets/ottixhow-info1.WebP"
              alt="img"
              style={{ height: "auto", maxWidth: "100%", maxHeight: "100%" }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ProductVideoSection;
