import React, { useEffect, useState } from "react";
import styles from "./retail.module.css";
import CommonBannerImage from "../../components/CommonBannerImage";
import { Button, Col, Container, Image, Row, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ContactFormService from "../../components/ContactFormService";
import { IoIosArrowRoundForward } from "react-icons/io";
import CaseStudyDialog from "../../components/CaseStudyDialog";
import { Helmet } from "react-helmet-async";

const Retail = () => {
  const navigate = useNavigate();

  const serviceSections = [
    {
      title: "Streamlining Operations:",
      items: [
        "Inventory management solutions for accurate ordering and forecasting.",
        "Point-of-sale systems for faster checkout and improved data capture.",
        "Supply chain management tools for efficient logistics and vendor collaboration.",
        "Automation solutions to reduce manual tasks and boost workforce productivity.",
      ],
    },
    {
      title: "Enhancing Customer Experiences:",
      items: [
        "Personalized marketing campaigns to engage customers and drive sales.",
        "Loyalty programs to reward repeat business and build brand loyalty.",
        "Omnichannel solutions for seamless shopping experiences across physical and online channels.",
        "Data analytics for understanding customer behavior and optimizing engagement strategies.",
      ],
    },
    {
      title: "Driving Profitable Growth:",
      items: [
        "Business intelligence tools for data-driven decision making.",
        "Financial management solutions for better control over costs and investments.",
        "Performance dashboards for real-time insights into key metrics.",
        "Consulting services to help you define your digital roadmap and achieve long-term success.",
      ],
    },
  ];

  let caseStudy = {
    id: "0",
    heading: "Building a successful E-commerce portal",
    desc: "How Orbio Solutions Built StoreE’s Feature-rich E-Portal through Specialized Development and Testing",
    img: "/assets/storee-screen.WebP",
    url: "/case-study/storee-feature-rich-e-portal-through-specialized-development-and-testing",
  };

  const [modalShow, setModalShow] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    const storedFormSubmitted = localStorage.getItem("formSubmitted");
    setIsFormSubmitted(storedFormSubmitted);
  }, []);

  const handleOpenModal = () => {
    if (isFormSubmitted) {
      navigate(caseStudy?.url);
    } else {
      setModalShow(true);
    }
  };
  let caseStudyLength = caseStudy ? Object.keys(caseStudy)?.length : 0;

  return (
    <>
    <Helmet>
        <meta
          name="keywords"
          content="Retail software technology consulting and IT solutions provider"
        />
      </Helmet>
      {!isFormSubmitted && (
        <CaseStudyDialog
          modalNavigateUrl={caseStudy?.url}
          modalShow={modalShow}
          setModalShow={setModalShow}
        />
      )}
      <CommonBannerImage backgroundImageURL="/assets/retail-bg.WebP">
        <div className={styles.overlay}></div>
        <div className={styles.textContainer}>
          <h1>Retail</h1>
          <h6>
            Elevate your analytics and outsmart the competition with retail
            services that redefine the way you understand and leverage data
          </h6>
        </div>
      </CommonBannerImage>
      <div className="mt-5">
        <Container>
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col md={6}>
              <p>
                The retail sector within the software industry is undergoing
                significant transformation, driven by the demand for efficient,
                data-driven solutions that enhance customer experiences and
                streamline operations. Retail software applications such as
                Point of Sale (POS) systems, inventory management, customer
                relationship management (CRM), e-commerce platforms, and
                analytics tools are some of the key.
              </p>
              <p>
                One of the notable trends in the retail software industry is the
                integration of Artificial Intelligence (AI) and data analytics
                to optimize various aspects of the retail supply chain. This
                includes demand forecasting, personalized marketing, and
                inventory optimization, leading to improved efficiency and cost
                savings. Additionally, Customer Relationship Management (CRM)
                software helps retailers build and maintain strong connections
                with their customers by analyzing purchase patterns and
                preferences.
              </p>
            </Col>
            <Col md={6}>
              <Image
                fluid
                src="/assets/retailimg.WebP"
                alt="retail"
                style={{ width: "100%", height: "350px", objectFit: "contain" }}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <p>
              In today's dynamic retail landscape, staying ahead of the curve
              requires more than offering great products. This is why we at
              Orbio Solutions offer a comprehensive suite of software services
              specifically designed to empower retail businesses of all sizes.
              Whether you're a small boutique or a leading chain, we can help
              you optimize your operations, enhance customer experiences, and
              achieve your business goals.
            </p>
          </Row>
          <Row className="mt-3">
            {serviceSections.map((section, index) => (
              <Col key={index} md={4} className="d-flex mb-1">
                <Card
                  className="flex-column h-100"
                  style={{ background: "#f2f2f2" }}
                >
                  <Card.Body className="h-100">
                    <Card.Title style={{ fontWeight: "bold" }}>
                      {section.title}
                    </Card.Title>
                    <ul>
                      {section.items.map((item, itemIndex) => (
                        <li key={itemIndex}>{item}</li>
                      ))}
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
          <Row className="mt-3" >
              <Col xs={12} className="d-flex mb-1">
                <Card style={{ background: "#f2f2f2" }}>
                  <Card.Body className="h-100">
                      <h6>At Orbio, we empower businesses of all sizes to thrive in the ever-evolving world of e-commerce. Our comprehensive services encompass the entire development lifecycle, ensuring you get a solution tailored to your specific needs.</h6>
                      <p>Here's how we can help you:</p>
                      <p><span style={{ fontWeight: "bold" }} >Custom E-commerce Websites:</span> We build online stores, marketplaces, subscription box platforms, food delivery apps, and auction sites – tailored to your unique business model and target audience.</p>
                      <p><span style={{ fontWeight: "bold" }} >Seamless User Experience:</span> We prioritize user-friendly interfaces and intuitive navigation, ensuring a smooth and enjoyable journey for your customers, from product discovery to checkout.</p>
                      <p><span style={{ fontWeight: "bold" }} >E-commerce Integration:</span> Thinking of adding e-commerce functionality to your existing platform? We seamlessly integrate robust online shopping features without compromising your current website's structure.
                        Whether you're a startup venturing into the online space or a seasoned business seeking to revamp your digital storefront, we offer end-to-end support.  Our team of experts will guide you through every step, from concept development and design to implementation and ongoing maintenance.</p>
                        <p>Partner with us to elevate your e-commerce journey and unlock your full online sales potential.</p>
                  </Card.Body>
                </Card>
              </Col>
          </Row>
          <Row className={`mt-4 ${styles.contactusBanner}`}>
            <Col xs={12} sm={10}>
              <h6 className={styles.contactheading}>
                Ready to transform your retail business?{" "}
              </h6>
            </Col>
            <Col
              xs={12}
              sm={2}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                className={styles.btn}
                onClick={() => navigate("/contact-us")}
              >
                Contact us now
              </Button>
            </Col>
          </Row>

          <Row className="mt-5">
            <h5>Why Choose Orbio Solutions?</h5>
          </Row>
          <Row className="mt-3 mb-3">
            <Col md={6} className={`${styles.service1} p-3`}>
              <div className={styles.chooseSection}>
                <h6>Experience: </h6>
                <p>
                  We have a proven track record of helping retailers succeed.
                </p>
              </div>
            </Col>
            <Col md={6} className={`${styles.service3} p-3`}>
              <div className={styles.chooseSection}>
                <h6>Expertise: </h6>
                <p>
                  Our team of software developers and professionals bring the
                  expertise and demonstrated success to build solutions that
                  align with your objectives and requirements.
                </p>
              </div>
            </Col>
            <Col md={6} className={`${styles.service4} p-3`}>
              <div className={styles.chooseSection}>
                <h6>Flexibility: </h6>
                <p>We offer a variety of pricing options to fit your budget.</p>
              </div>
            </Col>
            <Col md={6} className={`${styles.service6} p-3`}>
              <div className={styles.chooseSection}>
                <h6>Support: </h6>
                <p>
                  We're here to guide you every step of the way, from conception
                  to and beyond.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        {caseStudy && (
          <div className={`p-4 pt-5 ${styles.caseStudyContainer}`} >
            <Container>
              <Row>
                <Col md={7}>
                  <div>
                    <h2>{caseStudy?.heading}</h2>
                    <p>{caseStudy?.desc}</p>
                    <div
                      onClick={handleOpenModal}
                      className={`pt-md-5 ${styles.singleCarouselCircleSection}`}
                    >
                      <span className={styles.cta}>
                        <IoIosArrowRoundForward
                          color="black"
                          fontWeight="bold"
                          className={styles.singlCarouselRightArrow}
                        />
                        <span>Read full case study</span>
                      </span>
                    </div>
                  </div>
                </Col>
                <Col md={5}>
                  <div
                    style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                    <img alt="img" src={caseStudy?.img} className="mt-md-1"
                      style={{ height: "auto", width: "100%", objectFit: "contain" }}/>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}
        <ContactFormService caseStudyLength={caseStudyLength} />
      </div>
    </>
  );
};

export default Retail;
