import React from "react";
import styles from "./productEngineeringCaseStudy.module.css";
import { Container, Row } from "react-bootstrap";
import CommonBannerImage from "../../../components/CommonBannerImage";

const ProductEngineeringCaseStudy = () => {

  const orbiosolutions = "https://www.orbiosolutions.com";

  return (
    <>
      <CommonBannerImage backgroundImageURL="/assets/product-engineering-bg.WebP">
        <div className={styles.overlay}></div>
        <div className={styles.textContainer}>
          <h1>Case-Study: myFirmData</h1>
        </div>
      </CommonBannerImage>
      <div className="mt-5">
        <Container>
          <Row>
            <h4 className="fw-bold">
              Revolutionizing Your Clio Experience Through the Power of Data
              Tracing, Troubleshooting and Scheduler Advancements.
            </h4>
            <h6 className="fw-bold mt-3">Company Profile:</h6>
            <p>
              myFirmData specializes in tailored reporting tools for Clio to
              empower legal professionals. myFirmData offers a custom reporting
              tool that allows to easily build reports using over 250 fields
              including all of your custom fields over the entire life of your
              firm. The company’s user-friendly interface streamlines reporting
              with consolidated views and customizable inbuilt reports, enabling
              simplified workflows and informed decision-making.
            </p>
            <h6 className="fw-bold">Situation:</h6>
            <p>
              When it comes to process engineering, law firms heavily rely on
              data-driven insights to streamline their operations, allocate
              resources effectively, and make well-informed decisions.
              myFirmData was challenged in generating detailed, filtered, and
              customizable reports. This created a significant limitation in
              harnessing the full potential of data for actionable insights.
            </p>
            <h6 className="fw-bold">Task:</h6>
            <p>
              One of the critical challenges faced by myFirmData in integrating
              with Clio for data retrieval revolved around the sensitivity and
              security of customer data. Another challenge was in implementing a
              scheduler for background report generation and emailing in
              Excel/PDF format. These translated to the need for implementing
              solutions for process improvements.
            </p>
            <h6 className="fw-bold">Action:</h6>
            <p>
              Orbio Solutions, a solution service provider navigated the
              complexity of the existing system to gain familiarity.
              Implementing data tracing tools and established troubleshooting
              methodologies allowed Orbio Solutions to pinpoint the source of
              customer-facing issues for myFirmData effectively. Superior root
              cause analysis meant clear identification of threats, constraints
              and solutions.
            </p>
            <p>
              By defining the system requirements and job execution, by studying
              the system architecture and by creating a job scheduler workflow,
              a robust scheduler was developed to automatically generate reports
              at specified intervals. Integrating the job scheduler with email
              services allowed seamless dispatch of Excel/PDF reports as
              attachments (.ng format) to prevent data inconsistencies and
              formatting issues.
            </p>
            <p>
              This way, myFirmData was able to enhance their process engineering
              practices.
            </p>
            <h6 className="fw-bold">Result:</h6>
            <p>
              The process engineering services we provided ensured our client’s
              establishment of a superior cloud-based process management.
            </p>
            <h6 className="fw-bold">Benefits,</h6>
            <li>
              Post implementation, myFirmData benefitted from increased sales
              and was able to expand its customer base by 50%
            </li>
            <li>
              As the results were evident, myFirmData increased the scope from
              working on one project (initial agreement) to handling three
              different projects
            </li>
            <li>
              Our work was helpful in explaining the advantages of their process
              as myFirmData expands its geographic reach to Canada.
            </li>

            <p className="mt-4">
              Other benefits included i) time savings, ii) cost savings, iii)
              improved collaboration, and iv) improved process control.
            </p>
            <p className="mt-4">
              Are you looking to strengthen and automate your customer’s data
              and workflow journey? Visit our website today at <a href={orbiosolutions} target="_blank" rel="noreferrer" >www.orbiosolutions.com</a> or email us at <a href="mailto:info@orbiosolutions.com">info@orbiosolutions.com</a> to
              get started on creating a tailored solution for your business!
            </p>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ProductEngineeringCaseStudy;
