import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import styles from "./measurable.module.css";

const MeasurableSuccess = ()=> {
  return (
    <div className="mt-5" >
      <Container>
      <Row>
        <Col>
          <div>
            <img alt="img" src="/assets/ottixhow-logo.WebP" className={styles.ottixlogo} />
          </div>
        </Col>
      </Row>
      <Row>
      <div className={styles.ottixhowTitle}>
        <p>Powered By AI And Driven By Data Analytics To Transform Retail Business At Speed. <span><a className={styles.viewMore} href="https://ottixhow.com/" target="_blank" rel="noopener noreferrer" >Explore More...</a></span></p>
      </div>
      </Row>
      <Row>
      <Col md={6} style={{display:"flex",flexDirection:"column",gap:"12px"}} >
        <Row style={{display:"flex",gap:"20px"}} >
          <Col xs={12} style={{display:"flex"}} >
            <Col xs={4} className={styles.singleCircleSection} >
            <div className={styles.roundedcirclecontainer}>
                    <Image src="/assets/analytics.WebP" alt="product mapping" className={styles.imgIcons} />
                  </div>
                    <h3 className={styles.iconText} >AI–powered automatic product mapping</h3>
            </Col>
            <Col xs={4} className={styles.singleCircleSection}>
            <div className={styles.roundedcirclecontainer}>
                    <Image src="/assets/tax.WebP" alt="historical price tracking" className={styles.imgIcons} />
                </div>
                <h3 className={styles.iconText} >Real-time & historical price tracking</h3>
            </Col>
            <Col xs={4} className={styles.singleCircleSection}>
            <div className={styles.roundedcirclecontainer}>
                    <Image src="/assets/data.WebP" alt="visualization" className={styles.imgIcons} />
                </div>
                <h3 className={styles.iconText} >Intuitive heat map & visualization</h3>
            </Col>
          </Col>
        <Col xs={12} style={{display:"flex"}} >
            <Col xs={4} className={styles.singleCircleSection} >
            <div className={styles.roundedcirclecontainer}>
                    <Image src="/assets/market-share.WebP" alt="Predictive analysis" className={styles.imgIcons} />
                </div>
                <h3 className={styles.iconText} >Predictive analysis</h3>
            </Col>
            <Col xs={4} className={styles.singleCircleSection}>
            <div className={styles.roundedcirclecontainer}>
                    <Image src="/assets/statistical-inference.WebP" alt="reports integration" className={styles.imgIcons} />
                </div>
                <h3 className={styles.iconText} >Seamless reports integration</h3>
            </Col>
            <Col xs={4} className={styles.singleCircleSection}>
            <div className={styles.roundedcirclecontainer}>
                    <Image src="/assets/import.WebP" alt="Data-import-export" className={styles.imgIcons} />
                </div>
                <h3 className={styles.iconText} >Data import & export</h3>
            </Col>
          </Col>
        </Row>
      </Col>
      <Col md={6} className={styles.desktopImgContainer}>
        <Image src="/assets/desktop.WebP" alt="dashboard" className={styles.desktopImg} />
      </Col>
      </Row>
      </Container>
    </div>
  );
}

export default MeasurableSuccess;
