import React, { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import styles from "./youtubesection.module.css";

import VideoPlayerLeftSection from "../VideoPlayerLeftSection";
import YoutubeVideoModal from "../YoutubeVideoModal";
import StripBanner from "../StripBanner";

const YoutubeSection = () => {
  const [youtubeModalOpen, setYoutubeModalOpen] = useState(false);

  return (
    <div>
      {youtubeModalOpen && (
        <YoutubeVideoModal
          youtubeModalOpen={youtubeModalOpen}
          setYoutubeModalOpen={setYoutubeModalOpen}
        />
      )}
      <Container className="mt-4">
        <Row className="mb-5">
          <Col xs={12}>
            <h3 className={styles.mainheading}>
              <span className={styles.redUnderline}>About</span>
              <span style={{ color: "#007fc7", marginLeft: "5px" }}>Orbio</span>
            </h3>
          </Col>
        </Row>
        <Row className="mb-3">
          <VideoPlayerLeftSection setYoutubeModalOpen={setYoutubeModalOpen} />
          <Col
            md={7}
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}
          >
            <div>
              <Card className={`${styles.singlecard} textJustify`}>
                <Card.Body>
                  <p className={styles.cardcontent}>
                    Orbio Solutions is at the forefront of the digital
                    transformation landscape, recognized as a premier digital
                    transformation company that partners with businesses to
                    deliver comprehensive IT and Data services. With a deep
                    understanding of the evolving digital ecosystem, we provide
                    customized solutions that address the unique challenges of
                    modern enterprises. Our commitment to excellence is
                    reflected in our ability to combine technology, data, and
                    design to create transformative outcomes.
                  </p>
                </Card.Body>
              </Card>
            </div>
            <div>
              <Card className={`${styles.singlecard} textJustify`}>
                <Card.Body>
                  <p className={styles.cardcontent}>
                    Our journey began with a vision to bridge the gap between
                    business needs and technology solutions. Over the years, we
                    have grown into a trusted IT and Data solution company that
                    collaborates with clients to unlock new opportunities and
                    drive sustainable growth. Our team of experts brings a
                    wealth of experience across diverse industries, enabling us
                    to offer insights and solutions that are both innovative and
                    practical. At Orbio Solutions, we believe that successful
                    digital transformation is not just about adopting new
                    technologies but about creating a seamless integration of
                    people, processes, and technology.
                  </p>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <StripBanner
              desc={`<p>
                As a leading IT services and solutions provider, our mission
                    is to empower businesses to scale and innovate in today’s
                    fast-paced digital world. We work closely with our clients
                    to understand their goals, challenges, and aspirations,
                    ensuring that our solutions are aligned with their vision.
                    Whether it's implementing cutting-edge technologies,
                    optimizing existing systems, or driving digital innovation,
                    Orbio Solutions is your partner in achieving digital
                    excellence. With a focus on agility, collaboration, and
                    customer satisfaction, we are committed to helping
                    businesses thrive in the digital.
              </p>
              `}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default YoutubeSection;
