import React, { useState, useEffect } from "react";
import styles from "./dynamicJobPage.module.css";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import CommonBannerImage from "../../components/CommonBannerImage";
import { GoArrowLeft } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const DynamicJobPage = ({ job }) => {
  const navigate = useNavigate();

  const [formState, setFormState] = useState({
    fullName: "",
    email: "",
    phone: "",
    coverLetter: "",
    fileList: [],
    isCheckboxChecked: false,
  });
  const [formErrors, setFormErrors] = useState({
    fullName: "",
    email: "",
    phone: "",
    coverLetter: "",
    fileList: " ",
    isCheckboxChecked: "",
  });
  const [inpFocused, setInpFocused] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const droppedFiles = e.dataTransfer.files;
    if (droppedFiles.length > 0) {
      setFormState((prevFormState) => ({
        ...prevFormState,
        fileList: [droppedFiles[0]],
      }));
    }
  };

  const handleFiles = (e) => {
    const selectedFiles = e.target.files;
    if (selectedFiles.length > 0) {
      setFormState((prevFormState) => ({
        ...prevFormState,
        fileList: [selectedFiles[0]],
      }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevFormState) => ({
      ...prevFormState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = () => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      isCheckboxChecked: !prevFormState.isCheckboxChecked,
    }));
  };

  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const hasErrors = Object.values(formErrors).some((error) => error !== "");

    if (!hasErrors) {
      setInpFocused(false);
      setLoading(true);
      try {
        const rsponse = await axios.post(
          "https://api.orbiosolutions.com/profile.php",
          {
            name: formState.fullName,
            email: formState.email,
            phone: formState.phone,
            cover_letter: formState.coverLetter,
            job: job?.designation,
            filename: formState?.fileList[0],
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setSuccessMsg("Your application have been submitted");
        setTimeout(() => {
          setInpFocused(false);
          setSuccessMsg("");
          setFormState({
            fullName: "",
            email: "",
            phone: "",
            coverLetter: "",
            fileList: [],
            isCheckboxChecked: false,
          });
          setFormErrors({
            fullName: "",
            email: "",
            phone: "",
            coverLetter: "",
            fileList: " ",
            isCheckboxChecked: "",
          });
        }, 600);
        setLoading(false);
      } catch (error) {
        setErrorMsg("Your application have been submitted");
        setTimeout(() => {
          setInpFocused(false);
          setErrorMsg("");
          setFormState({
            fullName: "",
            email: "",
            phone: "",
            coverLetter: "",
            fileList: [],
            isCheckboxChecked: false,
          });
          setFormErrors({
            fullName: "",
            email: "",
            phone: "",
            coverLetter: "",
            fileList: " ",
            isCheckboxChecked: "",
          });
          setLoading(false);
        }, 600);
      }
    }
  };

  useEffect(() => {
    if (!formState.fullName || !formState.fullName.trim()) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        fullName: "Please enter your Full Name",
      }));
    } else if (formState.fullName !== formState.fullName.trim()) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        fullName: "Full Name should not start or end with spaces",
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        fullName: "",
      }));
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formState.email) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        email: "Please enter your Email",
      }));
    } else if (!emailPattern.test(formState.email)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        email: "Please enter a valid Email",
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    }

    const phonePattern = /^[0-9()+-]*$/;
    if (!formState.phone) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        phone: "Please enter your Phone Number",
      }));
    } else if (!phonePattern.test(formState.phone)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        phone: "Please enter a valid Phone Number",
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        phone: "",
      }));
    }

    if (!formState.coverLetter || !formState.coverLetter.trim()) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        coverLetter: "Please enter your Cover Letter",
      }));
    } else if (formState.coverLetter !== formState.coverLetter.trim()) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        coverLetter: "Cover Letter should not start or end with spaces",
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        coverLetter: "",
      }));
    }

    if (formState.fileList.length === 0) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        fileList: "Please upload a file",
      }));
    } else {
      const allowedFormats = ["pdf", "doc", "docx"];
      const invalidFiles = formState.fileList.filter((file) => {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        return !allowedFormats.includes(fileExtension);
      });

      if (invalidFiles.length > 0) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          fileList:
            "Invalid file format. Please upload a PDF, DOC, or DOCX file.",
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          fileList: "",
        }));
      }
    }

    if (!formState.isCheckboxChecked) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        isCheckboxChecked: "Please agree to the terms and conditions.",
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        isCheckboxChecked: "",
      }));
    }
  }, [formState]);

  return (
    <>
      <CommonBannerImage backgroundImageURL="/assets/career-bg.WebP">
        <div className={styles.overlay}></div>
        <div className={styles.textContainer}>
          <h1>{job?.designation}</h1>
        </div>
      </CommonBannerImage>
      <div className="mt-3">
        <Container>
          <Row>
            <Col xs={12} className="p-3">
              <Button
                onClick={() => navigate(-1)}
                variant="outline"
                className={styles.backArrow}
              >
                {" "}
                <GoArrowLeft /> back
              </Button>
            </Col>
            <Col md={6}>
              <div className={styles.jobContainer}>
                <h3
                  className="mb-4"
                  style={{ color: "#1b1d21", fontWeight: "bold" }}
                >
                  {job?.designation}
                </h3>
                <p>
                  <strong>Experience:</strong> <span>{job.experience}</span>
                </p>
                <p>
                  <strong>Skills:</strong>{" "}
                  <span>{job?.skills?.join(", ")}</span>
                </p>
                <p>
                  <strong>Location:</strong>{" "}
                  <span>{job?.locations?.join(", ")}</span>
                </p>
                <p>
                  <strong>Designation:</strong> <span>{job.designation}</span>
                </p>
                <p>
                  <strong>Job Type:</strong> <span>{job.jobType}</span>
                </p>
                {job?.jobpurpose ? (
                  <p>
                    <strong>Job Purpose:</strong> <span>{job.jobpurpose}</span>
                  </p>
                ) : null}
                <p>
                  <strong>Job Description:</strong>
                </p>
                <ul>
                  {Array.isArray(job?.jobDescription) && (
                    <div>
                      {job?.jobDescription?.length > 0 &&
                      typeof job.jobDescription[0] === "string" ? (
                        <ul>
                          {job.jobDescription.map((desc, index) => (
                            <li key={index}>{desc}</li>
                          ))}
                        </ul>
                      ) : (
                        job?.jobDescription?.map((section) => (
                          <div key={section.id}>
                            <h6 style={{color: "#726d6d", fontWeight: "bold"}} className="m-2" >{section.title}:</h6>
                            <ul>
                              {section.desc.map((item, index) => (
                                <li key={index}>{item}</li>
                              ))}
                            </ul>
                          </div>
                        ))
                      )}
                    </div>
                  )}
                </ul>
                <p>
                  <strong>Salary:</strong> <span>{job.salary}</span>
                </p>
                <p>
                  <strong>Benefits:</strong>{" "}
                  <span>{job?.benefits?.join(", ")}</span>
                </p>
                <p>
                  <strong>Schedule:</strong> <span>{job.schedule}</span>
                </p>
                <p>
                  <strong>Supplemental pay types:</strong>
                </p>
                <ul>
                  {job?.supplementalPayTypes.map((obj, index) => (
                    <li key={index}>{obj}</li>
                  ))}
                </ul>
                <p>
                  <strong>Contact:</strong> <span>{job.contact}</span>
                </p>
                <p>
                  <strong>Job Category:</strong> <span>{job.designation}</span>
                </p>
                <p>
                  <strong>Openings:</strong> <span>{job.openings}</span>
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div style={{ border: "1px solid #dddbda", padding: "20px" }}>
                <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
                  Apply for this position
                </h3>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="formGroupFullName">
                    <Form.Label className={styles.formLabel}>
                      Full Name{" "}
                      <span style={{ color: "red", fontSize: "20px" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      name="fullName"
                      value={formState.fullName}
                      onChange={handleInputChange}
                      className={styles.inpfield}
                      onFocus={() => setInpFocused(true)}
                      autoComplete="off"
                      type="text"
                    />
                    {formErrors.fullName && inpFocused && (
                      <p className={styles.errmsg}>{formErrors.fullName}</p>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGroupEmail">
                    <Form.Label className={styles.formLabel}>
                      Email{" "}
                      <span style={{ color: "red", fontSize: "20px" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      name="email"
                      value={formState.email}
                      onChange={handleInputChange}
                      className={styles.inpfield}
                      onFocus={() => setInpFocused(true)}
                      autoComplete="off"
                      type="email"
                    />
                    {formErrors.email && inpFocused && (
                      <p className={styles.errmsg}>{formErrors.email}</p>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGroupPhone">
                    <Form.Label className={styles.formLabel}>
                      Phone{" "}
                      <span style={{ color: "red", fontSize: "20px" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      name="phone"
                      value={formState.phone}
                      onChange={handleInputChange}
                      className={styles.inpfield}
                      onFocus={() => setInpFocused(true)}
                      autoComplete="off"
                      type="text"
                    />
                    {formErrors.phone && inpFocused && (
                      <p className={styles.errmsg}>{formErrors.phone}</p>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGroupCoverLetter">
                    <Form.Label className={styles.formLabel}>
                      Cover Letter{" "}
                      <span style={{ color: "red", fontSize: "20px" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      name="coverLetter"
                      value={formState.coverLetter}
                      onChange={handleInputChange}
                      className={styles.inpfield}
                      onFocus={() => setInpFocused(true)}
                      autoComplete="off"
                      as="textarea"
                      style={{ height: "100px" }}
                    />
                    {formErrors.coverLetter && inpFocused && (
                      <p className={styles.errmsg}>{formErrors.coverLetter}</p>
                    )}
                  </Form.Group>
                  <div className="mt-2" style={{ color: "#6d6d6d" }}>
                    Upload CV/Resume{" "}
                    <span style={{ color: "red", fontSize: "20px" }}>*</span>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <label
                        htmlFor="file-upload"
                        className={styles.labelforinput}
                      >
                        <div
                          className={`mt-2 ${styles.inputcard}`}
                          onDragOver={(e) => handleDragOver(e)}
                          onDrop={(e) => handleDrop(e)}
                        >
                          <img
                            src="/assets/upload-to-cloud.WebP"
                            alt="upload"
                            width="50px"
                          />
                          <div style={{ fontSize: "14px" }}>
                            Drag and drop files here or click to select files
                          </div>
                          <div className={styles.btnContainer}>Browse File</div>
                          <p style={{ fontSize: "12px" }}>
                            Supported formats: ".pdf, .doc, .docx"
                          </p>
                        </div>
                      </label>
                      <input
                        accept=".pdf, .doc, .docx"
                        type="file"
                        id="file-upload"
                        style={{ display: "none" }}
                        onChange={handleFiles}
                        onFocus={() => setInpFocused(true)}
                        onBlur={() => setInpFocused(false)}
                      />
                    </div>
                    {formState?.fileList?.length > 0 && (
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "10px",
                          fontSize: "13px",
                        }}
                      >
                        Uploaded File: {formState?.fileList[0].name}
                      </div>
                    )}
                    {formErrors.fileList && inpFocused && (
                      <p className={styles.errmsg}>{formErrors.fileList}</p>
                    )}
                  </div>

                  <div className="mt-3">
                    <input
                      type="checkbox"
                      checked={formState.isCheckboxChecked}
                      onChange={handleCheckboxChange}
                    />
                    <span
                      style={{
                        fontSize: "15px",
                        paddingLeft: "5px",
                        color: "#6d6d6d",
                      }}
                    >
                      By using this form you agree with the storage and handling
                      of your data by this website.{" "}
                      <span style={{ color: "red" }}>*</span>
                    </span>
                    {formErrors.isCheckboxChecked && inpFocused && (
                      <p className={styles.errmsg}>
                        {formErrors.isCheckboxChecked}
                      </p>
                    )}
                  </div>
                  <div className="mt-4">
                    <Button
                      type="submit"
                      disabled={
                        loading ||
                        (successMsg && successMsg.length > 0) ||
                        (errorMsg && errorMsg.length > 0) ||
                        Object.values(formErrors).some((error) => error !== "")
                      }
                    >
                      {loading ? "Submitting..." : "Submit"}
                    </Button>
                    {successMsg && successMsg?.length > 0 && (
                      <p className="text-center" style={{ fontSize: "16px" }}>
                        {successMsg}
                      </p>
                    )}
                    {errorMsg && errorMsg?.length > 0 && (
                      <p className="text-center" style={{ fontSize: "16px" }}>
                        {errorMsg}
                      </p>
                    )}
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default DynamicJobPage;
