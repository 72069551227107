import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import styles from "./resourceDropdown.module.css"

const ResourcesDropdown = ()=> {
  return (
    <Container fluid>
            <Row>
            <div style={{
         background: "linear-gradient(180deg,rgba(0,0,0,.2),rgba(0,0,0,0))",
          height: "20px",
          position:"absolute",
          let:"0px",
          right:"0px",
          width:"100%"
        }}></div>
            </Row>
            <Row>
              <Col xs={6} style={{padding:"20px",}}>
              <div style={{textAlign:"start",marginTop:"30px"}} >
                <h6 style={{fontSize:"15px",color:"#6f6f6f"}} >Resources</h6>
                <hr style={{margin:"0px",width:"90%",marginTop:"15px"}} />
                <div style={{display:"flex",flexDirection:"column",gap:"4px",marginTop:"18px",color:"black"}} >
                <NavLink to="/blogs" style={{color:"black"}} className={({ isActive }) =>`${ isActive ? `${styles.active}` : ""}`}>Blogs</NavLink>
                  <NavLink to="/info-graphics" style={{color:"black"}} className={({ isActive }) =>`${ isActive ? `${styles.active}` : ""}`} >Infographics</NavLink>
                </div>
               </div>
              </Col>
              <Col xs={6} style={{background:"#eeeeee",padding:"20px",display:"flex",justifyContent:"center",alignItems:"center"}} >
              <div style={{ width: "100%",display:"flex",justifyContent:"center" }}>
                <Image fluid alt='award-winning' src='/assets/award-image.WebP' style={{ height: "300px", objectFit: "contain", borderRadius: "10px" }} />
                </div>
              </Col>
            </Row>
           </Container>
  )
}

export default ResourcesDropdown