import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

const ProductKeyPoints = ()=> {
  let keyValue = [
    {
      id: "0",
      title: "AI – powered automatic product mapping",
      desc: "AI-powered automatic product mapping streamlines and automates the process of categorizing and organizing products efficiently.",
      img: "/assets/analytics.WebP",
    },
    {
      id: "1",
      title: "Real-time & historical price tracking",
      desc: "Real-time and historical price tracking provides valuable insights into pricing trends and fluctuations over time, aiding in strategic decision-making and competitive analysis.",
      img: "/assets/tax.WebP",
    },
    {
      id: "2",
      title: "Intuitive heat map & visualization",
      desc: "Intuitive heat map and visualization tools present data in a visually engaging format, enabling easy interpretation and analysis of patterns and trends. ",
      img: "/assets/data.WebP",
    },
    {
      id: "3",
      title: "Predictive analysis",
      desc: "Predictive analysis utilizes data and algorithms to forecast future outcomes, enabling informed decision-making and proactive strategies.",
      img: "/assets/market-share.WebP",
    },
    {
      id: "4",
      title: "Seamless reports integration",
      desc: "Seamlessly integrate reports based on user preferences for a smooth and customizable experience. ",
      img: "/assets/statistical-inference.WebP",
    },
    {
      id: "5",
      title: "Data import & export",
      desc: "Utilize the data in any desired location by conveniently exporting or importing the data as needed ",
      img: "/assets/import.WebP",
    },
  ];
  return (
    <div className="mt-5">
      <Container>
        <Row>
          <Col>
            <h6
              className="mb-4"
              style={{ textAlign: "center", color: "#7a7e83" }}
            >
              Leverage OttixHow to gain powerful advantage in understanding your
              competition and their pricing strategies.
            </h6>
          </Col>
        </Row>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          {keyValue.map((item) => (
            <Col key={item.id} md={4}>
              <Card style={{ marginBottom: "12px", border: "none" }}>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      padding: "5px",
                      paddingTop: "0px",
                      paddingRight: "4px",
                      marginTop: "12px",
                    }}
                  >
                    <img height="40" width="40" src={item.img} alt="img" />
                  </div>
                  <div style={{ flex: "1" }}>
                    <h4 style={{ marginTop: "6px" }}>{item.title}</h4>
                    <p style={{ marginTop: "20px", color: "#7a7e83" }}>
                      {item.desc}
                    </p>
                  </div>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default ProductKeyPoints;
