import React from "react";
import styles from "./dataCaseStudy.module.css";
import { Container, Row } from "react-bootstrap";
import CommonBannerImage from "../../../components/CommonBannerImage";

const DataEngineeringCaseStudy = () => {
  return (
    <>
      <CommonBannerImage backgroundImageURL="/assets/data-engineering-service.WebP">
        <div className={styles.overlay}></div>
        <div className={styles.textContainer}>
          <h1>Case-Study: Ecomedes</h1>
        </div>
      </CommonBannerImage>
      <div className="mt-5">
        <Container>
          <Row>
            <h4 className="fw-bold">
              Ecomedes Relies on Orbio Solutions to Strengthen their Data
              Analytics Practices.
            </h4>
            <h6 className="fw-bold mt-3">Company Profile:</h6>
            <p>
              Ecomedes is known for its SaaS digital platform that assists
              organizations in making environmentally conscious and sustainable
              choices when it comes to procuring building materials and
              products. The platform is designed to streamline the procurement
              process while providing information on the environmental impact of
              various products. This includes data on energy efficiency,
              material composition, and other factors that contribute to a
              product's overall sustainability.
            </p>
            <h6 className="fw-bold">Situation:</h6>
            <p>
              Ecomedes monitors and updates the standards and certifications
              associated with eco-friendly products to provide their clients
              with the latest regulations and necessary certificates. However,
              to accomplish this objective, Ecomedes needed to navigate through
              various websites to gather the essential data. This approach had a
              lot of challenges that included accurately collecting data that is
              free of errors, ensuring the collected data is relevant to the
              business and ensuring the data is refreshed and in current state.
            </p>
            <h6 className="fw-bold">Task:</h6>
            <p>
              Ecomedes wanted a reliable vendor who could help them address the
              challenges of complex data acquisition and mapping. Gathering
              accurate data, aligning it with existing records, and keeping it
              refreshed was a constant battle for them. Managing linked PDFs and
              images added another layer of complexity, demanding secure and
              robust storage solutions.
            </p>
            <h6 className="fw-bold">Action:</h6>
            <p>
              At Orbio Solutions, we used advanced web scraping techniques which
              facilitated the efficient acquisition of relevant data from
              diverse sources. Accurately designed database insertion protocols
              ensured the structured and organized storage of acquired data.
            </p>
            <p>
              Leveraging AWS for secure and scalable storage effectively
              addressed the challenges across image and PDF cloud storage.
              Furthermore, a comprehensive data mapping system was developed to
              help optimize the client-side product identification process,
              maximizing accuracy and efficiency. These enabled Ecomedes to
              maintain a one-stop-shop data house where they could access all
              the information they need.
            </p>
            <p>
              Orbio Solutions provided support by gathering, cleaning, and
              mapping data for over 1 million eco- friendly products on a
              monthly basis for Ecomedes.
            </p>
            <h6 className="fw-bold">Result:</h6>
            <p>
              Orbio Solutions helped Ecomodes successfully address their data
              analytics-related challenges keeping in mind the high quality and
              faster-time-to-deliver requirements. Multiple stakeholders within
              Ecomedes benefitted from the resulting delivery by making the data
              relevant, up-to-date and available.
            </p>
            <h6 className="fw-bold">Benefits included,</h6>
            <li>Improved data accuracy</li>
            <li>Enhanced data accessibility</li>
            <li>Streamlined data and management</li>
            <li>Time savings</li>
            <li>Cost savings</li>
            <p className="mt-4">
              Looking for insights and intelligence from data through data
              analytics consulting services and solutions? Get in touch with
              Orbio Solutions today and jumpstart your journey with our data
              analytics experts. More here: www.orbiosolutions.com or email us
              directly.
            </p>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default DataEngineeringCaseStudy;
