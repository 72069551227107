import React, { useContext, useState } from 'react'
import { Col, Container, Dropdown, Image, Row } from 'react-bootstrap'
import { NavLink, useLocation } from 'react-router-dom'
import styles from "./navbar.module.css"

import { MdWhatsapp } from "react-icons/md";
import { RxHamburgerMenu } from "react-icons/rx";
import { HamburgerContext } from '../../context/hamburgerState';
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

import ResourcesDropdown from '../ResourcesDropdown';
import IndustriesDropdown from '../IndustriesDropdown';
import ProductsDropdown from '../ProductsDropdown';
import ServiceDropDown from "../ServiceDropDown"
import NavCanvas from '../NavCanvas';

const NavSection = ({setServiceDialog})=> {


  const { toggleHamburger } = useContext(HamburgerContext)

  const handleWhatsAppClick = () => {
    window.open('https://api.whatsapp.com/send?phone=9886767400','_blank');
  };

  const [dropdownState, setDropdownState] = useState({
    service: false,
    resources: false,
    industries: false,
    products: false,
  });

  const handleDropdownToggle = (dropdown) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [dropdown]: !prevState[dropdown],
    }));
  };

  const handleMouseEnter = (dropdown) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [dropdown]: true,
    }));
  };

  const handleMouseLeave = (dropdown) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [dropdown]: false,
    }));
  };
  const location = useLocation();

  const productPaths = ['/ottixhow'];
  const resourcePaths = ['/blogs', '/info-graphics'];
  const industriesPaths = ['/retail', '/health-care', '/education'];
  const servicesPaths = [
    '/business-it-solutions',
    '/product-engineering',
    '/mobility-digital-acceleration',
    '/iot-development',
    '/comprehensive-ai-data',
    '/data-artificial-intelligence',
    '/generative-ai-service',
    '/cloud-managed-services',
    '/technology-consulting',
    '/digital-security',
    '/quality-engineering',
  ];

const isProductsPath = productPaths.includes(location.pathname);
const isResourcePath = resourcePaths.includes(location.pathname);
const isIndustriesPath = industriesPaths.includes(location.pathname);
const isServices = servicesPaths.includes(location.pathname);

  return (
    <>
      <NavCanvas />
      <div className={styles.navbarMainContainer} >
      <Container fluid>
      <Row className='d-none d-md-flex' style={{ height: "30px",width:"100vw" }}>
      <div style={{ padding: "4px 12px", marginTop: "4px" }} className='d-flex justify-content-end gap-2'>
      <div className={styles.socialIconsContainer} >
      <a href="https://www.facebook.com/orbiosolutions" target="_blank" rel="noopener noreferrer" aria-label="facebook">
          <FaFacebookSquare className={styles.icon} />
      </a>
      <a href="https://www.instagram.com/orbiosolutions/" target="_blank" rel="noopener noreferrer" aria-label="instagram">
        <FaInstagramSquare className={styles.icon} />
      </a>
      <a href="https://www.linkedin.com/company/orbio-solutions-private-limited" target="_blank" rel="noopener noreferrer" aria-label="linkedin">
        <FaLinkedin className={styles.icon} />
      </a>
      </div>
        <NavLink to="/contact-us" style={{ color: "white", textDecoration: "none",border:'1px solid white',padding:'2px',paddingLeft:'10px',paddingRight:'10px',borderRadius:'19px',fontSize:"14px" }} className={({ isActive }) => `${isActive ? `${styles.active}` : ""}`} >Contact Us</NavLink>
        <NavLink to="/careers" style={{ color: "white",textDecoration: "none",marginRight:"8%",border:'1px solid white',padding:'2px',paddingLeft:'10px',paddingRight:'10px',borderRadius:'19px',fontSize:"14px" }} className={({ isActive }) => `${isActive ? `${styles.active}` : ""}`}>Careers</NavLink>
      </div>  
      </Row>
        <Row className='w-100' >
        <Col md={1} xs={4} style={{display:"flex",justifyContent:"flex-start",alignItems:"center",cursor:"pointer"}} >
        <a href="/">
          <Image src="/assets/orbioLogo.WebP" alt='orbioLogo' className={`${styles.img}`} />
        </a>
        </Col>
        <Col md={10} className={`d-none d-md-block ${styles.navSecondItem}`} style={{marginTop:"6px"}}>
            <div className={styles.navlinkContainer} style={{display:"flex",justifyContent:"flex-end",alignItems:"center"}} >
            <NavLink to='/' className={({ isActive }) =>`${ isActive ? `${styles.active}` : ""}`}>Home</NavLink>
            <NavLink to="/about-us" className={({ isActive }) =>`${ isActive ? `${styles.active}` : ""}`}>About Us</NavLink>
            <Dropdown show={dropdownState.service} onClick={() => handleDropdownToggle('service')} onMouseLeave={() => handleMouseLeave('service')} onMouseEnter={() => handleMouseEnter('service')} className="d-inline ">
              <div id="dropdown-autoclose-true" style={{background:"none",border:"none",color: isServices ? '#007fc7' : 'white',fontWeight: isServices ? 'bolder' : 'normal',marginTop:"16px",cursor:"pointer"}} >
                <p>Services {dropdownState.service ? <IoIosArrowUp /> : <IoIosArrowDown /> }  </p>
              </div>
              <Dropdown.Menu className={`${styles.fadeInDropdown}`} 
              style={{
              width:"100vw",position:"fixed",right:0,borderRadius:"0px",
              marginRight:"-1px",
              padding:"0px",
              height:"auto",
              overflow:"auto",
              scrollbarWidth: "thin",
              msOverflowStyle: "none",
                }} >
                 <ServiceDropDown
                 setServiceDialog={setServiceDialog}
                 />
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown
                show={dropdownState.products}
                onMouseEnter={() => handleMouseEnter('products')}
                onMouseLeave={() => handleMouseLeave('products')}
                onClick={() => handleDropdownToggle('products')}
                className="d-inline"
              > 
                <div id="dropdown-resources" style={{ background: "none", border: "none", color: isProductsPath ? '#007fc7' : 'white',fontWeight: isProductsPath ? 'bolder' : 'normal', marginTop: "16px", cursor: "pointer" }}>
                  <p>Products {dropdownState.products ? <IoIosArrowUp /> : <IoIosArrowDown />} </p>
                </div>
                <Dropdown.Menu className={`${styles.fadeInDropdown}`}
                  style={{
                    width: "45vw", position: "fixed", right: 0, borderRadius: "4px",
                    marginRight: "-1px",
                    padding: "0px",
                    height: "auto",
                    overflow: "auto",
                    scrollbarWidth: "thin",
                    msOverflowStyle: "none",
                  }} >
                  <ProductsDropdown />
                </Dropdown.Menu>
              </Dropdown>
            <Dropdown
                show={dropdownState.resources}
                onMouseEnter={() => handleMouseEnter('resources')}
                onMouseLeave={() => handleMouseLeave('resources')}
                onClick={() => handleDropdownToggle('resources')}
                className="d-inline"
              >
                <div id="dropdown-resources" style={{ background: "none", border: "none", color: isResourcePath ? '#007fc7' : 'white',fontWeight: isResourcePath ? 'bolder' : 'normal', marginTop: "16px", cursor: "pointer" }}>
                  <p>Resources {dropdownState.resources ? <IoIosArrowUp /> : <IoIosArrowDown />} </p>
                </div>
                <Dropdown.Menu className={`${styles.fadeInDropdown}`}
                  style={{
                    width: "45vw", position: "fixed", right: 0, borderRadius: "4px",
                    marginRight: "-1px",
                    padding: "0px",
                    height: "auto",
                    overflow: "auto",
                    scrollbarWidth: "thin",
                    msOverflowStyle: "none",
                  }} >
                  <ResourcesDropdown />
                </Dropdown.Menu>
              </Dropdown>
            <Dropdown
            show={dropdownState.industries}
            onMouseEnter={() => handleMouseEnter('industries')}
            onMouseLeave={() => handleMouseLeave('industries')}
            onClick={() => handleDropdownToggle('industries')}
            className="d-inline"
          >
            <div id="dropdown-industries" style={{ background: "none", border: "none", color: isIndustriesPath ? '#007fc7' : 'white',fontWeight: isIndustriesPath ? 'bolder' : 'normal', marginTop: "16px", cursor: "pointer" }}>
              <p>Industries {dropdownState.industries ? <IoIosArrowUp /> : <IoIosArrowDown />} </p>
            </div>
            <Dropdown.Menu className={`${styles.fadeInDropdown}`} style={{ 
              width: "45vw", position: "fixed", right: 0, 
              borderRadius: "4px", marginRight: "-1px", padding: "0px", 
              height: "auto", 
              overflow: "auto",
              scrollbarWidth: "thin",
              msOverflowStyle: "none", 
              }}>
              <IndustriesDropdown />
            </Dropdown.Menu>
          </Dropdown>
            </div>
          </Col>
          <Col md={1} xs={8} className={`${styles.navThirdItem}`} >
            <div className={`${styles.iconsContainer}`} >
            <MdWhatsapp className={`${styles.whatsappIcon}`} onClick={handleWhatsAppClick} />
            <RxHamburgerMenu onClick={toggleHamburger} className={`d-sm-block d-md-none ${styles.hamburIcon}`} />
            </div>
          </Col>
        </Row>
      </Container>
      </div>
    </>
  )
}

export default NavSection;