import React from "react";
import "./logoFooter.css";

const LogoFooter = () => {
  return (
    <div className="containerlogo">
      <div className="hello">
        <img
          src={"/assets/orbioLogo.WebP"}
          alt="img"
          style={{ objectFit: "contain" }}
        />
      </div>
      <div className="circle delay1"></div>
      <div className="circle delay2"></div>
      <div className="circle delay3"></div>
      <div className="circle delay4"></div>
    </div>
  );
};

export default LogoFooter;
