import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { NavLink, useNavigate } from 'react-router-dom'
import styles from "./productsDropdown.module.css"

const ProductsDropdown = () => {
  const navigate = useNavigate()
  return (
    <Container fluid>
            <Row>
            <div style={{
         background: "linear-gradient(180deg,rgba(0,0,0,.2),rgba(0,0,0,0))",
          height: "20px",
          position:"absolute",
          let:"0px",
          right:"0px",
          width:"100%"
        }}></div>
            </Row>
            <Row>
              <Col xs={6} style={{padding:"20px",}}>
              <div style={{textAlign:"start",marginTop:"30px"}} >
              <NavLink to="/ottixhow" style={{color:"black",fontSize:"20px"}} className={({ isActive }) =>`${ isActive ? `${styles.active}` : ""}`}>OttixHow</NavLink>
                <hr style={{margin:"0px",width:"90%",marginTop:"10px"}} />
                <p style={{fontSize:"14px",marginBottom:"2px",paddingTop:"5px"}} >
                OttixHow is an AI-powered retail insights platform that provides
              businesses with advanced tools to analyze market trends, pricing
              models, and competitor pricing strategies. It enables companies to
              optimize sales in a competitive global market.
              </p>
              <p style={{color:"#007fc7",cursor:"pointer",fontSize:"14px"}} onClick={()=>navigate("/ottixhow")} >Read More</p>
               </div>
              </Col>
              <Col xs={6} style={{background:"#eeeeee",padding:"20px",cursor:"pointer"}} onClick={()=>navigate("/ottixhow")} >
                <div style={{textAlign:"start",marginTop:"30px"}} >
                  <img alt='img' src="/assets/ottixhow-logo.WebP" style={{height:"35px",width:"auto",objectFit:"contain"}} />
                </div>
                <div style={{marginTop:"10px"}} >
                <img alt='desktopottixhow' src='/assets/ottixhowdesktop.WebP' style={{height:"auto",width:"100%",objectFit:"contain"}} />
                </div>
              </Col>
            </Row>
           </Container>
  )
}

export default ProductsDropdown