import React, { useState } from "react";
import styles from "./InfoGraphics.module.css";
import { Modal, Col, Container, Row } from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';
import "react-multi-carousel/lib/styles.css";
import CommonBannerImage from "../../components/CommonBannerImage";

const InfoGraphics = () => {
  const mediaOrder = [
    { id: 0, url: "/assets/infographics/Picture1.WebP" },
    { id: 1, url: "/assets/infographics/Picture2.WebP" },
    { id: 2, url: "/assets/infographics/Picture3.WebP" },
    { id: 3, url: "/assets/infographics/Picture4.WebP" },
    { id: 4, url: "/assets/infographics/Picture5.WebP" },
    { id: 5, url: "/assets/infographics/Picture6.WebP" },
    { id: 6, url: "/assets/infographics/Picture7.WebP" },
    { id: 7, url: "/assets/infographics/Picture8.WebP" },
    { id: 8, url: "/assets/infographics/Picture9.WebP" },
    { id: 9, url: "/assets/infographics/Picture10.WebP" },
    { id: 10, url: "/assets/infographics/competitor-analysis.mp4" },
    { id: 11, url: "/assets/infographics/data-import.mp4" },
    { id: 12, url: "/assets/infographics/digital-future.mp4" },
    { id: 13, url: "/assets/infographics/product-mapping.mp4" },
    { id: 14, url: "/assets/infographics/sales-analysis.mp4" },
  ];
  const [modalShow, setModalShow] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const openModal = (id) => {
    setSelectedImageIndex(id);
    setModalShow(true);
  };

  const closeModal = () => {
    setModalShow(false);
    setSelectedImageIndex(0);
  };
  return (
    <>
      <CommonBannerImage backgroundImageURL="/assets/infographics-bg.WebP">
        <div className={styles.textContainer}>
          <h1>Info-Graphics</h1>
        </div>
      </CommonBannerImage>
      <div style={{ background: "black", paddingBottom: "20px" }}>
        <Container fluid>
          <Row>
            {mediaOrder?.map(({url}, index) => (
              <Col className="mt-4" key={index} md={3} style={{ cursor: "pointer" }}
                
              >
                {url?.endsWith(".mp4") ? (
                  <video autoPlay muted loop src={url}
                  onClick={() => openModal(index)}
                    alt={`video-${index}`}
                    style={{ width: "100%", objectFit: "contain", height: "200px" }} />
                ) : (
                  <img src={url} alt={`img-${index}`}
                  onClick={() => openModal(index)}
                    style={{
                      width: "100%",
                      objectFit: "contain",
                      height: "200px",
                    }}
                  />
                )}
              </Col>
            ))}
          </Row>
        </Container>
      </div>

      <div>
      <Modal show={modalShow} onHide={closeModal} centered
      style={{ height: "100vh" }}>
    <Modal.Body>
    <Carousel data-bs-theme="dark"
    activeIndex={selectedImageIndex}
    onSelect={(index) => setSelectedImageIndex(index)}
    >
      {mediaOrder?.map(({ id, url }) => (
        <Carousel.Item key={id}>
          {url?.endsWith(".mp4") ? (
            <video autoPlay muted loop src={url} alt={`video-${id}`}
              style={{ width: "100%", maxHeight: "70vh", objectFit: "contain", }}
            />
          ) : (
            <img src={url} alt={`img-${id}`}
              style={{ width: "100%", maxHeight: "70vh", objectFit: "contain" }}
            />
          )}
        </Carousel.Item>
      ))}
      </Carousel>
    </Modal.Body>
  </Modal>
      </div>
    </>
  );
};

export default InfoGraphics;