import React from 'react'
import ContactUsBannerSection from '../../components/ContactUsBannerSection'
import ContactUsFormSection from '../../components/ContactUsFormSection'
import MapSection from '../../components/MapSection'
import ContactUsCountryLocations from '../../components/ContactUsCountryLocations'

function ContactUs() {
  return (
    <div>
      <ContactUsBannerSection />
      <ContactUsFormSection />
      <MapSection />
      <ContactUsCountryLocations />
    </div>
  )
}

export default ContactUs