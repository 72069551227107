import React, { useEffect, useState } from "react";
import styles from "./contactFormService.module.css";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import axios from "axios";

const ContactFormService = ({ caseStudyLength, title }) => {
  const [formState, setFormState] = useState({
    fullName: "",
    lastName: "",
    workEmail: "",
    companyName: "",
    phone: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({
    fullName: "",
    lastName: "",
    workEmail: "",
    companyName: "",
    phone: "",
  });
  const [inpFocused, setInpFocused] = useState(false);

  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevFormState) => ({
      ...prevFormState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (!formState.fullName || !formState.fullName.trim()) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        fullName: "Please enter your Full Name",
      }));
    } else if (formState.fullName !== formState.fullName.trim()) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        fullName: "Full Name should not start or end with spaces",
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        fullName: "",
      }));
    }

    if (!formState.lastName || !formState.lastName.trim()) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        lastName: "Please enter your Last Name",
      }));
    } else if (formState.lastName !== formState.lastName.trim()) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        lastName: "Last Name should not start or end with spaces",
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        lastName: "",
      }));
    }

    if (!formState.companyName || !formState.companyName.trim()) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        companyName: "Please enter your Company Name",
      }));
    } else if (formState.companyName !== formState.companyName.trim()) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        companyName: "Company Name should not start or end with spaces",
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        companyName: "",
      }));
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formState.workEmail) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        workEmail: "Please enter your Email",
      }));
    } else if (!emailPattern.test(formState.workEmail)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        workEmail: "Please enter a valid Email",
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        workEmail: "",
      }));
    }

    const phonePattern = /^[0-9()+-]*$/;
    if (!formState.phone) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        phone: "Please enter your Phone Number",
      }));
    } else if (!phonePattern.test(formState.phone)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        phone: "Please enter a valid Phone Number",
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        phone: "",
      }));
    }
  }, [formState]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setInpFocused(true);
    const hasErrors = Object.values(formErrors).some((error) => error !== "");

    if (!hasErrors) {
      setLoading(true);
      setInpFocused(false);
      try {
        const response = await axios.post(
          "https://api.orbiosolutions.com/email.php",
          {
            fname: formState?.fullName,
            lname: formState?.lastName,
            email: formState?.workEmail,
            company: formState?.companyName,
            phone: formState?.phone,
            message: formState?.message,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setSuccessMsg("Your query has been submitted");
        setTimeout(() => {
          setInpFocused(false);
          setSuccessMsg("");
          setFormState({
            fullName: "",
            lastName: "",
            workEmail: "",
            companyName: "",
            phone: "",
            message: "",
          });
          setFormErrors({
            fullName: "",
            lastName: "",
            workEmail: "",
            companyName: "",
            phone: "",
          });
        }, 1000);
        setLoading(false);
      } catch (error) {
        setErrorMsg("Your query has been submitted");
        setTimeout(() => {
          setInpFocused(false);
          setErrorMsg("");
          setFormState({
            fullName: "",
            lastName: "",
            workEmail: "",
            companyName: "",
            phone: "",
            message: "",
          });
          setFormErrors({
            fullName: "",
            lastName: "",
            workEmail: "",
            companyName: "",
            phone: "",
          });
        }, 1000);
        setLoading(false);
      }
    }
  };

  return (
    <div
      style={{
        backgroundColor: caseStudyLength > 0 ? "black" : "#a9a9a9",
        color: "white",
      }}
    >
      <Container className="p-4">
        <Row>
          <Col xs={12}>
            {title ? (
              <p
                className={styles.heading}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            ) : (
              <p className={styles.heading}>
                We are happy to answer any questions you may have and help you
                to determine which of our services best fit your needs.
              </p>
            )}
          </Col>
        </Row>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={4}>
              <Form.Group className="mb-2" controlId="formGroupFullName">
                <Form.Label className={styles.formLabel}>
                  First Name{" "}
                  <span style={{ color: "red", fontSize: "20px" }}>*</span>
                </Form.Label>
                <Form.Control
                  name="fullName"
                  value={formState.fullName}
                  onChange={handleInputChange}
                  className={styles.inpfield}
                  onFocus={() => setInpFocused(true)}
                  autoComplete="off"
                  type="text"
                />
                {formErrors.fullName && inpFocused && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {formErrors.fullName}
                  </p>
                )}
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-2" controlId="formGroupLastName">
                <Form.Label className={styles.formLabel}>
                  Last Name{" "}
                  <span style={{ color: "red", fontSize: "20px" }}>*</span>
                </Form.Label>
                <Form.Control
                  name="lastName"
                  value={formState.lastName}
                  onChange={handleInputChange}
                  className={styles.inpfield}
                  onFocus={() => setInpFocused(true)}
                  autoComplete="off"
                  type="text"
                />
                {formErrors.lastName && inpFocused && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {formErrors.lastName}
                  </p>
                )}
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-2" controlId="formGroupcompanyName">
                <Form.Label className={styles.formLabel}>
                  Company Name{" "}
                  <span style={{ color: "red", fontSize: "20px" }}>*</span>
                </Form.Label>
                <Form.Control
                  name="companyName"
                  value={formState.companyName}
                  onChange={handleInputChange}
                  className={styles.inpfield}
                  onFocus={() => setInpFocused(true)}
                  autoComplete="off"
                  type="text"
                />
                {formErrors.companyName && inpFocused && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {formErrors.companyName}
                  </p>
                )}
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-2" controlId="formGroupworkEmail">
                <Form.Label className={styles.formLabel}>
                  Work Email{" "}
                  <span style={{ color: "red", fontSize: "20px" }}>*</span>
                </Form.Label>
                <Form.Control
                  name="workEmail"
                  value={formState.workEmail}
                  onChange={handleInputChange}
                  className={styles.inpfield}
                  onFocus={() => setInpFocused(true)}
                  autoComplete="off"
                  type="email"
                />
                {formErrors.workEmail && inpFocused && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {formErrors.workEmail}
                  </p>
                )}
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-2" controlId="formGroupphone">
                <Form.Label className={styles.formLabel}>
                  Phone{" "}
                  <span style={{ color: "red", fontSize: "20px" }}>*</span>
                </Form.Label>
                <Form.Control
                  name="phone"
                  value={formState.phone}
                  onChange={handleInputChange}
                  className={styles.inpfield}
                  onFocus={() => setInpFocused(true)}
                  autoComplete="off"
                  type="text"
                />
                {formErrors.phone && inpFocused && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {formErrors.phone}
                  </p>
                )}
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group className="mb-3" controlId="formGroupmessage">
                <Form.Label className={styles.formLabel}>Message</Form.Label>
                <Form.Control
                  name="message"
                  value={formState.message}
                  onChange={handleInputChange}
                  className={styles.inpfield}
                  autoComplete="off"
                  as="textarea"
                  style={{ height: "100px" }}
                />
              </Form.Group>
            </Col>
            {successMsg && successMsg?.length > 0 && (
              <p className="text-center" style={{ fontSize: "18px" }}>
                {successMsg}
              </p>
            )}
            {errorMsg && errorMsg?.length > 0 && (
              <p className="text-center" style={{ fontSize: "18px" }}>
                {errorMsg}
              </p>
            )}
            <Col
              xs={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                type="submit"
                className={styles.btn}
                disabled={
                  loading ||
                  (successMsg && successMsg.length > 0) ||
                  (errorMsg && errorMsg.length > 0) ||
                  Object.values(formErrors).some((error) => error !== "")
                }
              >
                {loading ? "Submitting..." : "Submit"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default ContactFormService;
