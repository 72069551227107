import React from "react";
import styles from "./aiSolutionCaseStudy.module.css";
import { Container, Row } from "react-bootstrap";
import CommonBannerImage from "../../../components/CommonBannerImage";

const AiSolutionCaseStudy = () => {
  return (
    <>
      <CommonBannerImage backgroundImageURL="/assets/ai-solution-engineering.WebP">
        <div className={styles.overlay}></div>
        <div className={styles.textContainer}>
          <h1>Case-Study: AE Digital</h1>
        </div>
      </CommonBannerImage>
      <div className="mt-5">
        <Container>
          <Row>
            <h4 className="fw-bold">
              Transforming E-Commerce Growth and Success: AE Digital Implements
              OttixHow to Power their End-to-End Solutions in the Electronic
              Goods Industry
            </h4>
            <h6 className="fw-bold mt-3">Company Profile:</h6>
            <p>
              AE Digital offers a wide range of electronic products that meet
              the needs and preferences of different customer segments in the
              UAE. The company has earned a reputation for providing quality
              products and exceptional customer service.
            </p>
            <h6 className="fw-bold">Situation:</h6>
            <p>
              Competing in the e-commerce business necessitates regular
              monitoring and optimizing of product pricing. To stay atop in this
              dynamic market space called for constant monitoring of product
              prices based on industry trends, competitors&#39; strategies, and
              consumer preferences. AE Digital wanted to keep a continuous tab
              on competitors&#39; prices and adjust their own prices on major
              UAE marketplaces like Amazon, Carrefour, Noon, and Sharaf DG. This
              ensured that AE Digital’s offerings consistently occupied the buy
              box* position on these leading e-commerce platforms.
            </p>

            <h6 className="fw-bold">Challenge:</h6>
            <p>
              AE Digital faced challenges due to frequent fluctuations in
              competitors&#39; pricing, causing them to compromise their buy
              box* position. This significantly impacted the sales of their
              products. The need was a solution that could instantly capture
              competitors&#39; prices and provide actionable insights to ensure
              the company maintained top position in the buy box over long
              periods of time.
            </p>
            <h6 className="fw-bold">Solution:</h6>
            <p>AE Digital implemented the OttixHow platform.</p>
            <p>
              OttixHow platform from Orbio Solutions supports resellers,
              retailers, brands, and manufacturers in swiftly assessing
              competitors&#39; prices. OttixHow utilizes scraping, crawling, and
              matching algorithms, coupled with manual quality assurance
              testing, to ensure accurate matches. This empowers AE Digital with
              knowledge of competitors&#39; prices, enabling the company to
              update their product prices strategically and secure a leading
              position in the marketplace. With real-time insights, AE Digital
              has been able to proactively and strategically modify their own
              product prices and consistently secure the buy box* position,
              maximizing sales and boosting profitability.
            </p>
            <h6 className="fw-bold">
              OttixHow platform offers AE Digital the following advantages,
            </h6>
            <li>Live price check</li>
            <li>Price change notification</li>
            <li>Product price position</li>
            <li>Location-based pricing</li>

            <p className="mt-4">
              Orbio Solutions helps AE Digital accomplish near real-time pricing
              analytics meeting AE Digital’s low latency requirements. Through
              OttixHow, AE Digital experienced that companies that have near
              real-time access to competitor product pricing insights are the
              ones that consistently win.
            </p>
            <p className="mt-4">
              *Buy Box – It is usually awarded to a single seller for a specific
              product listing based on various factors, including price,
              shipping, and seller performance metrics.
            </p>
            <p className="mt-4">
              Are you seeking to enhance your online sales? Explore the
              possibilities with OttixHow at www.orbiosolutions.com or email us
              at <a href="mailto:info@orbiosolutions.com">info@orbiosolutions.com</a> to discover how OttixHow's
              intelligent pricing analytics can increase your revenues and
              strengthen your market presence.
            </p>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AiSolutionCaseStudy;
