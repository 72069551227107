import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import styles from "./aboutHelpBusinesses.module.css";

const AboutHelpBusinesses = () => {
  return (
    <div style={{ marginTop: "3em" }}>
      <Container>
        <Row>
          <Col xs={12}>
            <h4 className={styles.heading}>How We Help Businesses</h4>
          </Col>
        </Row>
        <Row className={styles.aligncenter}>
          <Col md={6}>
            <ul className={styles.mainlist}>
              <li>
                <span className="fw-bold">Technology Integration:</span> We
                synchronize access to advanced technology with strategic
                implementation to address business challenges effectively.
              </li>
              <li>
                <span className="fw-bold">Custom Solutions:</span> Our team
                cultivates reliable solutions tailored to your specific business
                needs.
              </li>
              <li>
                <span className="fw-bold">Industry Expertise:</span> We apply
                diverse technology solutions across various verticals to drive
                growth.
              </li>
              <li>
                <span className="fw-bold">User-Centric Design:</span> We focus
                on enhancing user experiences based on thorough analysis of
                customer and employee feedback.
              </li>
              <li>
                <span className="fw-bold">Process Optimization:</span> We
                improve business efficiency by bridging gaps left by outdated
                processes.
              </li>
              <li>
                <span className="fw-bold">Data-Driven Decisions:</span> We
                utilize big data and analytics to maximize the value of your
                data-driven decisions.
              </li>
              <li>
                <span className="fw-bold">Digital Initiatives:</span> Our
                services enhance your business visibility and market penetration
                through well-planned digital strategies.
              </li>
            </ul>
          </Col>
          <Col md={6}>
            <Image
              fluid
              src="/assets/help-business.WebP"
              alt="business"
              className={styles.img}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutHelpBusinesses;
