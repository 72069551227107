import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import styles from './caseStudy.module.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const CaseStudyDialog = ({ modalShow, setModalShow, modalNavigateUrl }) => {

  const navigate = useNavigate()

  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    phone: '',
    jobTittle:""
  });
  const [formErrors, setFormErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    phone: '',
    jobTittle:""
  });
const [inpFocused, setInpFocused] = useState(false);

const [successMsg,setSuccessMsg] = useState("")
const [errorMsg,setErrorMsg] = useState("")

const [loading, setLoading] = useState(false);

const handleInputChange = (e) => {
  const { name, value } = e.target;
  setFormState((prevFormState) => ({
    ...prevFormState,
    [name]: value,
  }));
};

useEffect(() => {
  if (!formState.firstName || !formState.firstName.trim()) {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      firstName: 'Please enter your First Name',
    }));
  }
  else if (formState.firstName !== formState.firstName.trim()) {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      firstName: 'First Name should not start or end with spaces',
    }));
  } else {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      firstName: '',
    }));
  }

  if (!formState.lastName || !formState.lastName.trim()) {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      lastName: 'Please enter your Last Name',
    }));
  } else if (formState.lastName !== formState.lastName.trim()) {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      lastName: 'Last Name should not start or end with spaces',
    }));
  } else {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      lastName: '',
    }));
  }

  if (!formState.jobTittle || !formState.jobTittle.trim() ) {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      jobTittle: 'Please enter your Job Title',
    }));
  } else if (formState.jobTittle !== formState.jobTittle.trim()) {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      jobTittle: 'Job tittle should not start or end with spaces',
    }));
  }
  else {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      jobTittle: '',
    }));
  }

  if (!formState.companyName || !formState.companyName.trim()) {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      companyName: 'Please enter your Company Name',
    }));
  }
  else if (formState.companyName !== formState.companyName.trim()) {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      companyName: 'Company Name should not start or end with spaces',
    }));
  } else {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      companyName: '',
    }));
  }

  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!formState.email) {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      email: 'Please enter your Email',
    }));
  } else if (!emailPattern.test(formState.email)) {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      email: 'Please enter a valid Email',
    }));
  } else {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      email: '',
    }));
  }

const phonePattern = /^[0-9()+-]*$/;
if (!formState.phone) {
  setFormErrors((prevErrors) => ({
    ...prevErrors,
    phone: 'Please enter your Phone Number',
  }));
} else if (!phonePattern.test(formState.phone)) {
  setFormErrors((prevErrors) => ({
    ...prevErrors,
    phone: 'Please enter a valid Phone Number',
  }));
} else {
  setFormErrors((prevErrors) => ({
    ...prevErrors,
    phone: '',
  }));
}

}, [formState]);

const handleCancel = ()=>{
  setModalShow(false)
  setInpFocused(false)
  setFormState({
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    phone: '',
    jobTittle:""
  })
}

const handleSubmit = async (e) => {

  e.preventDefault();
  setInpFocused(true)
  const hasErrors = Object.values(formErrors).some((error) => error !== '');

  if (!hasErrors) {
    setInpFocused(false)
    setLoading(true);
    try {
      const response = await axios.post('https://api.orbiosolutions.com/email.php', {
        fname:formState?.firstName,
        lname:formState?.lastName,
        email: formState?.email,
        company: formState?.companyName,
        phone: formState?.phone,
        title: formState?.jobTittle,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setSuccessMsg("Form submitted succesfully");
      setTimeout(() => {
        setInpFocused(false);
        setSuccessMsg("")
        setFormState({
          firstName: '',
          lastName: '',
          email: '',
          companyName: '',
          phone: '',
          jobTittle: "",
      });
      setFormErrors({
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        jobTittle: '',
      });
      localStorage.setItem("formSubmitted",true)
      setModalShow(false)
      navigate(modalNavigateUrl)
      }, 500);
      setLoading(false);

    } catch (error) {
      setErrorMsg("Form submitted succesfully");
      setTimeout(() => {
        setInpFocused(false);
        setErrorMsg("")
        setFormState({
          firstName: '',
          lastName: '',
          email: '',
          companyName: '',
          phone: '',
          jobTittle: "",
      });
      setFormErrors({
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        phone: '',
        jobTittle: "",
    });
    localStorage.setItem("formSubmitted",true)
    setModalShow(false)
    navigate(modalNavigateUrl)
      }, 500);
      setLoading(false);
    }
  }
  
  // // added 12-02-2024 testing
  // if (!hasErrors){
  //   navigate(modalNavigateUrl)
  // }
};

  return (
    <div>
        <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Container>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} >
                <h6>Please provide your details</h6>
              </Col>
              {/* First Part */}
              <Col sm={6}>
                <Form.Group >
                  <Form.Label htmlFor='firstName' className={styles.inpLabel} >First Name</Form.Label>
                  <Form.Control name="firstName" value={formState.firstName}
                  onChange={handleInputChange} onFocus={()=>setInpFocused(true)} autoComplete="off"
                  id="firstName" className={styles.inpfield} size="sm" type="text" />
                  { formErrors.firstName && inpFocused && <span style={{color:"red",fontSize:"11px"}}>{formErrors.firstName}</span> }
                </Form.Group>
                <Form.Group >
                  <Form.Label htmlFor='email' className={styles.inpLabel} >Email</Form.Label>
                  <Form.Control id="email" name="email" className={styles.inpfield} size="sm" type="email" autoComplete='off'
                  value={formState.email} onChange={handleInputChange} onFocus={()=>setInpFocused(true)}
                   />
                  { formErrors.email && inpFocused && <span style={{color:"red",fontSize:"11px"}}>{formErrors.email}</span> }
                </Form.Group>
                <Form.Group >
                  <Form.Label htmlFor='jobTittle' className={styles.inpLabel} >Job Title</Form.Label>
                  <Form.Control id="jobTittle" className={styles.inpfield} size="sm" type="text" autoComplete='off'
                  name="jobTittle" value={formState.jobTittle} 
                  onChange={handleInputChange} onFocus={()=>setInpFocused(true)}
                  />
                  { formErrors.jobTittle && inpFocused && <span style={{color:"red",fontSize:"11px"}}>{formErrors.jobTittle}</span> }
                </Form.Group>
              </Col>  

              {/* Second Part */}
              <Col sm={6}>
                <Form.Group >
                  <Form.Label htmlFor='lastName' className={styles.inpLabel} >Last Name</Form.Label>
                  <Form.Control id="lastName" className={styles.inpfield} size="sm" type="text" autoComplete='off'
                  name="lastName" value={formState.lastName} 
                  onChange={handleInputChange} onFocus={()=>setInpFocused(true)}
                  />
                  { formErrors.lastName && inpFocused && <span style={{color:"red",fontSize:"11px"}}>{formErrors.lastName}</span> }
                </Form.Group>
                <Form.Group >
                  <Form.Label htmlFor='phone' className={styles.inpLabel} >Phone Number</Form.Label>
                  <Form.Control id="phone" className={styles.inpfield} size="sm" type="text" autoComplete='off'
                  name="phone" value={formState.phone} 
                  onChange={handleInputChange} onFocus={()=>setInpFocused(true)}
                  />
                  { formErrors.phone && inpFocused && <span style={{color:"red",fontSize:"11px"}}>{formErrors.phone}</span> }
                </Form.Group>
                <Form.Group >
                  <Form.Label htmlFor='companyName' className={styles.inpLabel} >company Name</Form.Label>
                  <Form.Control id="companyName" className={styles.inpfield} size="sm" type="text" autoComplete='off'
                  name="companyName" value={formState.companyName} 
                  onChange={handleInputChange} onFocus={()=>setInpFocused(true)}
                  />
                  { formErrors.companyName && inpFocused && <span style={{color:"red",fontSize:"11px"}}>{formErrors.companyName}</span> }
                </Form.Group>
              </Col>  

            </Row>
            <Row className="mt-2" >
              <Col style={{display:"flex",justifyContent:"flex-end",gap:"5px"}} >
              <Button variant="outline-secondary" onClick={handleCancel}>
                Cancel
              </Button>
              <Button variant="success" type="submit"
              disabled={
                loading || 
                (successMsg && successMsg.length > 0) || 
                (errorMsg && errorMsg.length > 0) || 
                Object.values(formErrors).some(error => error !== '')
              }
              >
                {loading ? 'Submitting...' : 'Submit'}
              </Button>
              </Col>
              {successMsg && successMsg?.length > 0 && (
          <p className="text-center" style={{ fontSize: "14px" }}>
            {successMsg}
          </p>
        )}
        {errorMsg && errorMsg?.length > 0 && (
          <p className="text-center" style={{ fontSize: "14px" }}>
            {errorMsg}
          </p>
        )}
            </Row>
          </Form>
        </Container>
      </Modal.Body>
    </Modal>
    </div>
  );
};

export default CaseStudyDialog;
