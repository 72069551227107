import React, { createContext, useState } from 'react'

const HamburgerContext = createContext()

const HamburgerProvider = ({children})=>{
    const [hamburgerOpen, setHamburgerOpen] = useState(false);

    const toggleHamburger = () => {
        setHamburgerOpen((prev) => !prev);
      };
      return (
        <HamburgerContext.Provider value={{ hamburgerOpen, toggleHamburger }}>
          {children}
        </HamburgerContext.Provider>
      );
    };

    export { HamburgerContext, HamburgerProvider };