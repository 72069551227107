import React from "react";
import AboutSlider from "../../components/AboutSlider";
import MeasurableSuccess from "../../components/MeasurableSuccess";
import ServiceListing from "../../components/ServiceListing";
import AchievementSection from "../../components/AchievementSection";
import ReviewSection from "../../components/ReviewSection";
import BannerImageSection from "../../components/BannerImageSection";
import YoutubeSection from "../../components/YoutubeSection";
import { Helmet } from "react-helmet-async";

function HomePage() {

  const serviceSchema = {
    "@context": "https://schema.org",
    "@type": "Service",
    serviceType: "Home",
    provider: {
      "@type": "Organization",
      name: "Orbio Solutions",
      url: "https://www.orbiosolutions.com",
    },
    description:
      "Leading digital transformation company offering innovative IT solutions and services. Empower your business with cutting-edge technology and sustainable growth.",
    offers: {
      "@type": "Service",
      url: "https://www.orbiosolutions.com/",
    },
  };

  return (
    <div>
      <Helmet>
        <title>
          Orbio Solutions: Top Digital Transformation & IT Solutions
        </title>
        <meta
          name="keywords"
          content="A digital transformation service company"
        />
        <meta
          name="description"
          content="Leading digital transformation company offering innovative IT solutions and services. Empower your business with cutting-edge technology and sustainable growth."
        />
        <meta
          property="og:title"
          content="Orbio Solutions: Top IT Solutions Company for Digital Growth"
        />
        <meta
          property="og:description"
          content="Orbio Solutions, a leading IT solutions company, delivers innovative digital transformation services to help businesses scale and thrive in the digital age."
        />
        <script type="application/ld+json">
          {JSON.stringify(serviceSchema)}
        </script>
      </Helmet>
      <BannerImageSection />
      <YoutubeSection />
      <AboutSlider />
      <MeasurableSuccess />
      <ServiceListing />
      <AchievementSection />
      <ReviewSection />
    </div>
  );
}

export default HomePage;
