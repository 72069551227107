import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import styles from "./cookies.module.css"

const CookiesBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleClose = () => {
    localStorage.setItem("consentAccepted",true)
    setIsVisible(false);
  };
  useEffect(() => {
    let a = localStorage.getItem("consentAccepted")
    if(a){
      setIsVisible(false)
    }else{
      setIsVisible(true)
    }
  }, [])
  

  return (
    <>
      {isVisible && (
        <div className={styles.mainContainer} >
          <Container fluid style={{padding:"10px"}} >
            <Row>
            <Col xs={12} >
            <h6 className={styles.heading} >This website uses cookies</h6>
            </Col>
            </Row>
            <Row>
            <Col md={8} >
            <p className={styles.desc} >
            This website uses cookies. By navigating around this site you consent to cookies being stored on your machine.
            </p>
              </Col>
              <Col md={4} style={{display:"flex",gap:"10px"}} >
              <Button onClick={handleClose} size="sm" variant="outline-dark" aria-label="Accept all cookies" >Accept all cookies</Button>
              <Button onClick={handleClose} size="sm" variant="outline-dark" aria-label="Accept essential cookies" >Accept essential cookies</Button>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default CookiesBanner;
