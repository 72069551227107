import React from "react";
import CommonBannerImage from "../../components/CommonBannerImage";
import { Button, Col, Container, Row } from "react-bootstrap";
import styles from "./futureofAi.module.css";
import { RxDotFilled } from "react-icons/rx";
import { FaArrowRightLong } from "react-icons/fa6";
import { NavLink, useNavigate } from 'react-router-dom';
import { FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { GoArrowLeft } from "react-icons/go";

function FutureofAi() {

  const navigate = useNavigate()

  const handleTwitterClick = () => {
    const twitterPageUrl =
      "https://twitter.com/intent/tweet?text=Robotic%20&%20AI%20future&url=https://www.orbiosolutions.com/future-of-ai-and-robotics/";
    window.open(twitterPageUrl, "_blank");
  };
  const handleFacebookClick = () => {
    const facebookPageUrl =
      "https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fsharer%2Fsharer.php%3Fu%3Dhttps%253A%252F%252Fwww.orbiosolutions.com%252Ffuture-of-ai-and-robotics%252F&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=en_GB";
    window.open(facebookPageUrl, "_blank");
  };

  const handleLinkedinClick = () => {
    const linkedinPageUrl =
      "https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2FshareArticle%3Fmini%3Dtrue%26url%3Dhttps%3A%2F%2Fwww.orbiosolutions.com%2Ffuture-of-ai-and-robotics%2F%26title%3DRobotic%2520%26%2520AI%2520future%26summary%3Dhttps%3A%2F%2Fwww.orbiosolutions.com%26source%3DOrbio%2520Solutions";
    window.open(linkedinPageUrl, "_blank");
  };

  return (
    <div>
      <CommonBannerImage backgroundImageURL="/assets/Robotic-ai.WebP">
        <Container>
          <div className={styles.textContainer}>
            <h1>The Future of Robotics & AI</h1>
          </div>
        </Container>
      </CommonBannerImage>

      <div className='mt-3'>
        <Container>
          <div className="mb-2" >
          <Button onClick={()=>navigate(-1)} variant="outline" className={styles.backArrow} > <GoArrowLeft /> back</Button>
          </div>
            <Row>
                <Col md={8} >
                    <Row>
                <div style={{display:"flex",gap:"12px",width:"100%",flexWrap:"wrap",}} >
                <Button className={styles.btn} >Autonomous</Button>
                <Button className={styles.btn} >Digitization</Button>
                <Button className={styles.btn} >Nanotechnology</Button>
                <Button className={styles.btn} >Innovations</Button>
                </div>
                    </Row>
                    <Row className='mt-4'>
                        <Col xs={8} ><p style={{fontWeight:"bold"}} >Sep 27, 2023 <RxDotFilled /> 6 min read</p></Col>
                        <Col xs={4} > 
                        <div style={{display:"flex",gap:"12px"}} >
                            <FaTwitter onClick={handleTwitterClick} style={{fontSize:"26px",cursor:"pointer",color:"#44b1e4"}} />
                            <FaFacebook onClick={handleFacebookClick} style={{fontSize:"26px",cursor:"pointer",color:"#4661c5"}} />
                            <FaLinkedin onClick={handleLinkedinClick} style={{fontSize:"26px",cursor:"pointer",color:"#0073b0"}} />
                        </div>
                        </Col>
                    </Row>
                    <Row>
                    <div>
      <div>
        <h3 className={styles.heading}>The Future of Robotics & AI</h3>
        <p className={styles.desc}>
          The cosmos of commonality has been terribly taking twists and turns
          according to the changing dynamics of technological breakthrough . It
          takes no insight to comprehend that humanity is now about to embrace a
          new and novel lifestyle that will be powered by algorithm, autonomous
          and al-driven technologies It Is inescapable reality that A I has
          grown out of the science fiction realm and is Playing out right now.
          However, Ai ,in sooth, is quite distinct from the Frankenstein robots
          which Hollywood movies imagined since it could be turned out to be
          boon and Bane of humanity simultaneously .well an erudite futurist
          gerd leonhard depicts explicitly a picture of men vs robotics slugfest
          in his book known as technology vs humanity. Whereas, there seems to
          be no ambiguity and dubity over the fact that the humanity gets s
          underway to a radical megashifts more in the next 20years than the
          previous 300 years. In an ideal future of humanity will sit on-top of
          technology, harnesses its power to redress most of its conundrums and
          dilemmas (disease, water ,food, energy) while allowing us to spend
          more time on the top layers of the Maslow needs pyramid (ei: social
          and cultural needs,self realisation etc.)
        </p>
      </div>
      <div>
        <h3 className={styles.heading}>
          History of tomorrow ; human vs humanoid
        </h3>
        <p className={styles.desc}>
          The compound effect of exponential and combinatorial technological
          change will become evident by 2023 will surpass the industrial
          revolution or the invention of the print press. So now the awkward
          question mark is whether it’s boon or Bane for ….? While some
          futurists profusely lament the fluctuation of human driven social
          fabric into humanoid driven order stamping out all social values and
          ethics visionary scientists are in a strenuous bid shaping an utopian
          cosmos that couldn’t have been even thought of a couple of years back
          . It would be farfetched to ponder over this paradigm shift in the
          technological shpere if it were a couple of decades ago reshaping all
          walks of life and apportioning a miraculous rhythmes to the lives of
          ecch one within just matter of years. However, now humanity has been
          got to a level where none will defy the corellary that the
          technological ramification will no longer retain its status quo as
          being Just outside of human bodies but it’s actually penetrating
          inside of us(via wearbels , bcis , nanotechnology, human genome
          editing, AI etc.) Marking a phenomenal change in the history of human
          beings . Well ,It Is an inconvertible fact that The leaner cycle of
          historical experience are already morphing into parallel universe of
          rich and poor , peaceful and warridden . Business cycle within
          industries also vanish ,as winners and losers contrast dramatically at
          any one time .this Is more than just the conflation of time and space
          so often defined as globalisation – this is exponential technology
          redefining what it means to exist as a human being at any one time. So
          As a result of The somersault of the pattern of life, jabbing the
          previous one into the eternal oblivion , now the world is witnessing a
          sharp meandering in the daily lives of populaces as the technological
          inventions and innovations are continuing to usher in novel world
          order acquiring momentum in plethora of fields like Digitisation
          scientificstion datafication intelligesation, automation,
          virtulaisation and Augmentat ion. So in a recapitulation, the
          exponential expanding scope of robotics and AI will definitely give
          birth a fabulous and unprecedented world order at the expense of the
          prevailing one . And of course it could be a boon and Bane for the
          world as it can expedite the velocity of upward trajectory of
          globalisation and at the same time robotics and AI could be menace to
          the balance of world .
        </p>
      </div>
    </div>
                    </Row>
                </Col>
                <Col md={4}>
                    <div className={styles.otherBlogs} >
                        <div><FaArrowRightLong /><NavLink to="/blog/things-to-be-aware-of-before-starting-an-ecommerce-business" className={styles.sublinks} > Things To Be Aware of Before Starting An Ecommerce Business </NavLink></div>
                        <div><FaArrowRightLong /><NavLink to="/blog/plan-your-project-with-your-software" className={styles.sublinks} > Big Data Helping Enterprises </NavLink></div>
                        <div><FaArrowRightLong /><NavLink to="/blog/competitive-data-analytics" className={styles.sublinks} > Competitive Data Analytics </NavLink></div>
                        <div><FaArrowRightLong /><NavLink to="/blog/data-driven-decision-making" className={styles.sublinks} > Data-Driven Decision Making </NavLink></div>
                        <div><FaArrowRightLong /><NavLink to="/blog/driving-sales-and-profits-why-your-retail-business-requires-a-well-constructed-pricing-strategy" className={styles.sublinks} > Driving Sales and Profits: Why your Retail Business Requires a Well-constructed Pricing Strategy? </NavLink></div>
                        <div><FaArrowRightLong /><NavLink to="/blog/strategic-pricing-intelligence-enhancing-business-strategies-with-competitor-pricing-analytics" className={styles.sublinks} > Strategic Pricing Intelligence</NavLink></div>
                        <div><FaArrowRightLong /><NavLink to="/blog/the-rising-importance-of-price-management-and-optimization-for-online-retailers-of-today-and-tomorrow" className={styles.sublinks} > The rising importance of price management and optimization for online retailers of today and tomorrow.</NavLink></div>
                        <div><FaArrowRightLong /><NavLink to="/blog/e-commerce-insights-trends-challenges-winning-strategies" className={styles.sublinks} > E-Commerce Insights: Trends, Challenges, and Winning Strategies</NavLink></div>
                        <div><FaArrowRightLong /><NavLink to="/blog/headless-woocommerce" className={styles.sublinks} > Headless WooCommerce</NavLink></div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
    </div>
  );
}

export default FutureofAi;
