import React from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { BiSolidQuoteLeft } from "react-icons/bi";
import "./aboutCeoSection.css";

const AboutCeoSection = () => {
  return (
    <div className="aboutCeoMainContainer">
      <Container>
        <Card className="aboutCeoCard">
          <Row>
            <Col
              xs={12}
              md={4}
              className="d-flex justify-content-center align-items-center"
            >
              <Image
                fluid
                src="/assets/rakesh4.WebP"
                alt="ceo image"
                className="aboutCeoImg"
              />
            </Col>

            <Col xs={12} md={8} className="d-flex justify-content-center align-items-center">
              <div className="ceocontentsection">
                <span>
                  <BiSolidQuoteLeft style={{ fontSize: "30px" }} />
                </span>
                <h3 className="mt-2">Rakeshnath Oopath</h3>
                <h6 className="mt-2">Founder & CEO</h6>
                <p className="mt-2">
                  "In every challenge lies an opportunity. At Orbio Solutions,
                  we embrace innovation and foster a culture of commitment and
                  excellence. From conceptualizing ideas to delivering
                  comprehensive tech solutions, my team and I are dedicated to
                  accelerating your business growth and meeting your customers'
                  needs. Our mission is to help you achieve faster, safer, and
                  more effective growth. We are grateful to our clients for
                  being an integral part of our journey towards excellence."
                </p>
              </div>
            </Col>
          </Row>
        </Card>
      </Container>
    </div>
  );
};

export default AboutCeoSection;
