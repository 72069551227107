import React from "react";
import { Container } from "react-bootstrap";
import styles from "./productBudget.module.css";

const ProductBudgetPlan = ()=> {
  return (
    <div style={{ background: "#061321", padding: "45px" }}>
      <Container>
        <div style={{ textAlign: "center" }}>
          <h1 className={styles.heading}>Choose the Right Plan for Your</h1>
          <h1 className={styles.heading}>Business</h1>
          <h5 className={styles.subheading}>
          No matter your budget, OttixHow has a solution to empower your business. Explore plans from $299 to $10,000.
          </h5>
          <h5 className={styles.subheading}>
            We tailor our packages to your requirements based on how many
            websites and products you wish to track, and how often.
          </h5>
        </div>
      </Container>
    </div>
  );
}

export default ProductBudgetPlan;
