import React, { useState, useEffect } from "react";
import { countryDialCode } from "../../db/country";
import styles from "./contactForm.module.css";
import { FaArrowRightLong } from "react-icons/fa6";
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import { Button } from "react-bootstrap";

const ContactForms = ()=> {
  const [selectedCountry, setSelectedCountry] = useState("United States");
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  
  const [businessPhone, setBusinessPhone] = useState("");
  const [businessPhoneError, setBusinessPhoneError] = useState("");
  
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [recaptchaError, setRecaptchaError] = useState("");

  const [inpFocused, setInpFocused] = useState(false);
  
  const [companyName,setCompanyName] = useState("")
  const [textAreaDesc,setTextAreaDesc] = useState("")

  const [firstName,setFirstName] = useState("")
  const [firstNameError,setFirstNameError] = useState("")

  const [lastName,setLastName] = useState("")
  const [lastNameError,setLastNameError] = useState("")
  
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");


  const [successMsg,setSuccessMsg] = useState("")
  const [errorMsg,setErrorMsg] = useState("")



  useEffect(() => {
    const selectedCountryObj = countryDialCode.find(
      (country) => country.name === selectedCountry
    );
    if (selectedCountryObj) {
      setSelectedCountryCode(selectedCountryObj.dial_code);
      setBusinessPhone(""); // Reset business phone when country changes
    }
  }, [selectedCountry]);

  const toggleAdditionalFields = () => {
    setShowAdditionalFields(!showAdditionalFields);
  };

  const handleRecaptchaVerify = (response) => {
    setRecaptchaVerified(true);
    setRecaptchaError("");
  };
  

  useEffect(() => {
    if (!firstName || !firstName.trim() ) {
      setFirstNameError("Please enter your First Name");
    } else if (firstName !== firstName.trim()) {
      setFirstNameError("First Name should not start or end with spaces");
    }else {
      setFirstNameError("");
    }
  
    if (!lastName || !lastName.trim()) {
      setLastNameError("Please enter your Last Name");
    } else if (lastName !== lastName.trim()) {
      setFirstNameError("Last Name should not start or end with spaces");
    }else {
      setLastNameError("");
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!email) {
    setEmailError("Please enter your Email");
  } else if (!emailPattern.test(email)) {
    setEmailError("Please enter a valid Email");
  } else {
    setEmailError("");
  }

  const phonePattern = /^[0-9()+-]*$/;

  if (!businessPhone) {
    setBusinessPhoneError("Please enter your Business Phone");
  } else if (!phonePattern.test(businessPhone)) {
    setBusinessPhoneError("Please enter a valid 10-digit phone number");
  } else {
    setBusinessPhoneError("");
  }

  }, [firstName, lastName, email, businessPhone]);
  

  const [loading, setLoading] = useState(false);

  const handleContinue = async () => {
    setInpFocused(true);
  
    // Check for errors
    if (!recaptchaVerified || firstNameError || lastNameError || emailError || businessPhoneError) {
      if (!recaptchaVerified) {
        setRecaptchaError("Please verify reCAPTCHA");
      } else {
        setRecaptchaError("");
      }
      return; // Exit the function if there are any errors
    }
  
    // form data
    const formData = {
      fname: firstName,
      lname: lastName,
      email: email,
      country: selectedCountryCode,
      phone: businessPhone,
      company: companyName,
      message: textAreaDesc,
      
    };
  
    if (formData) {
      setLoading(true);

      try {
        const response = await axios.post('https://api.orbiosolutions.com/email.php', formData,{
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setSuccessMsg("You have succesfully submitted the form");
        setTimeout(() => {
          setSuccessMsg("");
          setSelectedCountry("India");
          setSelectedCountryCode("");
          setShowAdditionalFields(false);
          setBusinessPhone("");
          setBusinessPhoneError("");
          setRecaptchaVerified(false);
          setRecaptchaError("");
          setCompanyName("");
          setTextAreaDesc("");
          setFirstName("");
          setFirstNameError("");
          setLastName("");
          setLastNameError("");
          setEmail("");
          setEmailError("");
          setInpFocused(false);
          window.location.reload()
        }, 500);
        setLoading(false);
      } catch (error) {
        setErrorMsg("You have succesfully submitted the form");
        setTimeout(() => {
          setErrorMsg("")
          setSelectedCountry("India");
          setSelectedCountryCode("");
          setShowAdditionalFields(false);
          setBusinessPhone("");
          setBusinessPhoneError("");
          setRecaptchaVerified(false);
          setRecaptchaError("");
          setCompanyName("");
          setTextAreaDesc("");
          setFirstName("");
          setFirstNameError("");
          setLastName("");
          setLastNameError("");
          setEmail("");
          setEmailError("");
          setInpFocused(false);
          window.location.reload()
        }, 500); 
        setLoading(false);
      }
  }
  };
  
  

  return (
    <div className={styles.contactForm}>
       <div>
        { firstNameError && inpFocused && <label style={{color:"red",fontSize:"12px"}}>{firstNameError}</label> }
       <input id="firstName" autoComplete="off" value={firstName} onChange={(e)=>setFirstName(e.target.value)} onFocus={()=>setInpFocused(true)} name="firstname" type="text" placeholder="First Name" className={styles.inputStyle} />
       </div>
      <div>
      { lastNameError && inpFocused && <label style={{color:"red",fontSize:"12px"}}>{lastNameError}</label> }
      <input id="lastName" autoComplete="off" value={lastName} onChange={(e)=>setLastName(e.target.value)} onFocus={()=>setInpFocused(true)} name="lastname" type="text" placeholder="Last Name" className={styles.inputStyle} />
      </div>
      <div>
      { emailError && inpFocused && <label style={{color:"red",fontSize:"12px"}}>{emailError}</label> }
      <input id="email" autoComplete="off" name="email" value={email} onChange={(e)=>setEmail(e.target.value)} onFocus={()=>setInpFocused(true)} type="email" placeholder="Business Email" className={styles.inputStyle} />
      </div>

      {/* Dropdown to select country */}
      <select
      id="selectCompany" 
        value={selectedCountry}
        onChange={(event) => setSelectedCountry(event.target.value)}
        className={styles.inputStyle}
        placeholder="Country/Region"
      >
        {countryDialCode.map((country, index) => (
          <option key={index} value={country.name}>
            {country.name}
          </option>
        ))}
      </select>

      {/* Display selected country's dial code */}
      {businessPhoneError && inpFocused && (
    <span style={{ color: "red", fontSize: "12px" }}>{businessPhoneError}</span>
  )}
      <div style={{ display: "flex", alignItems: "center" }}>
        {selectedCountryCode && (
          <span style={{ marginRight: "5px" }}>{selectedCountryCode}</span>
        )}
        {/* Input field for Business Phone */}
        <input
        id="phoneNumber" autoComplete="off"
          type="text"
          placeholder="Business Phone"
          className={styles.inputStyle}
          value={businessPhone}
          onChange={(e) => setBusinessPhone(e.target.value)}
          onFocus={() => setInpFocused(true)}
        />
      </div>

      <div className={`${styles.additionalFields} ${showAdditionalFields ? styles.show : ''}`}>
        <input id='company' autoComplete="off" value={companyName} onChange={(e)=>setCompanyName(e.target.value)} onFocus={()=>setInpFocused(true)} type="text" placeholder="Company" className={styles.inputStyle} />
        <textarea
        id="textarea" autoComplete="off"
        value={textAreaDesc} onChange={(e)=>setTextAreaDesc(e.target.value)} onFocus={()=>setInpFocused(true)}
          style={{ marginTop: "28px", width: "100%", padding: "23px", height: "100px" }}
          placeholder="What products, services or solutions would you like to discuss with a sales expert?"
        />
        {/* reCAPTCHA Checkbox */}
        <div className={styles.recaptchaContainer} >
          <ReCAPTCHA
            id="recaptchaInput"
            sitekey="6LfzHg0kAAAAAKLjk3VB56YrKjnoms4FmqaCUtRj"
            onChange={handleRecaptchaVerify}
            aria-label="Recaptcha verification"
          />
          <label htmlFor="recaptchaInput" style={{ color: "red", fontSize: "12px" }}>{recaptchaError}</label>
        </div>
        <div className={styles.btnContainer} >
        <Button onClick={handleContinue} className={styles.continueBtn} 
        disabled={
          loading || 
          firstNameError || 
          lastNameError || 
          emailError || 
          businessPhoneError || 
          recaptchaError
        }
        >
          {loading ? 'Submitting...' : (
            <>
              Continue <FaArrowRightLong className={styles.arrowIcon} />
            </>
          )}
        </Button>
        </div>
      </div>

      {/* Toggle button */}
      <div style={{ textAlign: "center" }}>
      {successMsg && successMsg?.length > 0 && (
          <p className="text-center" style={{ fontSize: "18px" }}>
            {successMsg}
          </p>
        )}
        {errorMsg && errorMsg?.length > 0 && (
          <p className="text-center" style={{ fontSize: "18px" }}>
            {errorMsg}
          </p>
        )}
        <h6 className={styles.toggleBtn} onClick={toggleAdditionalFields}>
          {showAdditionalFields ? "- Less" : "+ More"}
        </h6>
      </div>
    </div>
  );
}

export default ContactForms;
