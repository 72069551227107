import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./contactUsLocations.module.css"

const ContactUsCountryLocations = ()=> {
  return (
    <>
    <div className="mt-4 mb-1" >
    <Container>
      <h5>Other Locations; </h5>
    </Container>
    </div>
    <div style={{background:"#f8f8f8",padding:"25px"}}>
      <Container style={{ minHeight:"123px",display:"flex",flexDirection:"column",justifyContent:"center" }}>
        <Row className={styles.rowConatiner} >
          <Col xs={12} sm={4} className="d-md-flex justify-content-md-center">
            <div>
            <h6>
              #65/3770B, Qatar Plaza, 
            </h6>
            <h6>East Nadakkavu, Wayanad Road, </h6>
            <h6>Kozhikode, Kerala - 673006</h6>
            </div>
          </Col>
          <Col xs={12} sm={4} className="d-md-flex justify-content-md-center">
            <div>
            <h6>
                PO Box 700, Andover MA,
            </h6>
            <h6>01810, USA</h6>
            </div>
          </Col>
          <Col xs={12} sm={4} className="d-md-flex justify-content-md-center">
            <div>
            <h6>1908, City Tower 2, Sheikh Zayed Road,</h6>
            <h6> Dubai, UAE</h6>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    </>
  );
}

export default ContactUsCountryLocations;
