import React from "react";
import styles from "./homeBannerImageSection.module.css";

const BannerImageSection = () => {
  const backgroundImageURL = 'url("/assets/technology1.WebP")';

  return (
    <>
      <div
        style={{ backgroundImage: backgroundImageURL }}
        className={styles.homeConatiner}
      >
        <div className={styles.technology}>
          <h1>
            Leading the Future: Your Trusted Digital Transformation Company
          </h1>
          <h2>
            Accelerate innovation and growth with Orbio Solutions, a top digital
            transformation company delivering tailored IT solutions for
            sustainable success.
          </h2>
          <h4>INNOVATE . CONNECT . INSPIRE</h4>
          <a href="/about-us" className={styles.btn}>
            Explore More
          </a>
        </div>
      </div>
    </>
  );
};

export default BannerImageSection;
