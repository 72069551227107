import React from "react";
import styles from "./businessItCaseStudy.module.css";
import { Container, Row } from "react-bootstrap";
import CommonBannerImage from "../../../components/CommonBannerImage";

const BusinessItCaseStudy = () => {
  return (
    <>
      <CommonBannerImage backgroundImageURL="/assets/businessitsolution.WebP">
        <div className={styles.overlay}></div>
        <div className={styles.textContainer}>
          <h1>Case-Study: Secure Document Exchange</h1>
        </div>
      </CommonBannerImage>
      <div className="mt-5">
        <Container>
          <Row>
            <h4 className="fw-bold">
              Enhancing Data Management Efficiency and Effectiveness via
              Tailored Application Development.
            </h4>
            <h6 className="fw-bold mt-3">Company Profile:</h6>
            <p>
              Secure Document Exchange (SDE), a client based in Australia is a
              growing provider of the SDE platform designed for law firms to
              aggregate, organize and remodel essential client data, authorized
              by the clients themselves, to be used for further analysis of
              their application. This data intelligence platform will form the
              basis of insights-driven analytics.
            </p>
            <h6 className="fw-bold">Situation:</h6>
            <p>
              Every other day, as businesses look for new and creative ways to
              leverage the data they collect and the technology available at
              their disposal, the use cases for application development are
              constantly evolving.
            </p>
            <p>
              Designed to handle vast and varied data owned by multiple business
              units within law firms, SDE required a robust document management
              platform to meet the escalating demands of legal processes. The
              client wanted an offering that facilitated ease-of-access and
              empowered system owners with comprehensive control, enabling law
              firms to streamline document requests and configurations,
              providing their end clients with user-friendly tools for seamless
              document interaction.
            </p>
            <h6 className="fw-bold">Task:</h6>
            <p>
              The need was to create a secure and robust platform that, i)
              enabled business owners to make maximum use of the consolidated
              data, and ii) earn client trust that is paramount due to the
              sensitive nature of the data. This called for the need to deploy
              robust security measures and establish clear communication
              protocols about how the gathered data will be leveraged.
              Furthermore, ensuring compliance with legal and ethical data
              handling practices was the key as much as building reliable audit
              trails for access and usage of data. Additionally, it was also
              necessary for the platform to be able to handle growing influx of
              data from diverse clients and cases without compromising on
              performance and scalability.
            </p>
            <h6 className="fw-bold">Action:</h6>
            <p>
              In response to the unique challenges faced by our client, Orbio
              Solutions embarked on a comprehensive application development
              initiative. Wireframes and prototypes were iteratively refined
              with client approval, ensuring the final product resonated
              client’s vision and requirements. Strict testing and control
              measures were adapted to ensure no bugs and issues existed. Our
              agile team delivered within the agreed timeline, maintaining clear
              communication and proactively accommodating additional scope
              requests. Through a collaborative approach we implemented both
              standard and advanced features ensuring the data management
              platform not only met but exceed our client expectations.
            </p>
            <h6 className="fw-bold">Result:</h6>
            <p>
              Our client benefitted from the following advantages by partnering
              with Orbio Solutions:
            </p>
            <h6 className="fw-bold">Benefits included,</h6>
            <li>Ease-of-use</li>
            <li>Secured document sharing</li>
            <li>
              User level configurations and permissions providing the needed
              flexibility and limiting unauthorized access to sensitive data
            </li>
            <li>
              Platform that supported scalability to future demands of increased
              data ingest  Secured cloud storage
            </li>
            <li>Platform supported simultaneous loading and querying</li>

            <p className="mt-4">
              Partner with Orbio Solutions today to transform your ideas into
              modern applications that make data tangible and reliable to
              improve your business outcomes.
            </p>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default BusinessItCaseStudy;
