import React from "react";

const ErrorPage = () => {

  return (
    <div
      style={{
        height: "100vh", display: "flex", justifyContent: "center",
        alignItems: "center", flexDirection: "column",
        backgroundColor: "whitesmoke", color: "black",
      }}
    >
      <h1 style={{ color: "black", textAlign: "center", fontWeight: "bold" }}>
        404
      </h1>
      <p style={{ textAlign: "center", fontWeight: "bold" }}>
        Sorry, we couldn't find the page you're looking for. Don't worry! You can discover plenty of other great content on our <a href="/" >homepage</a>.
      </p>
    </div>
  );
};

export default ErrorPage;
