import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import styles from "./aboutSlider.module.css";

const AboutSlider = () => {
  return (
    <div>
      <Container fluid>
        <Row>
          <Col md={6} className="p-0">
            <div className={`${styles.stickyContainerLeft}`}>
              <div
                style={{
                  height: "100vh",
                  backgroundImage: `url('/assets/digitalTransformation.WebP')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  position: "sticky",
                  top: 0,
                }}
              >
                <div className={styles.stickyShadow}></div>
                <div className={styles.firstimgContent}>
                  <div style={{ padding: "20px" }}>
                    <h2>DIGITAL TRANSFORMATION & AUTOMATION</h2>
                    <p style={{ fontStyle: "italic" }}>
                      In-Depth Technology Consulting: Elevating Businesses
                      through Digital Transformation
                    </p>
                    <p>
                      Embark on a transformative journey with Orbio, a digital
                      transformation company that redefines the landscape of
                      automation and IT services. Our comprehensive technology
                      consulting services encompass Cloud Managed Services,
                      Software Product Engineering, IoT, Quality Engineering,
                      Enterprise Apps, and IT Governance, ensuring your digital
                      infrastructure operates at peak performance.
                    </p>
                    <p>
                      At Orbio, we meticulously design and implement cloud
                      solutions that exceed industry standards, ensuring your
                      digital infrastructure operates at peak performance. In
                      Software Product Engineering, our seasoned team guides you
                      from product design through development, testing, and
                      deployment, delivering excellence at every stage. As
                      pioneers in IoT, we craft ingenious solutions that
                      integrate devices, data, and analytics, unlocking the full
                      potential of the Internet of Things. Trust us to lead your
                      organization into a connected future where efficiency and
                      intelligence converge.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} className="p-0">
            <div className={styles.rightSideImageContainer}>
              <div className={styles.imageWithShadow}>
                <LazyLoadImage
                  effect="blur"
                  src="/assets/enterpriseitsolutions.WebP"
                  alt="Enterprise IT Solutions"
                  className={`${styles.rightSideImage}`}
                />
                <div className={styles.centeredText}>
                  <div>
                    <h2>BUSINESS IT SOLUTIONS</h2>
                    <p style={{ fontStyle: "italic" }}>
                      Strategic Planning to Execution: Unleashing Advanced
                      Business IT Solutions for Future-Ready Enterprises
                    </p>
                    <p>
                      At Orbio Solutions, we deliver advanced business IT
                      solutions that empower future-ready enterprises. As a
                      leading IT services and solutions provider, we guide
                      enterprises from strategic planning to flawless execution,
                      ensuring that businesses harness the full potential of
                      technology in their operations. Our relentless focus on
                      digital enablement and business agility ensures that our
                      clients unlock immediate and sustained business value.
                    </p>
                    <p>
                      Our strategic approach is tailored to meet the evolving
                      demands of the digital landscape, ensuring that your
                      business stays ahead of the curve. Whether you're looking
                      to streamline operations, enhance customer engagement, or
                      drive innovation, Orbio Solutions offers bespoke solutions
                      that are designed to exceed expectations. With our
                      expertise, your organization can confidently navigate the
                      complexities of the digital age, achieving sustained
                      growth and success.
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.imageWithShadow}>
                <LazyLoadImage
                  effect="blur"
                  src="/assets/productengineering.WebP"
                  alt="Product Engineering"
                  className={`${styles.rightSideImage}`}
                />
                <div className={styles.centeredText}>
                  <div>
                    <h2>PRODUCT ENGINEERING</h2>
                    <p style={{ fontStyle: "italic" }}>
                      Engineering Excellence: Fulfilling and Surpassing End-User
                      Expectations
                    </p>
                    <p>
                      Experience engineering excellence with Orbio Solutions,
                      where we fulfill and surpass end-user expectations through
                      a meticulous approach to product engineering. From the
                      inception of product design to development, rigorous
                      testing, and deployment, our commitment extends far beyond
                      meeting expectations - it aims to surpass them. As a
                      leading IT solutions company, we draw from industry
                      experience to deliver software products that embody
                      excellence in product engineering and exceed industry
                      benchmarks.
                    </p>
                    <p>
                      At Orbio, we understand that engineering excellence is not
                      just a goal; it's a commitment to a seamless and
                      innovative end-user experience. Our team is dedicated to
                      the relentless pursuit of perfection, ensuring that your
                      software products not only meet but surpass the
                      expectations of your end-users. Trust Orbio Solutions to
                      deliver a level of quality assurance that fosters lasting
                      satisfaction and loyalty.
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.imageWithShadow}>
                <LazyLoadImage
                  effect="blur"
                  src="/assets/mobilityAcceleration9.WebP"
                  alt="Mobility Digital Acceleration"
                  className={`${styles.rightSideImage}`}
                />
                <div className={styles.centeredText}>
                  <div>
                    <h2>MOBILITY & DIGITAL ACCELERATION</h2>
                    <p style={{ fontStyle: "italic" }}>
                      Pioneering Experience Design: Crafting Immersive and
                      Technologically Advanced Digital Experiences
                    </p>
                    <p>
                      As pioneers in experience design, Orbio Solutions is at
                      the forefront of crafting immersive and technologically
                      advanced digital experiences. Our expertise spans UI/UX,
                      digital marketing, eCommerce, and mobility solutions,
                      ensuring a holistic approach to crafting seamless and
                      engaging digital experiences for your audience. From sleek
                      apps to responsive interfaces, we create not just digital
                      solutions but immersive experiences that resonate with
                      your audience.
                    </p>
                    <p>
                      Trust Orbio Solutions to accelerate your digital presence
                      with a unique blend of innovation, finesse, and
                      technological advancement. Our commitment to pioneering
                      experience design ensures that your digital footprint
                      becomes a testament to your brand's commitment to
                      excellence and user-centricity in the ever-evolving
                      digital landscape.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutSlider;
