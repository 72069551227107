import React from 'react';
import { Col } from 'react-bootstrap';
import { FaPlay } from 'react-icons/fa';
import './videoplayerleftSection.css';

const VideoPlayerLeftSection = ({setYoutubeModalOpen})=> {

  // hanleOpenVideoModal
  const hanleOpenVideoModal = ()=>{
    setYoutubeModalOpen(true)
  }
  return (
    <Col md={5} className="videoPlayerContainer" >
      <div className='circlesContainer'>
        {/* Outer Circle */}
        <div className='videoplayerOuterCircle'></div>

        {/* Inner Circle */}
        <div className='videoplayerInnerCircle'>
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundImage: 'url(/assets/video-background1.WebP)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              position: 'relative',
            }}
          >
            {/* Outer Circle around the icon */}
            <div className="wave-effect" onClick={hanleOpenVideoModal}>
              {/* Play icon */}
              <div>
                <FaPlay size={30} color="#fff" style={{marginLeft:"9px"}} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
}

export default VideoPlayerLeftSection;
