import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import styles from "./productOverview.module.css";
import { IoIosArrowRoundForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import CaseStudyDialog from "../CaseStudyDialog";

const ProductOverView = ()=> {

  let caseStudy = {
    id: "0",
    heading: "OttixHow",
    desc: "Transforming E-Commerce Growth and Success: E-Commerce Reseller Implements OttixHow to Power their End-to-End Solutions in the Electronic Goods Industry",
    img: "/assets/ottixhowdesktop.WebP",
    url: "/case-study/ottixHow",
  };

  const navigate = useNavigate();

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    const storedFormSubmitted = localStorage.getItem('formSubmitted');
    setIsFormSubmitted(storedFormSubmitted);
  }, []);

  const handleOpenModal = () => {
    if (isFormSubmitted) {
      navigate(caseStudy?.url)
    } else {
      setModalShow(true);
    }
  };

  const handleOpenCalendly = ()=>{
      window.Calendly.initPopupWidget({ url: 'https://calendly.com/orbiosolutions' });
      return false;
  }
  

  return (
    <>
    {
      !isFormSubmitted &&
      <CaseStudyDialog modalNavigateUrl={caseStudy?.url} modalShow={modalShow} setModalShow={setModalShow} />
    }
    <div>
      <Container>
        <Row className="mt-5 mb-3">
          <Col md={6}>
            <div>
              <h2 className={styles.heading}>
                Refine your pricing strategies by gaining a deeper understanding
                of your competition.
              </h2>
              <p className={`mt-4 ${styles.boldtext}`}>
                OttixHow identifies and analyses competitors, providing product
                price details from various marketplaces in a centralised
                dashboard. The platform scrutinizes trends and competitor
                pricing strategies to stimulate demand, optimize sales, and
                maintain a competitive advantage through meticulous price
                tracking.
              </p>
              <hr className="mt-4 mb-4" />
              <p>
                "We were struggling with remaining competitive in the buy box
                position across platforms for our entire product range as
                competitors frequently adjusted prices. Thanks to OttixHow,
                tasks are now simpler. With a click, we instantly monitor and
                ensure the best value for our customers. OttixHow has become an
                indispensable asset for our business."
              </p>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span className={styles.heading}>Mohammed Zanil</span>
                <span className={styles.heading}>Director</span>
              </div>
              <h5 className="mt-2">AE Digital, UAE</h5>
              <div style={{display:"flex",gap:"10px",marginBottom:"5px"}} >
              <Button
                className={`mt-3 ${styles.btn}`}
                style={{ borderRadius: "19px", background: "#007fc7" }}
                onClick={handleOpenModal}
              >
                Read case study
              </Button>
              <Button
                onClick={handleOpenCalendly}
                className={`mt-3 ${styles.btn}`}
                style={{ borderRadius: "19px", background: "#007fc7" }}
              >
                Book a demo
                <IoIosArrowRoundForward
                  className={styles.arrowIcon}
                  style={{ fontSize: "24px", marginLeft: "2px" }}
                />
              </Button>
              </div>
            </div>
          </Col>
          <Col md={6} style={{ display: "grid", placeItems: "center" }}>
            <div className={styles.threedee}>
              <Image
                fluid
                src="/assets/productlisting.WebP"
                alt="products-listing"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    </>
  );
}

export default ProductOverView;
