import React from "react";
import styles from "./education.module.css";
import CommonBannerImage from "../../components/CommonBannerImage";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ContactFormService from "../../components/ContactFormService";
import { Helmet } from "react-helmet-async";

const Education = () => {

    const navigate = useNavigate()

  const softwareServices = [
    {
      id: "1",
      title:
        "Launch your education platform faster:",
        desc:"Skip the complexities of coding and infrastructure with our readily available solutions"
    },
    {
      id: "2",
      title:
        "Tailor your platform to your vision:",
        desc:"Choose from a variety of customizable features and modules to create a platform that perfectly fits your needs"
    },
    {
      id: "3",
      title:
        "Scale with confidence:",
        desc:"Our solutions are built to handle growing user bases and evolving educational demands"
    },
  ];

  return (
    <>
    <Helmet>
        <meta
          name="keywords"
          content="Education software technology consulting and implementation services provider"
        />
      </Helmet>
      <CommonBannerImage backgroundImageURL="/assets/education-bg.WebP">
        <div className={styles.textContainer}>
          <h1>Education</h1>
          <h6>
            Software education services that enable a smarter future for
            students and institutions alike
          </h6>
        </div>
      </CommonBannerImage>
      <div className="mt-5">
        <Container>
          <div>
            <h4>Discovering boundless possibilities</h4>
          </div>
          <Row>
            <Col md={6}>
              <p>
                The educational sector is dynamically evolving, driven by
                technological advancements and the growing demand for innovative
                learning solutions. Educational software plays a pivotal role in
                modern classrooms, offering interactive and personalized
                learning experiences. Learning Management Systems (LMS),
                educational games, virtual simulations, and content creation
                tools are some of the top applications.
              </p>
              <p>
                In recent years, the integration of Artificial Intelligence (AI)
                and Machine Learning (ML) has further enriched educational
                software by providing adaptive learning systems, intelligent
                tutoring, and data-driven insights into student performance.
                Cloud-based platforms have also become prevalent, facilitating
                seamless access to educational resources and collaboration among
                students and educators.
              </p>
            </Col>
            <Col md={6} style={{display:"flex",justifyContent:"center"}} >
              <Image
                fluid
                src="/assets/educationimg.WebP"
                alt="education"
                style={{ width: "90%", height: "280px",objectFit:"contain" }}
              />
            </Col>
          </Row>
          <Row className="mt-5">
            <h5>
              Orbio Solutions offers a comprehensive suite of software services
              designed to empower you to:
            </h5>
            {softwareServices.map((item) => (
              <Col key={item.id} md={4} className="d-flex">
                <div className={styles.servicecontainer} >
                  <Card className={`d-flex flex-column h-100 ${styles.servicecard}`} >
                    <div>
                      <h6>{item?.title}</h6>
                      <p>{item.desc}</p>
                    </div>
                  </Card>
                </div>
              </Col>
            ))}
          </Row>
          <Row className="mt-4">
            <h5>Our Services:</h5>
            <Col className={`${styles.ourService} ${styles.service1} ${styles.serviceCol}`} md={4}  >
            <div className={styles.servicefirstSection} >
                <img src="/assets/serviceicons/learningmngtedu.WebP" alt="img" style={{height:"auto",width:"25px",objectFit:"contain"}} />
                <p>Learning Management Systems (LMS)</p>
                </div>
                <div className={styles.serviceSecondSection} >
                <h6>Learning Management Systems (LMS):</h6>
                <p style={{fontSize:"12px",}} >Deliver engaging online courses, manage learner progress, and track performance with our powerful LMS.</p>
            </div>
            </Col>
            <Col className={`${styles.ourService} ${styles.service2} ${styles.serviceCol}`} md={4}  >
            <div className={styles.servicefirstSection} >
                <img src="/assets/serviceicons/studentinfosystem.WebP" alt="img" style={{height:"auto",width:"25px",objectFit:"contain"}} />
                <p>Student Information Systems (SIS)</p>
                </div>
                <div className={styles.serviceSecondSection} >
                <h6>Student Information Systems (SIS): </h6>
                <p style={{fontSize:"12px",}} >Simplify student lifecycle management with robust registration, gradebooks, attendance, and communication tools. </p>
            </div>
            </Col>
            <Col className={`${styles.ourService} ${styles.service3} ${styles.serviceCol}`} md={4} >
            <div className={styles.servicefirstSection} >
                <img src="/assets/serviceicons/contentmngtedu.WebP" alt="img" style={{height:"auto",width:"25px",objectFit:"contain"}} />
                <p>Content Creation Tools</p>
                </div>
                <div className={styles.serviceSecondSection} >
                <h6>Content Creation Tools:</h6>
                <p style={{fontSize:"12px",}} >Build interactive learning experiences with ease using our intuitive tools for quizzes, multimedia, and more.</p>
            </div>
            </Col>
            <Col className={`${styles.ourService} ${styles.service4} ${styles.serviceCol}`} md={4} >
            <div className={styles.servicefirstSection} >
                <img src="/assets/serviceicons/e-commerceedu.WebP" alt="img" style={{height:"auto",width:"25px",objectFit:"contain"}} />
                <p>E-commerce Integration</p>
                </div>
                <div className={styles.serviceSecondSection} >
                <h6>E-commerce Integration:</h6>
                <p style={{fontSize:"12px",}} >Streamline enrollment and manage payments seamlessly with our built-in e-commerce features.</p>
            </div>
            </Col>
            <Col className={`${styles.ourService} ${styles.service5} ${styles.serviceCol}`} md={4} >
            <div className={styles.servicefirstSection} >
                <img src="/assets/serviceicons/dataanalyticsedu.WebP" alt="img" style={{height:"auto",width:"25px",objectFit:"contain"}} />
                <p>Data Analytics and Reporting</p>
                </div>
                <div className={styles.serviceSecondSection} >
                <h6>Data Analytics and Reporting:</h6>
                <p style={{fontSize:"12px",}} >Gain valuable insights into user behavior and course performance to make informed decisions.</p>
            </div>
            </Col>
            <Col className={`${styles.ourService} ${styles.service6} ${styles.serviceCol}`} md={4} >
            <div className={styles.servicefirstSection} >
                <img src="/assets/serviceicons/customdevedu.WebP" alt="img" style={{height:"auto",width:"25px",objectFit:"contain"}} />
                <p>Custom Development</p>
                </div>
                <div className={styles.serviceSecondSection} >
                <h6>Custom Development:</h6>
                <p style={{fontSize:"12px",}} >Need something specific? We offer tailored software development services to bring your unique vision to life.</p>
            </div>
            </Col>
          </Row>
          <Row className="mt-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'black', color: 'white', padding: '20px' }}>
            <Col xs={12} sm={10}>
              <h6 className={styles.contactheading}>Explore our options on transforming your educational services</h6>
            </Col>
            <Col xs={12} sm={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button className={styles.btn} onClick={() => navigate('/contact-us')}>Contact us now</Button>
            </Col>
          </Row>
          <Row className="mt-5">
            <h5>Why Choose Orbio Solutions?</h5>
          </Row>
          <Row className="mt-3 mb-3">
            <Col md={6} className={`${styles.service1} p-3`} >
                <div style={{display:"flex",justifyContent:"center",flexDirection:"column"}} >
                    <h6 style={{fontWeight:"bold"}} >Experience: </h6>
                    <p style={{fontSize:"15px"}} >We have a proven track record of helping education entrepreneurs succeed.</p>
                </div>
            </Col>
            <Col md={6} className={`${styles.service3} p-3`} >
            <div style={{display:"flex",justifyContent:"center",flexDirection:"column"}} >
                    <h6 style={{fontWeight:"bold"}} >Expertise: </h6>
                    <p style={{fontSize:"15px"}} >Our team of software developers and education professionals bring the expertise and demonstrated success to build solutions that align with your objectives and requirements.</p>
            </div>
            </Col>
            <Col md={6} className={`${styles.service4} p-3`} >
            <div style={{display:"flex",justifyContent:"center",flexDirection:"column"}} >
                    <h6 style={{fontWeight:"bold"}} >Flexibility: </h6>
                    <p style={{fontSize:"15px"}} >We offer a variety of pricing options to fit your budget.</p>
            </div>
            </Col>
            <Col md={6} className={`${styles.service6} p-3`} >
            <div style={{display:"flex",justifyContent:"center",flexDirection:"column"}} >
                    <h6 style={{fontWeight:"bold"}} >Support: </h6>
                    <p style={{fontSize:"15px"}} >We're here to guide you every step of the way, from conception to launch and beyond.</p>
            </div>
            </Col>
          </Row>
        </Container>
        <ContactFormService />
      </div>
    </>
  );
};

export default Education;
