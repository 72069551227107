import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import styles from "./industriesDropdown.module.css"

const IndustriesDropdown = ()=> {
  return (
    <Container fluid>
        <Row>
        <div style={{
         background: "linear-gradient(180deg,rgba(0,0,0,.2),rgba(0,0,0,0))",
          height: "20px",
          position:"absolute",
          let:"0px",
          right:"0px",
          width:"100%"
        }}></div>
        </Row>
        <Row>
              <Col xs={6} style={{background:"#eeeeee",padding:"20px",}} >
                <div style={{textAlign:"start",marginTop:"30px"}} >
                  <h6 style={{fontSize:"15px",color:"#6f6f6f"}}>All Products and Solutions</h6>
                  <hr style={{margin:"0px",width:"90%",marginTop:"15px"}} />
                  <p style={{fontSize:"14px",color:"#6f6f6f",marginTop:"20px"}} >
                  Industry dialog - At Orbio Solutions, our comprehensive suite of
              products and solutions reflects our dedication to driving
              innovation and exceeding customer expectations. By combining
              cutting-edge technology with industry expertise, we empower
              businesses to thrive in an ever-evolving landscape. 
                  </p>
                </div>
              </Col>
              <Col xs={6} style={{padding:"20px",}}>
              <div style={{textAlign:"start",marginTop:"30px"}} >
                <h6 style={{fontSize:"15px",color:"#6f6f6f"}} >Industries</h6>
                <hr style={{margin:"0px",width:"90%",marginTop:"15px"}} />
                <div style={{display:"flex",flexDirection:"column",gap:"4px",marginTop:"18px",color:"black"}} >
                <NavLink to="/retail" style={{color:"black"}} className={({ isActive }) =>`${ isActive ? `${styles.active}` : ""}`}>Retail</NavLink>
                  <NavLink to="/health-care" style={{color:"black"}} className={({ isActive }) =>`${ isActive ? `${styles.active}` : ""}`} >Health Care</NavLink>
                  <NavLink to="/education" style={{color:"black"}} className={({ isActive }) =>`${ isActive ? `${styles.active}` : ""}`} >Education</NavLink>
                </div>
               </div>
              </Col>
            </Row>
    </Container>
  )
}

export default IndustriesDropdown