import React from "react";
import Modal from "react-bootstrap/Modal";

const YoutubeVideoModal = ({ youtubeModalOpen, setYoutubeModalOpen }) => {
  return (
    <div>
      <Modal
        show={youtubeModalOpen}
        onHide={() => setYoutubeModalOpen(false)}
        centered
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        {/* <Modal.Body> */}
        <iframe
          allow="autoplay"
          title="video"
          width="100%"
          height="480"
          src="https://www.youtube.com/embed/5QHWDng85GA?si=eJdcl8wT16XkQS1N&autoplay=1"
          frameBorder="0"
          allowFullScreen
        ></iframe>
        {/* </Modal.Body> */}
      </Modal>
    </div>
  );
};

export default YoutubeVideoModal;
