import React from 'react';
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import { Container, Image, Row } from 'react-bootstrap';
import styles from "./aboutPageClients.module.css";

const AboutPageClients = ()=> {
  const clientsData = [
    { id: "1", img: "/assets/client1-delta.WebP" },
    { id: "2", img: "/assets/client2-dubai-equine-hospital.WebP" },
    { id: "3", img: "/assets/client3-ecomedes.WebP" },
    { id: "4", img: "/assets/client4-emaar.WebP" },
    { id: "5", img: "/assets/client5-global-pricing.WebP" },
    { id: "6", img: "/assets/client6-mintmesh.WebP" },
    { id: "7", img: "/assets/client7-my-firm-data.WebP" },
    { id: "8", img: "/assets/client7-mps.webP" }
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
      slidesToSlide: 1 
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
      slidesToSlide: 1 
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 
    }
  };

  return (
    <div className={`${styles.mainContainer} mt-4`} >
      <Container>
        <Row>
          <h3 className={styles.heading} >Powering top organizations worldwide</h3>
        </Row>
        <Row>
        <Carousel
        swipeable={true}
        draggable={true}
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        transitionDuration={500}
        containerClass="carousel-container"
        deviceType={'desktop'}
        customTransition="transform 500ms ease-in-out"
        itemClass={styles['custom-carousel-item']}
      >
        {clientsData.map((client) => (
          <div key={client.id} style={{display:"flex",justifyContent:"center",alignItems:"center",textAlign:"center"}}>
            <Image className={styles.img} src={client.img} alt={`Client ${client.id}`} />
          </div>
        ))}
      </Carousel>
        </Row>
      </Container>
    </div>
  );
}

export default AboutPageClients;
