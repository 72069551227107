import React from "react";
import ProductOverView from "../../components/ProductOverView";
import ProductKeyPoints from "../../components/ProductKeyPoints";
import ProductBudgetPlan from "../../components/ProductBudgetPlan";
import ProductWebsiteCounts from "../../components/ProductWebsiteCounts";
import ProductHelpAccordion from "../../components/ProductHelpAccordion";
import ProductVideoSection from "../../components/ProductVideoSection/inedx";
import CommonBannerImage from "../../components/CommonBannerImage";
import styles from "./productPage.module.css";
import { Helmet } from "react-helmet-async";

function ProductPage() {
  return (
    <div>
      <Helmet>
        <meta
          name="keywords"
          content="Retail pricing analytics artificial intelligence software company"
        />
      </Helmet>
      <CommonBannerImage backgroundImageURL="/assets/ottixhow-background.WebP">
        <div className={styles.textContainer}>
          <h1>OttixHow</h1>
          <h6>
            Gain invaluable insights into pricing strategies, empowering you to
            make well-informed decisions that keep you competitive in the
            market.
          </h6>
        </div>
      </CommonBannerImage>
      <ProductOverView />
      <ProductVideoSection />
      <ProductKeyPoints />
      <ProductBudgetPlan />
      <ProductWebsiteCounts />
      <ProductHelpAccordion />
    </div>
  );
}

export default ProductPage;
