import React from "react";
import styles from "./retailCaseStudy.module.css";
import { Container, Row } from "react-bootstrap";
import CommonBannerImage from "../../../components/CommonBannerImage";

const RetailCaseStudy = () => {
  return (
    <>
      <CommonBannerImage backgroundImageURL="/assets/retail-bg.WebP">
        <div className={styles.overlay}></div>
        <div className={styles.textContainer}>
          <h1>Case-Study: StoreE’s</h1>
        </div>
      </CommonBannerImage>
      <div className="mt-5">
        <Container>
          <Row>
            <h4 className="fw-bold">
              How Orbio Solutions Built StoreE’s Feature-rich E-Portal through
              Specialized Development and Testing
            </h4>
            <h6 className="fw-bold mt-3">Company Profile:</h6>
            <p>
              StoreE is one of the fastest growing fashion e-commerce portals
              catering to online shoppers in India. It offers high-end products
              in fashion wear. Their e-commerce portal is committed towards
              providing the highest value-for-money.
            </p>
            <h6 className="fw-bold">Situation:</h6>
            <p>
              StoreE needed a feature-rich, responsive portal built within a
              limited timeframe. The initial product list comprised of 5,000+
              items across 20+ categories, posing a complexity challenge. The
              development needs were further increased by the demand for
              integration with an already-existing ERP system.
            </p>

            <h6 className="fw-bold">Task:</h6>
            <p>
              StoreE wanted a trustworthy vendor capable of constructing an
              e-portal using their preferred content management system (CMS).
              The vendor was required to prioritize and strategize a set of
              essential features crucial for the performance of the online
              portal. Some other mandatory requirements included the portal to
              maintain an intuitive and user-friendly interface, optimized for
              speed and performance, and to provide a consistent experience
              across various devices, including desktops, tablets, and
              smartphones.
            </p>
            <h6 className="fw-bold">Action:</h6>
            <p>
              Orbio Solutions began its journey by understanding the feature
              requirements, functionalities and involving complexities. By
              prioritizing the list of features Orbio Solutions had a clear path
              to portal development and testing. Understanding of the optimized
              technology stack including the programming languages, frameworks,
              databases and the content management system in place meant Orbio
              Solutions chose a well-rounded approach to portal development.
              Wireframes were developed to visualize the portal layout and gain
              confidence and confirmation from StoreE.
            </p>
            <p>
              Orbio Solutions delivered a robust portal that ran on MagentO
              enterprise version CMS. We were able to provide all of the
              features and functionalities StoreE were looking for. The portal
              UI was developed to be responsive enough to view in desktop and
              also on multiple mobile devices. The portal was hosted within AWS
              with CDN technology. Orbio Solutions also integrated the portal
              with StoreE’s ERP system using tools from Microsoft Technologies.
            </p>

            <h6 className="fw-bold">Result:</h6>
            <p>
              Orbio Solutions delivered the portal for StoreE that successfully
              met their objectives.
            </p>
            <p>Following were the outcome:</p>
            <li className="fw-bold">
              Increased Visibility and Traffic:
              <span className="fw-normal">
                The responsive design and robust features attracted a
                significant increase in daily page hits, indicating improved
                visibility and user engagement. In a period of six months to an
                year, the page hits grew from 0 to 20,000 per day.
              </span>{" "}
            </li>
            <li className="fw-bold">
              Diversified Product Catalog:
              <span className="fw-normal">
                With 25,000 SKUs across 20+ categories, StoreE effectively
                expanded its product offerings, catering to a broader customer
                base and increasing overall sales potential.
              </span>
            </li>
            <li className="fw-bold">
              Enhanced User Experience:
              <span className="fw-normal">
                The implementation of features such as search, wish lists, and
                brand pages contributed to a more enjoyable and personalized
                shopping experience for customers.
              </span>
            </li>
            <li className="fw-bold">
              Operational Efficiency:
              <span className="fw-normal">
                Integration with Microsoft Technologies-based ERP streamlined
                backend operations, allowing for better inventory management and
                order fulfillment.
              </span>
            </li>
            <li className="fw-bold">
              Sales Growth:
              <span className="fw-normal">
                The successful deployment of the e-commerce portal resulted in a
                remarkable increase in daily sales, with an average of 400
                transactions per day within the first year.
              </span>
            </li>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default RetailCaseStudy;
