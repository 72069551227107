import React from "react";
import CommonBannerImage from "../../components/CommonBannerImage";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import styles from "./mobilityDigitalAcceleration.module.css";
import { Helmet } from "react-helmet-async";
import ContactFormService from "../../components/ContactFormService";
import ServiceKeyPoints from "../../components/ServiceKeyPoints";
import { GoDash } from "react-icons/go";
import StripBanner from "../../components/StripBanner";
import HoverCard from "../../components/HoverCard";
import { Link } from "react-router-dom";

const MobilityDigitalAcceleration = () => {
  const serviceSchema = {
    "@context": "https://schema.org",
    "@type": "Service",
    serviceType: "Mobility & Digital Acceleration",
    provider: {
      "@type": "Organization",
      name: "Orbio Solutions",
      url: "https://www.orbiosolutions.com",
    },
    description:
      "Discover Orbio Solutions' mobility services tailored for businesses. Optimize user experience, enhance mobile performance, and drive digital transformation.",
    offers: {
      "@type": "Service",
      url: "https://www.orbiosolutions.com/mobility-digital-acceleration",
    },
  };

  const keyPoints = [
    {
      id: "1",
      title: "Strategic Planning & Stakeholder Engagement",
      desc: "We begin every project with a thorough understanding of your business goals. By engaging stakeholders from the start, we ensure everyone is aligned with the project’s objectives, paving the way for successful implementation.",
    },
    {
      id: "2",
      title: "Digital Strategy Development",
      desc: "Our team crafts innovative digital strategies tailored to your business needs. We focus on creating forward-thinking strategies that not only address your current challenges but also anticipate future growth opportunities.",
    },
    {
      id: "3",
      title: "Technology Stack Selection",
      desc: "Choosing the right technology is critical to the success of your mobility initiatives. Our experts guide you in selecting the optimal technology stack to ensure high performance, scalability, and security.",
    },
    {
      id: "4",
      title: "Data Security and Compliance",
      desc: `<a href="https://en.wikipedia.org/wiki/Data_security" target="_blank" rel="noreferrer" >Data security</a> is a top priority in all our solutions. We implement best-in-class data security measures and ensure compliance with industry standards, so your business can operate with peace of mind.`,
    },
    {
      id: "5",
      title: "Integration with Existing Systems",
      desc: "Seamless integration is key to driving efficiency. Our mobility solutions are designed to integrate smoothly with your existing systems, enhancing functionality without disrupting your business operations.",
    },
    {
      id: "6",
      title: "Testing and Quality Assurance",
      desc: "Before deployment, we conduct rigorous testing to ensure that all systems and applications meet the highest standards of reliability and performance. Our quality assurance process guarantees that your digital solutions are free of errors and ready for launch.",
    },
    {
      id: "7",
      title: "Deployment",
      desc: "Our deployment strategies are designed to ensure a smooth rollout of your mobile applications and digital solutions. We work closely with your team to manage the deployment process, minimizing disruptions and maximizing efficiency.",
    },
    {
      id: "8",
      title: "Performance Monitoring and Optimization",
      desc: "Post-deployment, we continuously monitor the performance of your applications to ensure they are running optimally. We provide ongoing support to address any issues and make adjustments that improve user experience and performance.",
    },
    {
      id: "9",
      title: "Feedback Loops and Continuous Improvement",
      desc: "At Orbio, we believe in the power of continuous improvement. We establish feedback loops that allow us to gather insights and make ongoing enhancements to your digital solutions, ensuring that your business remains competitive in an ever-changing market.",
    },
  ];

  const hoverCardData = [
    {
      imgSrc: "/assets/ui-ux.WebP",
      title: "Custom UI/UX Design for Enhanced User Experience",
      description:
        "At Orbio Solutions, we believe that great user experiences drive business success. Our creative and seasoned UI/UX designers work closely with clients to design intuitive, user-centric interfaces that enhance engagement and user satisfaction. With decades of combined experience in handling global design projects, we create visually appealing, functional, and efficient interfaces for mobile applications.",
    },
    {
      imgSrc: "/assets/future.WebP",
      title: "E-Commerce Solutions for the Digital Age",
      description:
        "We understand the challenges businesses face in the online marketplace. That’s why Orbio offers comprehensive e-commerce consulting and implementation solutions designed to help your retail business reach its highest potential. Our expertise spans a wide range of industries, enabling us to deliver solutions that increase sales, improve customer engagement, and enhance the overall shopping experience.",
    },
    {
      imgSrc: "/assets/android.WebP",
      title: "Tailor-Made Mobility Solutions for iOS and Android",
      description:
        "Whether you're looking to build a new mobile application or optimize an existing one, our team delivers unique mobility solutions for both iOS and Android platforms. We take a customized approach to mobile app development, ensuring that each solution is designed to meet your specific business requirements. Our focus on user experience guarantees superior visitor engagement and lasting traction from the first click.",
    },
  ];

  const customCard = {
    backgroundColor: "white",
    border: "1px solid black",
    borderRadius: "8px",
    padding: "3px",
  };

  return (
    <>
      <Helmet>
        <title>Mobility Services for Business Growth | Orbio Solutions</title>
        <meta
          name="keywords"
          content="Mobility and digital acceleration service provider"
        />
        <meta
          name="description"
          content="Discover Orbio Solutions' mobility services tailored for businesses. Optimize user experience, enhance mobile performance, and drive digital transformation."
        />
        <meta
          property="og:title"
          content="Comprehensive Mobility and Digital Acceleration Services"
        />
        <meta
          property="og:description"
          content="Leverage Orbio's mobility and digital acceleration services to transform your business. Achieve seamless mobile integration and innovative digital growth."
        />
        <script type="application/ld+json">
          {JSON.stringify(serviceSchema)}
        </script>
      </Helmet>
      <CommonBannerImage backgroundImageURL="/assets/mobility-digital-acceleration.WebP">
        <div className={styles.overlay}></div>
        <div className={styles.textContainer}>
          <h1>Mobility Services for a Connected Tomorrow</h1>
          <h6>
            Leverage Orbio’s expertise in{" "}
            <span className="fw-bold">mobility services</span> to drive
            innovation, enhance user experiences and accelerate your digital
            growth. Let us help you create a seamless, high-performance mobile
            presence that sets your business apart.
          </h6>
        </div>
      </CommonBannerImage>

      <div className="mt-4 mb-2">
        <Container>
          <Row>
            <Col md={6} className={styles.anlyseContainer}>
              <h3 className="mb-1 fs-3 text-black">
                Mobility & Digital Acceleration:
              </h3>
              <h5 className="mb-3 overViewTitle fs-5">
                <GoDash style={{ color: "#007fc7" }} /> Your Partner for
                Mobility & Digital Acceleration
              </h5>
              <p>
                In today’s competitive market, businesses must differentiate
                themselves through superior products, services, and customer
                experiences. Orbio Solutions offers a comprehensive range of{" "}
                <Link to="/">mobility services</Link> to help you stay ahead. We
                collaborate with businesses to provide end-to-end solutions that
                enhance mobile engagement, boost digital performance, and
                deliver significant results.
              </p>
              <p>
                Our{" "}
                <span className="fw-bold">
                  mobility and digital acceleration services
                </span>{" "}
                empower businesses to harness the potential of technology in
                ways that drive both immediate and sustained value.
              </p>
            </Col>
            <Col
              md={6}
              className={`${styles.anlyseImgContainer} d-flex justify-content-center align-items-center`}
            >
              <Image
                alt="mobility-digital"
                src="/assets/mobility-digital-screen.WebP"
                fluid
                style={{ objectFit: "contain", height: "100%", width: "100%" }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="mt-4">
        <Container>
          <h2 className="mb-4 text-black fs-3">
            Comprehensive Mobility Services for Business Growth
          </h2>
          <Row className="d-flex justify-content-center">
            <HoverCard cardData={hoverCardData} />
          </Row>
        </Container>
      </div>

      <div className="mt-4 mb-4">
        <Container>
          <h3 className="text-black">
            Digital Marketing Expertise for Holistic Growth :
          </h3>
          <Row>
            <Col md={6} className="mb-2 mb-md-0">
              <Card style={customCard}>
                <Card.Body>
                  <Card.Title className="fs-5 overViewTitle">
                    <GoDash style={{ color: "#007fc7" }} />
                    SEO, SEM, and Digital Branding Services
                  </Card.Title>
                  <Card.Text className="textJustify" style={{ color: "black" }}>
                    Orbio Solutions goes beyond app development by offering a
                    comprehensive suite of{" "}
                    <span className="fw-bold">digital marketing services</span>{" "}
                    that elevate your brand’s online presence. From SEO to SEM,
                    we provide end-to-end services that help you attract more
                    traffic, generate leads, and convert visitors into loyal
                    customers. Our experts work closely with you to create a
                    digital marketing strategy that drives business growth and
                    strengthens your digital footprint.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card style={customCard}>
                <Card.Body style={{ color: "black" }}>
                  <Card.Title className="fs-5 overViewTitle">
                    <GoDash style={{ color: "#007fc7" }} />
                    Lead Generation for Maximum ROI
                  </Card.Title>
                  <Card.Text className="textJustify">
                    We help businesses maximize their return on investment with
                    focused lead-generation strategies. Our team uses
                    cutting-edge digital tools and techniques to target the
                    right audience, ensuring that your marketing efforts result
                    in meaningful business outcomes. By combining data-driven
                    insights with creative execution, we deliver lead-generation
                    campaigns that produce measurable results.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <ServiceKeyPoints
        heading="A Strategic Approach to Mobility and Digital Acceleration:"
        keyPoints={keyPoints}
        customHeight={"180px"}
      />

      <div className="mt-4">
        <Container>
          <StripBanner
            title="Why Choose Orbio Solutions for Mobility Services?"
            desc={`
              <p>
               At Orbio Solutions, we combine creativity, technical expertise, and industry knowledge to deliver <span class="fw-bold" >mobility and digital acceleration services</span> that exceed expectations. 
               Whether you're looking to enhance your mobile presence, improve customer engagement, or accelerate your digital transformation, we have the experience and tools to help you succeed.
              </p>
              <p>
                Our approach is built on understanding your unique business challenges and delivering tailored solutions that drive real results. 
                With a focus on innovation, reliability, and continuous improvement, we are committed to helping your business achieve sustained growth and digital excellence.
              </p>
              `}
          />
        </Container>
      </div>

      <ContactFormService
        caseStudyLength={1}
        title={`Ready to take your business to the next level? Contact us today to learn how our mobility services can help you innovate, accelerate, and grow in the digital age. Whether you're looking for <a href="/product-engineering" >mobile app development</a>, e-commerce solutions, or digital marketing expertise, Orbio Solutions is your partner for success.`}
      />
    </>
  );
};

export default MobilityDigitalAcceleration;
