import React from "react";
import CommonBannerImage from "../../components/CommonBannerImage";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import styles from "./businessItSolutions.module.css";
import ServiceMainContent from "../../components/ServiceMainContent";
import { Helmet } from "react-helmet-async";
import ContactFormService from "../../components/ContactFormService";
import ServiceKeyPoints from "../../components/ServiceKeyPoints";
import { GoDash } from "react-icons/go";
import StripBanner from "../../components/StripBanner";
import HoverCard from "../../components/HoverCard";
import { Link } from "react-router-dom";

const BusinessItSolutions = () => {
  const serviceSchema = {
    "@context": "https://schema.org",
    "@type": "Service",
    serviceType: "Business IT Solutions",
    provider: {
      "@type": "Organization",
      name: "Orbio Solutions",
      url: "https://www.orbiosolutions.com",
    },
    description:
      "Boost performance with our managed IT services. Get expert IT support, enterprise security solutions, and customized business IT solutions to enhance efficiency.",
    offers: {
      "@type": "Service",
      url: "https://www.orbiosolutions.com/business-it-solutions",
    },
  };

  const keyPoints = [
    {
      id: "1",
      title: "Comprehensive IT Infrastructure Assessment",
      desc: "Before implementing any IT solution, our team conducts a thorough assessment of your current infrastructure. This includes analyzing your software, hardware, and network systems to identify areas of improvement. The assessment also allows us to tailor our services to fit your exact needs.",
    },
    {
      id: "2",
      title: "IT Implementation and Security Fortification",
      desc: "Once the strategy is in place, we proceed with the implementation phase, where we deploy the necessary technologies and fortify your systems with industry-leading enterprise security solutions. Our team ensures seamless integration, mitigating any disruptions during the transition period.",
    },
    {
      id: "3",
      title: "Continuous Monitoring and Proactive Support",
      desc: "Our managed IT services don’t end at implementation. We provide round-the-clock support and monitoring to detect potential issues before they impact your operations. This proactive approach ensures that your systems run smoothly while maintaining top-tier security.",
    },
    {
      id: "4",
      title: "User Training and Support Documentation",
      desc: "To help your team navigate the new systems efficiently, we offer comprehensive user training. Additionally, we provide detailed documentation to ensure your team has the resources they need to troubleshoot basic issues and maximize system usage.",
    },
  ];

  let caseStudy = {
    id: "0",
    heading:
      "Building a Custom Application for Enhanced Efficiency and Security",
    desc: `Enhancing Data Management Efficiency and Effectiveness via Tailored <a href="https://en.wikipedia.org/wiki/Application_development" target="_blank" rel="noreferrer">Application Development</a>.`,
    img: "/assets/sdx-lap-screen.WebP",
    url: "/case-study/building-custom-application-for-enhanced-efficiency-and-security",
  };

  const customCard = {
    backgroundColor: "white",
    border: "1px solid black",
    borderRadius: "8px",
    padding: "3px",
  };

  const hoverCardData = [
    {
      imgSrc: "/assets/ai-strategy.WebP",
      title: "Planning and IT Strategy Development",
      description:
        "Every successful IT solution starts with a well-structured strategy. We begin by assessing your current infrastructure and planning tailored IT support services that align with your business goals. Our experts craft a roadmap that addresses both short-term needs and long-term business growth, ensuring your IT setup is future-ready.",
    },
    {
      imgSrc: "/assets/digitalsecurity-bg.WebP",
      title: "Enterprise Security Solutions",
      description:
        "Protecting your data and assets is our priority. We design and implement enterprise security solutions that safeguard your business against potential cyber threats. From network monitoring to proactive threat detection, we ensure that your business remains secure with the latest industry protocols.",
    },
    {
      imgSrc: "/assets/future.WebP",
      title: "Scalable Cloud Solutions",
      description:
        "As part of our managed IT services, we offer cloud solutions that allow for scalability as your business grows. Our cloud management services ensure data availability, security, and disaster recovery, so your business can function seamlessly from anywhere, at any time.",
    },
    {
      imgSrc: "/assets/businessitsolution.WebP",
      title: "Performance Optimization and Maintenance",
      description:
        "Once your IT infrastructure is in place, we continuously monitor its performance to identify areas for optimization. Whether it’s software performance or network speed, we make sure that your systems are functioning at their best. Our IT experts regularly perform updates and fine-tuning to enhance productivity.",
    },
    {
      imgSrc: "/assets/Robotic-ai.WebP",
      title: "Advanced Cybersecurity Solutions",
      description:
        "Cybersecurity is a cornerstone of our managed IT services. We deliver enterprise security solutions that protect your business from evolving cyber threats. Through regular security assessments, we identify vulnerabilities and apply robust security measures like encryption, firewalls, and threat intelligence to keep your data safe.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          Managed IT Services | Optimize Business Efficiency & Security
        </title>
        <meta
          name="keywords"
          content="Business IT solutions for companies of all sizes"
        />
        <meta
          name="description"
          content="Boost performance with our managed IT services. Get expert IT support, enterprise security solutions, and customized business IT solutions to enhance efficiency."
        />
        <meta
          property="og:title"
          content="IT Support Services: Boost Business with Secure Solutions"
        />
        <meta
          property="og:description"
          content="Discover reliable IT support services for your business. Improve efficiency, protect enterprise security, and get tailored solutions for optimal performance."
        />
        <script type="application/ld+json">
          {JSON.stringify(serviceSchema)}
        </script>
      </Helmet>
      <CommonBannerImage backgroundImageURL="/assets/businessitsolution.WebP">
        <div className={styles.overlay}></div>
        <div className={styles.textContainer}>
          <h1>Empower Your Business with Tailored Managed IT Services</h1>
          <h6>
            Enhance your operations with advanced managed IT services crafted to
            boost performance, strengthen security, and streamline efficiency
            across all business functions.
          </h6>
        </div>
      </CommonBannerImage>
      <div>
        <Container>
          <Row>
            <Col md={6} className={styles.anlyseContainer}>
              <h3 className="mb-1 fs-3 text-black">Business IT Solutions:</h3>
              <h5 className="mb-3 overViewTitle fs-5">
                <GoDash style={{ color: "#007fc7" }} />
                Managed IT Services for Business Growth
              </h5>
              <p>
                At Orbio Solutions, we deliver comprehensive{" "}
                <Link to="/business-it-solutions">managed IT services </Link>
                that align with your business needs, ensuring seamless
                integration, enhanced performance, and fortified security. From
                streamlining your IT infrastructure to providing ongoing support
                and maintenance, our team of experts is dedicated to
                transforming your IT systems into a robust framework that drives
                business success.
              </p>
            </Col>
            <Col
              md={6}
              className="d-flex justify-content-center align-items-center"
            >
              <Image
                alt="business-it-solutions"
                src="/assets/business-it-screen.WebP"
                fluid
                style={{ objectFit: "contain", height: "100%", width: "100%" }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="mt-4">
        <Container>
          <StripBanner
            title="Why Choose Orbio Solutions for Managed IT Services?"
            desc={`<p>
          Our <span class="fw-bold" >managed IT services</span> offer a complete range of solutions designed to meet the challenges of today’s digital world. With extensive expertise in <span class="fw-bold" >business IT solutions</span>, <span class="fw-bold" >IT support services</span>, and <span class="fw-bold" >enterprise security solutions</span>, we ensure that your business remains agile, secure, and competitive in a fast-evolving market. From planning and implementation to ongoing monitoring and optimization, our managed services are engineered for your long-term success.
          </p>
          `}
          />
        </Container>
      </div>
      <div className="mt-4">
        <Container>
          <h3 className="mb-4 text-black">
            Key Services in Our Managed IT Solutions
          </h3>
          <Row className="d-flex justify-content-center">
            <HoverCard cardData={hoverCardData} />
          </Row>
        </Container>
      </div>
      <ServiceKeyPoints
        heading="The Managed IT Services Process:"
        keyPoints={keyPoints}
        customHeight="190px"
      />
      <div className="mt-4">
        <Container>
          <h3 className="text-black">
            Advanced Enterprise Security Solutions for Future-Ready Businesses{" "}
          </h3>
          <p>
            At Orbio Solutions, we don’t just manage your IT infrastructure; we
            protect it. As part of our managed IT services, we offer{" "}
            <span className="fw-bold">enterprise security solutions</span>{" "}
            tailored to your business’s unique risk profile. From securing
            sensitive data to protecting your network from external threats, our
            cybersecurity solutions are designed to safeguard your business
            against any cyber risks.
          </p>
        </Container>
      </div>

      <div className="mt-4">
        <Container>
          <h3 className="text-black">Our cybersecurity services include:</h3>
          <Row>
            <Col md={6} className="mb-2 mb-md-0">
              <Card style={customCard}>
                <Card.Body>
                  <Card.Title className="fs-5 overViewTitle">
                    <GoDash style={{ color: "#007fc7" }} />
                    Data Protection Strategies
                  </Card.Title>
                  <Card.Text className="textJustify" style={{ color: "black" }}>
                    Secure your business’s critical data with encryption,
                    regular backups, and disaster recovery solutions.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card style={customCard}>
                <Card.Body style={{ color: "black" }}>
                  <Card.Title className="fs-5 overViewTitle">
                    <GoDash style={{ color: "#007fc7" }} />
                    Threat Intelligence
                  </Card.Title>
                  <Card.Text className="textJustify">
                    We stay ahead of potential risks with real-time{" "}
                    <span className="fw-bold">
                      cyber threat intelligence services
                    </span>
                    , helping you mitigate threats before they become a problem.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-md-2 mb-2 d-flex justify-content-center">
            <Col md={6} className="mb-2 mb-md-0 mt-2 mt-md-0">
              <Card style={customCard}>
                <Card.Body>
                  <Card.Title className="fs-5 overViewTitle">
                    <GoDash style={{ color: "#007fc7" }} />
                    Vulnerability Management
                  </Card.Title>
                  <Card.Text className="textJustify" style={{ color: "black" }}>
                    Our proactive assessments identify potential weak spots in
                    your systems, allowing us to apply patches and updates to
                    prevent cyber attacks.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <ServiceMainContent caseStudy={caseStudy} />

      <div className="mt-4">
        <Container className="d-flex flex-column gap-3">
          <h2 className="text-black">
            Why Managed IT Services Matter for Your Business Growth
          </h2>
          <Row>
            <Col md={6} className="mb-2 mb-md-0">
              <Card
                style={{
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                  minHeight: "145px",
                }}
              >
                <Card.Body>
                  <Card.Title>
                    <h5
                      style={{ fontSize: "16px" }}
                      className="fw-bold text-black"
                    >
                      Secure Data and Business Continuity
                    </h5>
                  </Card.Title>
                  <Card.Text style={{ fontSize: "14px" }}>
                    With increasing cyber threats, data protection is more
                    important than ever. Our{" "}
                    <span className="fw-bold">managed IT services</span> ensure
                    that your business data is secure, compliant, and backed up,
                    safeguarding your business operations against unexpected
                    disruptions.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card
                style={{
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                  minHeight: "145px",
                }}
              >
                <Card.Body>
                  <Card.Title>
                    <h5
                      style={{ fontSize: "16px" }}
                      className="fw-bold text-black"
                    >
                      Optimized IT Support for Scalability
                    </h5>
                  </Card.Title>
                  <Card.Text style={{ fontSize: "14px" }}>
                    As your business grows, your IT needs will evolve. Our
                    managed IT services provide the flexibility and scalability
                    needed to expand without compromising on performance or
                    security. By focusing on efficient IT support services, we
                    ensure that your business is always prepared for future
                    demands.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="mt-4">
        <Container>
          <StripBanner
            title="Customized IT Solutions to Meet Your Needs"
            desc={`
              <p>
           At Orbio Solutions, we understand that no two businesses are the same. That’s why our <a href="/" >business IT solutions</a> are completely customizable, ensuring that your IT infrastructure aligns perfectly with your company’s specific needs and objectives.
          From small startups to global enterprises, we have the expertise to provide the best IT services for your business.
          </p>
            `}
          />
        </Container>
      </div>

      <div className="mt-4">
        <Container>
          <h3 className="text-black">
            Ready to Transform Your IT Infrastructure?
          </h3>
          <p>
            At Orbio Solutions, we are committed to helping businesses thrive by
            providing exceptional{" "}
            <Link to="/contact-us">managed IT services</Link> that meet your
            specific needs. Whether you require IT support services, business IT
            solutions, or enterprise security solutions, we have the expertise
            to drive your success.
          </p>
          <p>
            Contact us today to learn more about how we can optimize your IT
            infrastructure.
          </p>
        </Container>
      </div>

      <ContactFormService
        caseStudyLength={1}
        title="We are dedicated to helping you optimize your IT infrastructure and enhance business performance. Contact us today to find out more about our managed IT services and how we can help your business thrive in a competitive marketplace."
      />
    </>
  );
};

export default BusinessItSolutions;
