import React, { useEffect, useState } from 'react'
import ErrorPage from '../ErrorPage'

const ProtectedPage = ({component:Component}) => {

    const [state,setState] = useState(false)
    let a = localStorage.getItem("formSubmitted")
    
    useEffect(() => {
      if(a){
        setState(true)
      }
    }, []) //eslint-disable-line
    
    
    if (state === true) {
        return <Component />;
    } else {
        return <ErrorPage />;
    }
}

export default ProtectedPage
