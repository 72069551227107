import React from "react";
import CommonBannerImage from "../../components/CommonBannerImage";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import styles from "./productEngineering.module.css";
import ServiceMainContent from "../../components/ServiceMainContent";
import { Helmet } from "react-helmet-async";
import ContactFormService from "../../components/ContactFormService";
import ServiceKeyPoints from "../../components/ServiceKeyPoints";
import { GoDash } from "react-icons/go";
import CommonCards from "../../components/CommonCards";
import StripBanner from "../../components/StripBanner";
import { FaBuilding, FaCloud, FaCogs, FaMobileAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

const ProductEngineering = () => {
  const serviceSchema = {
    "@context": "https://schema.org",
    "@type": "Service",
    serviceType: "Product Engineering",
    provider: {
      "@type": "Organization",
      name: "Orbio Solutions",
      url: "https://www.orbiosolutions.com",
    },
    description:
      "Discover how Orbio Solutions leverages data and artificial intelligence to enhance your business operations, drive innovation, and deliver insightful solutions.",
    offers: {
      "@type": "Service",
      url: "https://www.orbiosolutions.com/product-engineering",
    },
  };

  const keyPoints = [
    {
      id: "1",
      title: "Development and Agile Coding",
      desc: "Our expert development teams leverage agile methodologies to bring your product to life. From web-based platforms to enterprise systems, we ensure efficient and high-quality coding that delivers superior functionality.",
    },
    {
      id: "2",
      title: "Quality Control and Testing",
      desc: "We take product reliability seriously. Our quality assurance process involves rigorous testing at every stage, guaranteeing seamless performance. This includes functional testing, security assessments, and usability checks to ensure your product is ready for real-world use.",
    },
    {
      id: "3",
      title: "Product Deployment and Go-to-Market Strategy",
      desc: "Once development is complete, we work to seamlessly deploy your product. Our focus is not only on a smooth launch but also on a strategic go-to-market approach that maximizes product visibility and user adoption.",
    },
  ];

  let caseStudy = {
    id: "0",
    heading: "Process Engineering in the Legal Industry",
    desc: "Revolutionizing Your Clio Experience Through The Power Of Data Tracing, Troubleshooting and Scheduler Advancements.",
    img: "/assets/my-firm-data-lap-screen.WebP",
    url: "/case-study/process-engineering-in-the-legal-industry",
  };

  const cardData = [
    {
      id: "1",
      title: "Ideation and Concept Validation",
      img: "/assets/businessitsolution.WebP",
      desc: "At the heart of every successful product lies a solid idea. We begin by working closely with your team to transform visionary ideas into market-ready concepts. Through extensive validation techniques, we ensure that your product idea resonates with target audiences and is primed for success.",
    },
    {
      id: "2",
      title: "Market Research and Requirements Analysis",
      img: "/assets/datadriven.WebP",
      desc: "Our dedicated team conducts thorough market research to understand user needs, industry trends, and competitive landscapes. This phase also includes requirements analysis, helping you build solutions that are both user-centric and effective for the current market.",
    },
    {
      id: "3",
      title: "Feasibility Study and Risk Assessment",
      img: "/assets/help-business.WebP",
      desc: "Before any development begins, we ensure the viability of your project through detailed feasibility studies. We evaluate technical, financial, and operational factors to mitigate risks and maximize success potential.",
    },
    {
      id: "4",
      title: "Innovative Product Design and Prototyping",
      img: "/assets/innovation.WebP",
      desc: "With an eye for innovation, we craft product designs and prototypes that reflect your vision while maintaining functionality. Our prototypes help validate user experience, set development expectations, and ensure an impactful final product.",
    },
    {
      id: "5",
      title: "Precision Engineering and Analysis",
      img: "/assets/strategic-pricing-intelligence-bg.WebP",
      desc: "Our team conducts thorough engineering analysis to ensure your product’s architecture is stable, scalable, and secure. This step refines product functionality to align with business goals and user requirements.",
    },
  ];

  const customCard = {
    backgroundColor: "white",
    border: "1px solid black",
    borderRadius: "8px",
    padding: "3px",
    minHeight: "170px",
  };

  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="Product, process, and data engineering services"
        />
        <meta
          name="description"
          content="Discover how Orbio Solutions leverages data and artificial intelligence to enhance your business operations, drive innovation, and deliver insightful solutions."
        />
        <meta
          property="og:title"
          content="Innovative Product Engineering Services for Digital Success"
        />
        <meta
          property="og:description"
          content="Discover innovative product engineering solutions, including software development, validation, and optimization. Build future-proof digital products with Orbio Solutions."
        />
        <script type="application/ld+json">
          {JSON.stringify(serviceSchema)}
        </script>
      </Helmet>
      <CommonBannerImage backgroundImageURL="/assets/product-engineering-bg.WebP">
        <div className={styles.overlay}></div>
        <div className={styles.textContainer}>
          <h1>
            Transform Your Vision into Reality with Expert Product Engineering
            Services
          </h1>
          <h6>
            Unlock the potential of your digital products with our cutting-edge
            product engineering services. We design innovative, scalable
            solutions that meet your business needs from ideation to deployment.
            Elevate your software development with top-tier expertise and
            future-proof technology.
          </h6>
        </div>
      </CommonBannerImage>
      <div className="mt-2">
        <Container>
          <Row>
            <Col md={6} className={styles.anlyseContainer}>
              <h3 className="mb-1 fs-3 text-black">
                Product Engineering Services:
              </h3>
              <h5 className="mb-3 overViewTitle fs-5">
                <GoDash style={{ color: "#007fc7" }} />
                Building Future-Ready Digital Solutions
              </h5>
              <p>
                At Orbio Solutions, our{" "}
                <Link to="/">Product Engineering Services</Link> are designed to
                create robust, scalable, and innovative digital solutions. We go
                beyond traditional development methods by embracing the latest
                in low-code/no-code platforms, cloud-based solutions, and
                enterprise software engineering. Our end-to-end services ensure
                that your product is built to last, maintaining relevance and
                efficiency in the ever-changing technology landscape.
              </p>
            </Col>
            <Col
              md={6}
              className={`${styles.anlyseImgContainer} d-flex justify-content-center align-items-center `}
            >
              <Image
                alt="product-engineering"
                src="/assets/robotic-process-automation-bg.WebP"
                fluid
                style={{ objectFit: "contain", height: "90%", width: "100%" }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div>
        <CommonCards
          cardData={cardData}
          title={"Our Process for Success  "}
          col={4}
        />
      </div>

      <div className="mt-4">
        <Container>
          <StripBanner
            title="Software Product Engineering with a Focus on Innovation"
            desc={`<p>
          At Orbio Solutions, we believe in blending creativity with technical expertise. 
          Our <a target="_blank" rel="noreferrer" href="https://www.ibm.com/topics/product-engineering#:~:text=Product%20engineering%20can%20refer%20to,engineering%20involves%20a%20multidisciplinary%20approach.">software product engineering services</a> provide businesses with custom, future-proof solutions that optimize operations. Whether it's mobile apps, cloud platforms, or enterprise-grade software, we specialize in low-code/no-code development to <span className="fw-bold" >speed up product delivery</span> while reducing cost.
          </p>
          `}
          />
        </Container>
      </div>

      <div className="mt-4 mb-4">
        <Container>
          <h3 className="text-black">
            Ensuring Longevity and Excellence with Post-Launch Support{" "}
          </h3>
          <Row>
            <Col md={6} className="mb-2 mb-md-0">
              <Card style={customCard}>
                <Card.Body>
                  <Card.Title className="fs-5 overViewTitle">
                    <GoDash style={{ color: "#007fc7" }} /> Post-Launch
                    Optimization and Continuous Support
                  </Card.Title>
                  <Card.Text className="textJustify" style={{ color: "black" }}>
                    Our relationship doesn’t end at launch. We provide ongoing
                    optimization and support to ensure your product remains
                    relevant and competitive in the market. This includes
                    regular updates, performance improvements, and scalability
                    assessments.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card style={customCard}>
                <Card.Body style={{ color: "black" }}>
                  <Card.Title className="fs-5 overViewTitle">
                    <GoDash style={{ color: "#007fc7" }} /> Scalability for
                    Future Growth
                  </Card.Title>
                  <Card.Text className="textJustify">
                    As your business grows, so should your product. We build
                    scalable solutions that evolve with your organization,
                    ensuring long-term efficiency and adaptability.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <ServiceKeyPoints
        heading="Key Features of Our Product Engineering Services:"
        keyPoints={keyPoints}
      />

      <div className="mt-4 mb-4">
        <Container className="d-flex flex-column gap-3">
          <h2 className="text-black">
            Why Choose Orbio Solutions for Your Product Engineering Needs?
          </h2>
          <Row>
            <Col md={6}>
              <Card
                style={{
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                }}
              >
                <Card.Body>
                  <Card.Title>
                    <h5 style={{ fontSize: "16px" }} className="fw-bold">
                      Proven Expertise
                    </h5>
                  </Card.Title>
                  <Card.Text style={{ fontSize: "14px" }}>
                    With years of experience across industries, our product
                    development services deliver unmatched quality and
                    precision.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card
                style={{
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                }}
              >
                <Card.Body>
                  <Card.Title>
                    <h5 style={{ fontSize: "16px" }} className="fw-bold">
                      Innovative Solutions
                    </h5>
                  </Card.Title>
                  <Card.Text style={{ fontSize: "14px" }}>
                    We stay on top of emerging technologies, offering you access
                    to the latest tools for enhanced product functionality.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="d-flex justify-content-center">
            <Col md={6}>
              <Card
                style={{
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                }}
              >
                <Card.Body>
                  <Card.Title>
                    <h5 className="fw-bold" style={{ fontSize: "16px" }}>
                      Comprehensive Approach
                    </h5>
                  </Card.Title>
                  <Card.Text style={{ fontSize: "14px" }}>
                    From initial concept to post-launch, we cover every aspect
                    of the product lifecycle with dedication and detail.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <ServiceMainContent caseStudy={caseStudy} />

      <div className="mt-4">
        <Container>
          <StripBanner
            title="How We Revolutionized Process Engineering in the Legal Industry"
            desc={`<p>
              Orbio Solutions played a pivotal role in <span class="fw-bold" >transforming legal operations</span> through tailored product engineering. 
              From <span class=""fw-bold >data tracing</span> to <span class="fw-bold" >scheduler advancements</span>, we delivered a custom application that significantly enhanced efficiency and security for legal professionals. Read our full case study to learn more about how we revolutionized the legal industry.
          </p>
          `}
          />
        </Container>
      </div>

      <div className="mt-5">
        <Container>
          <h2 className="text-black">
            Our Specialization in Product Engineering Solutions
          </h2>
          <p>
            We specialize in a wide array of product engineering solutions,
            including but not limited to:
          </p>
          <Row className="mt-4">
            <Col md={6} className="mb-3">
              <Card
                className="h-100 border-0 p-4"
                style={{
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                }}
              >
                <Card.Body>
                  <div className="d-flex align-items-center mb-1">
                    <FaCogs style={{ fontSize: "30px" }} />
                    <Card.Title className="mb-0 px-1">
                      <h3 style={{ fontSize: "20px" }}>
                        Low-Code/No-Code Platforms
                      </h3>
                    </Card.Title>
                  </div>
                  <Card.Text>
                    Accelerating product development with reduced complexity.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} className="mb-3">
              <Card
                className="h-100 border-0 p-4"
                style={{
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                }}
              >
                <Card.Body>
                  <div className="d-flex align-items-center mb-1">
                    <FaCloud style={{ fontSize: "25px" }} />
                    <Card.Title className="mb-0 px-1">
                      <h3 style={{ fontSize: "20px" }}>
                        Cloud-Based Applications
                      </h3>
                    </Card.Title>
                  </div>
                  <Card.Text>
                    Ensuring scalability, flexibility, and security with
                    cloud-native solutions.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md={6} className="mb-3">
              <Card
                className="h-100 border-0 p-4"
                style={{
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                }}
              >
                <Card.Body>
                  <div className="d-flex align-items-center mb-1 ">
                    <FaBuilding style={{ fontSize: "25px" }} />
                    <Card.Title className="mb-0 px-1">
                      <h3 style={{ fontSize: "20px" }}>Enterprise Software</h3>
                    </Card.Title>
                  </div>
                  <Card.Text>
                    Building robust enterprise-grade applications that enhance
                    business processes.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} className="mb-3">
              <Card
                className="h-100 border-0 p-4"
                style={{
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                }}
              >
                <Card.Body>
                  <div className="d-flex align-items-center mb-1 ">
                    <FaMobileAlt style={{ fontSize: "25px" }} />
                    <Card.Title className="mb-0 px-1">
                      <h3 style={{ fontSize: "20px" }}>Digital Mobility</h3>
                    </Card.Title>
                  </div>
                  <Card.Text>
                    Offering highly scalable and mobile-optimized solutions for
                    a wide range of industries.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <ContactFormService
        caseStudyLength={1}
        title="We are ready to partner with you on your next project. Reach out to learn how our product engineering services can transform your ideas into successful digital solutions."
      />
    </>
  );
};

export default ProductEngineering;
