import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const ProductWebsiteCounts = ()=> {
  return (
    <div>
      <Container>
        <Row>
          <Col
            xs={5}
            style={{
              borderBottom: "1px solid #c3c3c3",
              borderTop: "1px solid #c3c3c3",
              borderRight: "1px solid #c3c3c3",
            }}
          >
            <div style={{ padding: "25px", textAlign: "center" }}>
              <h2 style={{ fontWeight: "bold" }}>100+</h2>
              <span>Websites monitored daily</span>
            </div>
          </Col>
          <Col
            xs={7}
            style={{
              borderBottom: "1px solid #c3c3c3",
              borderTop: "1px solid #c3c3c3",
            }}
          >
            <div style={{ padding: "25px", textAlign: "center" }}>
              <h2 style={{ fontWeight: "bold" }}>50+</h2>
              <span>Locations tracked each month</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={7}>
            <div
              style={{
                padding: "35px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <h2 style={{ fontWeight: "bold" }}>
                If it's on the page, we can collect it.
              </h2>
              <span>
                Discover the effortless convenience of OttixHow's data
                collection capabilities. Our platform empowers you to
                effortlessly gather a wide range of information, ensuring that
                valuable data is at your fingertips. Whether it's product
                details or pricing information, OttixHow makes it simple to
                collect and centralise the data you need.
              </span>
            </div>
          </Col>
          <Col sm={5}>
            <Row style={{ display: "flex", flexDirection: "column" }}>
              <Col
                xs={6}
                style={{
                  borderBottom: "1px solid #c3c3c3",
                  borderLeft: "1px solid #c3c3c3",
                  width: "100%",
                }}
              >
                <div style={{ padding: "35px", textAlign: "center" }}>
                  <h2 style={{ fontWeight: "bold" }}>50,000+</h2>
                  <span>Product attributes</span>
                </div>
              </Col>
              <Col
                xs={6}
                style={{ borderLeft: "1px solid #c3c3c3", width: "100%" }}
              >
                <div style={{ padding: "35px", textAlign: "center" }}>
                  <h2 style={{ fontWeight: "bold" }}>500+</h2>
                  <span>Categories</span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ProductWebsiteCounts;
