import React, { useState } from "react";
import "./reviewsection.css";
import { FaQuoteLeft } from "react-icons/fa6";
import { FaQuoteRight } from "react-icons/fa6";
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { Button, Container } from "react-bootstrap";

const ReviewSection = ()=> {
  const reviewData = [
    {
      id: "1",
      desc: "Orbio Solutions (Orbio) has been our key partner for over eight years, consistently delivering exceptional expertise and dedication. Their analytics solutions have greatly contributed to the success of our marketing efforts. The quality of service and attention to detail provided by them is truly commendable.",
      image: "/assets/john.WebP",
      userName: "John Gallagher",
      designation:"CEO - Global Pricing Solutions, USA"
    },
    {
      id: "2",
      desc: "Thanks to Orbio Solutions, we successfully implemented LIMS, tailored to our needs, streamlining our laboratory operations significantly. Their team's ongoing support has been commendable, making our technicians' work easier. I highly recommend Orbio Solutions for their expertise in application development.",
      image: "/assets/dhilshad.WebP",
      userName: "Dhilshad M",
      designation:"Head of IT and Communication - Dubai Equine Hospital, UAE"
    },
    {
      id: "3",
      desc: "We joined forces with Orbio Solutions three years ago following dissatisfaction with our previous developer's performance. Orbio conducted an in-depth analysis of our software landscape, swiftly addressing our concerns and have since remained instrumental in enhancing our product. With our recent venture into developing a new cloud-based application, there's no one else we'd rather collaborate with!",
      image: "/assets/pegeen.WebP",
      userName: "Pegeen Turner",
      designation:"Co-Founder and President - Firm Data, Inc, USA"
    },
    {
      id: "4",
      desc:"Striving to maintain competitiveness in the buy box position for our entire product range was a challenge, given competitors' frequent price adjustments. OttixHow has simplified this process remarkably. With a single click, we can now instantly monitor competitor pricing moves and swiftly implement tactics to offer the best value-for-money to our customers.",
      image: "/assets/zanil.WebP",
      userName: "Mohammed Zanil",
      designation:"Director - AE Digital, UAE"
    },
    {
      id: "5",
      desc: "I highly endorse Orbio for their outstanding proficiency in creating the secure data exchange software. Their team thoroughly  grasped our needs, and  provided a sturdy and dependable solution. Orbio's dedication to security protocols surpassed our expectations, guaranteeing the confidentiality and integrity of our sensitive data. Their professionalism and prompt communication during the development phase made collaborating with Orbio an effortless endeavor.",
      image: "/assets/dummy-profile2.WebP",
      userName: "Rajeev",
      designation:"Director - RCVK, Australia"
    },
    {
      id: "6",
      desc: "I highly recommend Rakesh and his team for their invaluable assistance in getting our website LIVE. They skillfully guided us back on track while we were earlier headed in wrong direction loosing out on the spend along with the time delays. They guided us with best possible solution given the situation and steered us in the right direction. Their exceptional communication, willingness to delve deep into challenges, and the impeccable outcome built immense trust in their capabilities. Wishing them continued success in their future endeavors. And look forward to working more with the team for sure.",
      image: "/assets/divya-img.WebP",
      userName: "Divya Makin",
      designation:"Founder - PanachebyDivya.com, Australia",
      url: 'https://panachebydivya.com/'
    },
  ];

  const middleIndex = Math.floor(reviewData.length / 2);
  const [selectedDesc, setSelectedDesc] = useState(reviewData[middleIndex].desc);
  const [selectedDesig, setSelectedDesig] = useState(reviewData[middleIndex].designation);
  const [selectedUserName, setSelectedUserName] = useState(reviewData[middleIndex].userName);
  const [selectedId, setSelectedId] = useState(reviewData[middleIndex].id);
  const [selectedUrl, setSelectedUrl] = useState(reviewData[middleIndex].url || "");
  const [selectedItemIndex, setSelectedItemIndex] = useState(middleIndex);

  const handleImageClick = (id, desc, index,designation,userName, url) => {
    setSelectedDesc(desc);
    setSelectedId(id);
    setSelectedItemIndex(index);
    setSelectedDesig(designation)
    setSelectedUserName(userName)
    setSelectedUrl(url || "")
  };

  const handlePrevious = ()=>{
    const newIndex = selectedItemIndex === 0 ? reviewData.length - 1 : selectedItemIndex - 1;
    setSelectedDesc(reviewData[newIndex].desc);
    setSelectedDesig(reviewData[newIndex].designation);
    setSelectedUserName(reviewData[newIndex].userName);
    setSelectedId(reviewData[newIndex].id);
    setSelectedItemIndex(newIndex);
    setSelectedUrl(reviewData[newIndex].url || "")
  }
  const handleNext = ()=>{
    const newIndex = selectedItemIndex === reviewData.length - 1 ? 0 : selectedItemIndex + 1;
    setSelectedDesc(reviewData[newIndex].desc);
    setSelectedDesig(reviewData[newIndex].designation);
    setSelectedUserName(reviewData[newIndex].userName);
    setSelectedId(reviewData[newIndex].id);
    setSelectedItemIndex(newIndex);
    setSelectedUrl(reviewData[newIndex].url || "")
  }

  return (
    <div style={{ background: "#f3f0eb", padding: "12px", minHeight:"50vh", overflowY: "auto" }}>
      <Container>
      <div className="mb-5" style={{display:"flex",justifyContent:"center",alignItems:"center",minHeight:"196px"}}>
        <h5 className="text-center reviewDescrip" style={{}}>
        <FaQuoteLeft style={{marginTop:"-13px"}} /> {selectedDesc} <FaQuoteRight style={{marginTop:"-13px"}} />
        </h5>
      </div>
        <hr style={{ width: "100%", margin: "0 auto",marginTop:"-74px" }} />
        <div
          className="reviewmainContainer">
            {reviewData.map((obj, index) => (
              <div
                key={obj.id}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  opacity: selectedId === obj.id ? 1 : 0.5,
                  transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
                  transform: `translateX(${(index - selectedItemIndex) * 100}%)`,
                }}
              >
                <img
                  src={obj.image}
                  alt={obj.userName}
                  className="reviewsectionImg"
                  style={{
                    filter: selectedId === obj.id ? "none" : "blur(0.9px)",
                    transition: "filter 0.5s ease-in-out",
                  }}
                  onClick={() => handleImageClick(obj.id, obj.desc, index, obj.designation,obj.userName,obj.url)}
                />
                {selectedId === obj.id && <p className="reviewSectionname" >{obj.userName}</p>}

              </div>
            ))}
            
          </div>
          <div className="mt-3">
          <p className="text-center selecteduserName " >{selectedUserName}</p>
          {
            selectedUrl && selectedUrl?.length >0 ? 
            <p className="text-center selectedDesig" onClick={()=> window.open(selectedUrl, '_blank')} >{selectedDesig}</p>
            :
            <p className="text-center selectedDesig" >{selectedDesig}</p>
          }
          <div style={{display:"flex",justifyContent:"space-between"}} >
            <Button variant="outlined" onClick={handlePrevious} aria-label="previous" ><FaArrowLeft /></Button>
            <Button variant="outlined" onClick={handleNext} aria-label="next" ><FaArrowRight /></Button>
          </div>
          </div>
      </Container>
    </div>
  );
}

export default ReviewSection;
