import React from "react";
import { Container, Image, Row } from "react-bootstrap";
import styles from "./achievementSection.module.css"
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";

const AchievementSection = ()=> {
    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 5,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 5,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 4,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2,
        },
      };
    const images = [
        "Shopify",
        "clutch",
        "goodfirms",
        "googlepartner",
        "iso",
        "Sortlist",
    ]
  return (
    <div className="mt-5" >
      <Container fluid>
        <Row>
          <h4 className={styles.heading} ><span className={styles.redUnderline}>Our</span> <span style={{color:'#007fc7'}} >Partners</span> and <span style={{color:'#007fc7'}} >Reviews</span></h4>
        </Row>
        <Row className={`${styles.carouselRow} mt-2`}>
        <Carousel
        style={{ display: "flex", justifyContent: "center",alignItems:"center" }}
        autoPlaySpeed={3000}
        autoPlay={true}
        customTransition="transform 500ms ease-in-out" 
        transition={500} 
        transitionDuration={500} 
        arrows={false}
        infinite={true}
        responsive={responsive}
      >
        {images?.map((image, index) => (
        <div key={index} style={{ display:'flex',justifyContent:"center",alignItems:"center",textAlign:"center" }}>
            <Image className={styles.img} variant="top" src={`/assets/${image}.WebP`} alt={image} />
        </div>
        ))}
      </Carousel>
        </Row>
      </Container>
    </div>
  );
}

export default AchievementSection;
