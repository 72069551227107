import React,{useEffect,useState} from "react";
import CommonBannerImage from "../../components/CommonBannerImage";
import { Card, Col, Container, Image, Row, Form, Table } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styles from "./careers.module.css";
import { FaArrowRightLong } from "react-icons/fa6";
import jobData from "../../db/jobs.json";
import { useNavigate } from "react-router-dom";

const Careers = () => {

    let allImages = [
        {id:"1",img:'/assets/culture/image1.WebP',loc:"Kozhikode"},
        {id:"2",img:'/assets/culture/image2.WebP',loc:"Bengaluru"},
        {id:"3",img:'/assets/culture/image3.WebP',loc:"Bengaluru"},
        {id:"4",img:'/assets/culture/image4.WebP',loc:"Bengaluru"},
        {id:"5",img:'/assets/culture/image5.WebP',loc:"USA"},
        {id:"6",img:'/assets/culture/image6.WebP',loc:"Bengaluru"},
        {id:"7",img:'/assets/culture/image7.WebP',loc:"Bengaluru"},
        {id:"8",img:'/assets/culture/image8.WebP',loc:"Bengaluru"},
        {id:"9",img:'/assets/culture/image9.WebP',loc:"Bengaluru"},
        {id:"10",img:'/assets/culture/image10.WebP',loc:"Bengaluru"},
        {id:"11",img:'/assets/culture/image11.WebP',loc:"Bengaluru"},
        {id:"12",img:'/assets/culture/image12.WebP',loc:"Bengaluru"},
        {id:"13",img:'/assets/culture/image13.WebP',loc:"Bengaluru"},
        {id:"14",img:'/assets/culture/image14.WebP',loc:"Bengaluru"},
    ]
    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
    };
    let keyValue = [
        {id:"0",title:"Industry expertise",desc:"Be guided by seasoned technology evangelists and industry experts",img:"/assets/industry-expertise.WebP"},
        {id:"1",title:"Competitive packages",desc:"Work at the most dynamic workplaces with competitive remunerations",img:"/assets/competitive-packages.WebP"},
        {id:"2",title:"Remote work possibilities",desc:"Some of our lead positions are available with remote work possibilities",img:"/assets/work-from-home.WebP"},
        {id:"3",title:"Work life balance",desc:"Give equal priority to your career and personal life",img:"/assets/work-life-balance.WebP"},
        {id:"4",title:"Employee centricity",desc:"We believe that our employees are the driving force of success",img:"/assets/employee-centricity.WebP"},
        {id:"5",title:"Career growth",desc:"We advocate on the job skilling to ensure that employees achieve their ambitions with us",img:"/assets/career-growth.WebP"},
    ]

    const navigate = useNavigate()
    const [allJobs,setAllJobs] = useState([])
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [selectedJobType, setSelectedJobType] = useState('All Job Type');
    const [selectedLocation, setSelectedLocation] = useState('All Job Location');

    useEffect(() => {
      setAllJobs(jobData)
      setFilteredJobs(jobData)
    }, [])

    const uniqueJobTypes = Array.from(new Set(allJobs?.map((obj) => obj?.jobType)));
    const uniqueLocations = Array.from(new Set(allJobs?.flatMap((obj) => obj?.locations)));

    const handleJobTypeChange = (event) => {
      setSelectedJobType(event.target.value);
      filterJobs(event.target.value, selectedLocation);
    };
  
    const handleLocationChange = (event) => {
      setSelectedLocation(event.target.value);
      filterJobs(selectedJobType, event.target.value);
    };

    const filterJobs = (jobType, location) => {
      const filtered = allJobs.filter((job) => {
        const matchJobType = jobType === 'All Job Type' || job.jobType === jobType;
        const matchLocation = location === 'All Job Location' || job.locations.includes(location);
        return matchJobType && matchLocation;
      });
  
      setFilteredJobs(filtered);
    };
    

  return (
    <>
      <CommonBannerImage backgroundImageURL="/assets/career-bg.WebP">
      <div className={styles.overlay}></div>
        <div className={styles.textContainer}>
          <h1>Work with us</h1>
          <h6>
            Be a part of the fastest growing company and join the vibrant team
            to find fulfillment in sharing your ideas, creativity and working together.
          </h6>
        </div>
      </CommonBannerImage>
      <div className="mt-5">
        <Container>
          <Row>
            <Col>
              <h4 style={{ fontSize: "16px", color: "#007fc7" }}>{'// OUR CAREER'}</h4>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <h2 className={styles.heading}>
                Your Partner for Software Innovation
              </h2>
              <p className={styles.desc}>
                Equipped with an evolving Center of Excellence, we employ the
                industry’s best practices combined with the latest technologies
                to enable enterprise agility in the digital age. We’ll go into a
                lot more detail when speaking with you, but our core values are:
              </p>
              <ul className={styles.lists}>
                <li>OWNERSHIP</li>
                <li>THINKING LONG TERM</li>
                <li>CURIOSITY & CONTINUOUS IMPROVEMENT</li>
                <li>THOUGHTFUL COMMUNICATION</li>
                <li>TRANSPARENCY AND HONESTY</li>
              </ul>
            </Col>
            <Col md={6} className={styles.imgcontainer}>
              <Image alt="career" src="/assets/career-header-img.WebP" fluid style={{ objectFit: "contain" }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="mt-5" style={{ position: 'relative', zIndex: '1' }}>
        <Container>
          <Row className='text-center' >
            <Col>
              <h2 className={styles.heading}>Our Culture</h2>
            </Col>
          </Row>
          <Row>
            <Col>
            <Carousel swipeable={true} draggable={true} infinite={true} keyBoardControl={true} responsive={responsive}>
            {
                allImages?.map((obj)=>(
                <div key={obj?.id} style={{paddingTop: '60%', position: 'relative',}}>
                <img src={obj?.img} alt={obj?.loc} className={styles.cultureImg} />
                <div className={styles.imgLocContainer}  >
                  <span style={{backgroundColor: 'rgba(0, 0, 0, 0.7)',width:"90%",fontSize:"12px"}}>{obj?.loc}</span>
                </div>
                </div>
                ))
             }
            </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="mt-5">
        <Container>
          <Row>
            <Col>
              <h4 style={{ fontSize: "16px", color: "#007fc7" }}>{'// OUR VALUES'}</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className={styles.heading}>Benefits of working with us</h2>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="mt-3">
      <Container>
        <Row style={{display:"flex",justifyContent:"center",}} >
            {
                keyValue.map((item)=>(
                    <Col key={item.id} md={4}>
                        <Card style={{ marginBottom:"12px",border:"none" }}>
                        <div style={{ display: "flex" }}>
            <div style={{ display: "flex", padding: "5px",paddingTop:"0px",paddingRight:"4px",marginTop:"12px" }}>
              <img
              height="35"
              width="35"
                src={item.img}
                alt="img"
              />
            </div>
            <div style={{ flex: "1" }}>
              <h4 style={{ marginTop: "6px",fontSize:"22px",color:"#1b1d21",fontWeight:"bold" }}>
                {item.title}
              </h4>
              <p style={{ marginTop: "15px",color:"#7a7e83" }}>
                {item.desc}
              </p>
            </div>
          </div>
            </Card>
            </Col>
            ))
                }
        </Row>
      </Container>
    </div>
    <div className="mt-3 mb-3" >
      <Container>
        <Row>
          <Col xs={12} >
          <h2 className={`${styles.heading} text-center`}>Open Positions</h2>
          </Col>
          <Col xs={12} >
          <p className={`text-center`} style={{color:"#6d6d6d"}} >Providing a great chance to embark on a promising career. Come join us in reshaping the future</p>
          </Col>
        </Row>
        <Row className={styles.filterrow} >
          <Col lg={3} md={4} xs={12}>
          <Form.Select id="jobtype" onChange={handleJobTypeChange}
              value={selectedJobType} className={styles.inpfield} aria-label="Default select example">
            <option>All Job Type</option>
            {uniqueJobTypes.map((jobType, index) => (
              <option key={index}>{jobType}</option>
            ))}
          </Form.Select>
          </Col>
          <Col lg={3} md={4} xs={12} >
          <Form.Select onChange={handleLocationChange}
              value={selectedLocation} id="jobloc" className={styles.inpfield} aria-label="Default select example">
            <option>All Job Location</option>
            {uniqueLocations?.map((obj, index) => (
              <option key={index}>{obj}</option>
            ))}
          </Form.Select>
          </Col>
        </Row>
      </Container>
    </div>
    <div className="mt-3">
  <Container>
    <Table bordered>
      <tbody>
        {filteredJobs?.length > 0 ? (
          filteredJobs.map((obj, index) => (
            <tr key={index}>
              <td className={styles.tableData} >
                <Container>
                  <Row>
                  <Col md={6}>
                  <h5 onClick={() => navigate(`/jobs/${obj.designation}`)} className={styles.desig} >{obj.designation} <span style={{fontSize:"14px",color:"black"}} >({obj.openings})</span> </h5>
                  </Col>
                  <Col md={6} className={styles.jobActions} > 
                    <div style={{display:"flex"}}>
                    <div style={{ display: "flex" }}>
                      <h6 className={styles.locations}>
                        {obj?.locations?.join(", ")}
                      </h6>
                    </div>
                    </div>
                  <h6 onClick={() => navigate(`/jobs/${obj.designation}`)} className={styles.moredetails} >More Details <FaArrowRightLong /></h6>
                  </Col>
                  </Row>
                </Container>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="2">No job found</td>
          </tr>
        )}
      </tbody>
    </Table>
  </Container>
</div>

    </>
  );
};

export default Careers;
