import React from 'react'
import styles from "./termsConditions.module.css"
import CommonBannerImage from '../../components/CommonBannerImage'
import { Col, Container, Row } from 'react-bootstrap'

const TermsConditions = () => {

  const orbiosolutions = "https://www.orbiosolutions.com";

  return (
    <>
    <CommonBannerImage backgroundImageURL="/assets/terms-conditions-bg.WebP">
    <div className={styles.overlay}></div>
        <div className={styles.textContainer}>
          <h1>Terms & Conditions</h1>
        </div>
    </CommonBannerImage>

    <div style={{ color: "#6d6d6d"}}>
        <Container>
          <Row>
            <Col xs={12}>
              <h4
                className="mt-5"
                style={{
                  fontWeight: "800",
                  fontSize: "24px",
                  color: "#1b1d21",
                }}
              >
                Terms and Conditions
              </h4>

              <h6 className="mt-5" style={{ fontWeight: "700", textDecoration: 'underline' }}>
              Terminology
              </h6>
              <p className="mt-4" style={{ fontSize: "16px" }}>
              The following definitions apply to these Terms and Conditions, the Privacy Statement, the Disclaimer
              Notice, and all Agreements:
              </p>
              <p className="mt-4">
              "Client," "You," and "Your" refer to the individual accessing this website and agreeing to the Company's
              terms and conditions.
              </p>
              <p className="mt-4">
              "The Company," "Ourselves," "We," "Our," and "U" refer to Orbio Solutions.
              </p>
              <p className="mt-4">
              "Party," "Parties," or "Us" refer to both the Client and the Company.
              </p>
              <p className="mt-4">
              Any use of the above terms or other words in the singular, plural, with capitalization, and/or as
pronouns such as he/she or they, are considered interchangeable and thus refer to the same entities.
              </p>
              <h6 className="mt-5" style={{ fontWeight: "700", textDecoration: 'underline' }}>
                Cookies
              </h6>
              <p className="mt-4">
              We use cookies on our website. By accessing www.orbiosolutions.com, you consent to the use of
cookies as outlined in Orbio Solutions&#39; Privacy Policy. Cookies help enable certain functionalities of our
website, making it more user-friendly for visitors.
              </p>
              <h6 className="mt-5" style={{ fontWeight: "700", textDecoration: 'underline' }}>
              License
              </h6>
              <p className="mt-4">
              Unless otherwise specified, Orbio Solutions and/or its licensors hold the intellectual property rights for
all content on www.orbiosolutions.com. All rights are reserved. You may access this content from
www.orbiosolutions.com for your personal use, subject to the restrictions outlined in these terms and
conditions.
              </p>
              <h6 className="mt-5" style={{ fontWeight: "700", textDecoration: 'underline' }}>
              You must not:
              </h6>
              <ul>
                <li>Republish material from <a href={orbiosolutions} target="_blank" rel="noreferrer" >www.orbiosolutions.com</a></li>
                <li>Sell, rent, or sub-license material from <a href={orbiosolutions} target="_blank" rel="noreferrer" >www.orbiosolutions.com</a></li>
                <li>Reproduce, duplicate, or copy material from <a href={orbiosolutions} target="_blank" rel="noreferrer" >www.orbiosolutions.com</a></li>
                <li>Redistribute content from <a href={orbiosolutions} target="_blank" rel="noreferrer" >www.orbiosolutions.com</a></li>
                <li>This Agreement begins on the date hereof.</li>
              </ul>
              <h6 className="mt-5" style={{ fontWeight: "700", textDecoration: 'underline' }}>
                Blogs
              </h6>


              <p className="mt-4">
              Orbio Solutions does not filter, edit, publish, or review comments on its blogs before they appear on the
website. Comments represent the views and opinions of the individual posting them and do not reflect
those of Orbio Solutions, its agents, or affiliates. To the extent permitted by applicable law, Orbio Solutions is not liable for the comments or for any liability, damages, or expenses resulting from the use,
posting, or appearance of comments on the website.
              </p>
              <p className="mt-4">
              Orbio Solutions reserves the right to monitor all comments and remove any that are inappropriate,
              offensive, or violate our Terms and Conditions.
              </p>
              <h6 className="mt-5" style={{ fontWeight: "700"}}>
              You warrant and represent that:
              </h6>
              <ol>
                <li>You confirm you have the right to post your comments and any necessary permissions for their
                content.</li>
                <li>Your comments must not violate anyone else's intellectual property, like copyrights, patents, or
                trademarks.</li>
                <li>
                Keep your comments free of insults, defamation, offensive language, or anything illegal or that
                might invade someone's privacy.
                </li>
                <li>This platform isn't for advertising or promoting businesses or illegal activities.</li>
                <li>By posting, you grant Orbio Solutions a non-exclusive license to use, modify, and reproduce your
                comments, and to allow others to do the same, in any format or media.</li>
              </ol>

              <h6 className="mt-5" style={{ fontWeight: "700", textDecoration: 'underline' }}>
              Hyperlinking to Our Content
              </h6>
              <p className="mt-4">
              The following organizations can link to our website without needing our permission beforehand:
              </p>
              <ol>
                <li>Government Agencies</li>
                <li>Search Engines</li>
                <li>News Organizations</li>
                <li>Online Directories (as long as they list us similarly to other businesses)</li>
              </ol>
              <h6 className="mt-5" style={{ fontWeight: "700" }}>
              Linking Guidelines:
              </h6>
              <p className="mt-4">
              Links can point to our homepage, specific articles, or other website content, but they must follow these
rules:
              </p>
              <ul>
                <li>No Deception: Links shouldn't mislead visitors about the relationship between our website and
                the linking website.</li>
                <li>No False Endorsement: Links shouldn't create the impression that we endorse or sponsor the
                linking website or its products/services.</li>
                <li>Contextual Relevance: Links should fit naturally within the theme and content of the linking
                website.</li>
              </ul>
              <h6 className="mt-5" style={{ fontWeight: "700" }}>
              Other Approved Links:
              </h6>
              <p className="mt-4">
              We may consider and approve linking requests from organizations that fall into these categories:
              </p>
              <ol>
                <li>Consumer/Business Information Sources (well-known)</li>
                <li>Online Communities (.com sites)</li>
                <li>Charities and Associations</li>
                <li>Online Directories</li>
                <li>Internet Portals</li>
                <li>Professional Firms (accounting, legal, consulting)</li>
                <li>Educational Institutions and Trade Associations</li>
              </ol>
              <p className="mt-4">
              We'll approve links based on the following criteria:
              </p>
              <ul>
                <li>Positive Image: The link shouldn't portray Orbio Solutions or our accredited businesses in a
                negative light.</li>
                <li>Good Standing: The organization requesting the link must have a clean record with us.</li>
                <li>Mutual Benefit: The visibility gained through the link should be valuable to Orbio Solutions.</li>
                <li>Informational Context: The link should be placed within a context of general resource
                information.</li>
              </ul>
              <p className="mt-4">
              Essentially, we're looking for links that enhance our reputation and provide valuable exposure to a
              relevant audience.
              </p>
              <p className="mt-4">
              If you represent an organization from the categories listed in the "Other Approved Links" section and
want to link to our website, please submit a request through email to Orbio Solutions.
              </p>
              <p className="mt-4">
              In your email, include the following information:
              </p>
              <ul>
                <li>Your Name</li>
                <li>Your Organization Name</li>
                <li>Contact Information (phone number or email)</li>
                <li>Your Website URL</li>
                <li>A list of URLs from your site where you plan to place the link(s) to our website</li>
                <li>A list of specific URLs on our website you'd like to link to</li>
              </ul>
              <p className="mt-4">
              We'll review your request within a week and respond with our decision.
              </p>
              <h6 className="mt-5" style={{ fontWeight: "700" }}>
              How to Link to Our Website
              </h6>
              <p className="mt-4">
              Approved organizations can link to our website in these ways:
              </p>
              <ul>
                <li>Using our company name: This is a clear and straightforward option.</li>
                <li>Using the specific URL of the page you're linking to: This allows for targeted linking to relevant
                content.</li>
                <li>Using a descriptive phrase that makes sense in context: The link text should be clear and
                relevant to the content on the linking website.</li>
              </ul>
              <p className="mt-4">
              Important Note: You cannot use our logo or other artwork to link to our website without a separate
trademark license agreement.
              </p>
              <h6 className="mt-5" style={{ fontWeight: "700" }}>
              iFrames
              </h6>
              <p className="mt-4">
              Embedding our website content using iFrames is not allowed without our prior written consent. This is
because we want to ensure the visual presentation and user experience of our website remains
consistent.
              </p>
              <h6 className="mt-5" style={{ fontWeight: "700" }}>
              Content Liability
              </h6>
              <p className="mt-4">
              We are not responsible for the content on any website that links to ours. You, the owner of the linking
website, are responsible for ensuring your website's content complies with all laws and respects third-
party rights. This includes avoiding content that is libelous, obscene, criminal, or infringes on copyrights,
trademarks, or other intellectual property.
              </p>
              <h6 className="mt-5" style={{ fontWeight: "700" }}>
              Your Privacy
              </h6>
              <p className="mt-4">
              Please read our Privacy Policy.
              </p>
              <h6 className="mt-5" style={{ fontWeight: "700" }}>
              Right to Remove Links and Modify Policy
              </h6>
              <p className="mt-4">
              We reserve the right to request the removal of all links or specific links to our website at any time. You
agree to promptly remove any links upon our request. We may also update these linking terms and
conditions. By continuing to link to our website, you agree to be bound by the most current version of
these terms.
              </p>
              <h6 className="mt-5" style={{ fontWeight: "700" }}>
              Removal of Links from Our Website
              </h6>
              <p className="mt-4">
              If you find any link on our Website that is offensive for any reason, you are free to contact and inform us
at any moment. We will consider requests to remove links, but we are not obligated to do so or to
respond to you directly.
              </p>
              <p className="mt-4">
              We do not ensure that the information on this website is correct, complete, or accurate; nor do we
promise to ensure that the website remains available or that the material on the website is kept up to
date.
              </p>
              <h6 className="mt-5" style={{ fontWeight: "700", textDecoration: 'underline' }}>
              Service Updates and Availability
              </h6>
              <p className="mt-4">
              We reserve the right to modify, suspend, or discontinue our service, or any part of it, at any time
without prior notice. We will not be held liable for any service interruptions or downtime. We may also
occasionally restrict access to certain features or the entire service for specific users, including
registered users.
              </p>

            </Col>
          </Row>
        </Container>
          <Container fluid>
          <Row className='mt-4'>
                <hr />
          </Row>
          </Container>
      </div>
    </>
  )
}

export default TermsConditions