import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import styles from "./productHelp.module.css";

const ProductHelpAccordion = ()=> {
  const [activeCard, setActiveCard] = useState(""); // Set the initial active card to '0'

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      setActiveCard(activeCard === eventKey ? null : eventKey);
    });

    return (
      <div
        type="div"
        onClick={decoratedOnClick}
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          color: "white",
        }}
      >
        {children}
        {activeCard === eventKey ? (
          <BsChevronUp style={{ marginLeft: "auto", color: "#007fc7" }} />
        ) : (
          <BsChevronDown style={{ marginLeft: "auto" }} />
        )}
      </div>
    );
  }

  const getCardBorderStyle = (eventKey) => {
    return activeCard === eventKey ? "1px solid #007fc7" : "none";
  };

  return (
    <div className="pb-5" style={{ background: "#061321" }}>
      <Container>
        <Row style={{display:"flex",justifyContent:"center",alignItems:"center"}} >
          <Col md={6}>
            <div className="mt-5">
              <h2 style={{ color: "white",paddingBottom:"0.8em" }}>How we can help you:</h2>
            </div>
            <Accordion activeKey={activeCard}>
              <Card
                className={styles.cardstyle}
                style={{ borderLeft: getCardBorderStyle("0") }}
              >
                <Card.Header
                  style={{ borderBottom: "none", background: "none" }}
                >
                  <CustomToggle eventKey="0">Data Collection</CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body style={{ color: "white" }}>
                    Users can conveniently upload data according to their
                    specific needs, whether it’s individual files or bulk
                    uploads, providing flexibility and ease of data integration.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card
                className={styles.cardstyle}
                style={{ borderLeft: getCardBorderStyle("1") }}
              >
                <Card.Header style={{ borderBottom: "none", background: "none" }}>
                  <CustomToggle eventKey="1">Matching</CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body style={{ color: "white" }}>
                    Our AI engine will accurately match your product with
                    competitors, and a quality assurance process guarantees the
                    appropriateness of the results before final approval.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card
                className={styles.cardstyle}
                style={{
                  borderLeft: getCardBorderStyle("3"),
                  borderBottom: "1px solid #656363",
                }}
              >
                <Card.Header style={{ borderBottom: "none", background: "none" }}>
                  <CustomToggle eventKey="3">Data Validation</CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                <Card.Body style={{ color: "white" }}>
                  The data obtained is validated automatically and manually
                  against different algorithms, historical audits, and
                  attributes to ensure completeness and accuracy.
                </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card
                className={styles.cardstyle}
                style={{
                  borderLeft: getCardBorderStyle("4"),
                  borderBottom: "1px solid #656363",
                }}
              >
                <Card.Header style={{ borderBottom: "none", background: "none" }}>
                  <CustomToggle eventKey="4">Data Visualization</CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body style={{ color: "white" }}>
                    Users can access the consolidated results through a
                    centralized dashboard, tailored to their specific
                    requirements, enabling them to gain valuable insights and
                    maintain a competitive edge.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
          <Col className="mt-5" md={6}
  style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }}
>
  <div style={{ width: "100%",display:"flex",justifyContent:"center",alignItems:"center",paddingTop: "8px" }} className="mt-5" > 
    <iframe
      title="video"
      src="https://www.youtube-nocookie.com/embed/HWC_uVT_PBg"
      frameBorder="0"
      allowFullScreen
      style={{width:"100%",height:"250px"}}
    ></iframe>
  </div>
</Col>

        </Row>
      </Container>
    </div>
  );
}

export default ProductHelpAccordion;
