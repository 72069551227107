import React from "react";

const CommonBannerImage = ({ backgroundImageURL, children }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImageURL})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "80vh",
        top: 0,
        display: "flex",
        alignItems: "center",
      }}
    >
      {children}
    </div>
  );
};

export default CommonBannerImage;
