import React from "react";
import styles from "./strip.module.css";

const StripBanner = ({ title, desc }) => {
  return (
    <div
      className={`${styles.orbiowaycontainer} textJustify p-3 mb-4 mt-3`}
      style={{
        borderRadius: "8px",
        // backgroundImage: `url(/assets/orbioway.WebP)`,
      }}
    >
      <h3>{title}</h3>
      <div dangerouslySetInnerHTML={{ __html: desc }} />
    </div>
  );
};

export default StripBanner;
