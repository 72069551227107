import React from "react";
import styles from "./bigDataBlog.module.css";
import CommonBannerImage from "../../components/CommonBannerImage";
import { Button, Col, Container, Row } from "react-bootstrap";
import { RxDotFilled } from "react-icons/rx";
import { FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
import { NavLink, useNavigate } from "react-router-dom";
import { GoArrowLeft } from "react-icons/go";

function BigDataBlog() {

  const navigate = useNavigate()

  const handleTwitterClick = () => {
    const twitterPageUrl =
      "https://twitter.com/intent/tweet?text=Big%20Data%20Helping%20Enterprises&url=https://www.orbiosolutions.com/plan-your-project-with-your-software/";
    window.open(twitterPageUrl, "_blank");
  };
  const handleFacebookClick = () => {
    const facebookPageUrl =
      "https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fsharer%2Fsharer.php%3Fu%3Dhttps%253A%252F%252Fwww.orbiosolutions.com%252Fplan-your-project-with-your-software%252F&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=en_GB";
    window.open(facebookPageUrl, "_blank");
  };

  const handleLinkedinClick = () => {
    const linkedinPageUrl =
      "https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2FshareArticle%3Fmini%3Dtrue%26url%3Dhttps%3A%2F%2Fwww.orbiosolutions.com%2Fplan-your-project-with-your-software%2F%26title%3DBig%2520Data%2520Helping%2520Enterprises%26summary%3Dhttps%3A%2F%2Fwww.orbiosolutions.com%26source%3DOrbio%2520Solutions";
    window.open(linkedinPageUrl, "_blank");
  };

  return (
    <div>
      <CommonBannerImage backgroundImageURL="/assets/Big-Data.WebP">
        <Container>
          <div className={styles.textContainer}>
            <h1>Big Data Helping Enterprises</h1>
          </div>
        </Container>
      </CommonBannerImage>
      <div className='mt-3'>
        <Container>
        <div className="mb-2" >
          <Button onClick={()=>navigate(-1)} variant="outline" className={styles.backArrow} > <GoArrowLeft /> back</Button>
          </div>
            <Row>
                <Col md={8} >
                    <Row>
                <div style={{display:"flex",gap:"12px",width:"100%",flexWrap:"wrap"}} >
                <Button className={styles.btn} >Business Intelligence</Button>
                <Button className={styles.btn} >Cloud Computing</Button>
                <Button className={styles.btn} >Customer Insights</Button>
                <Button className={styles.btn} >Data Analytics</Button>
                </div>
                    </Row>
                    <Row className='mt-4'>
                        <Col xs={8} ><p style={{fontWeight:"bold"}} >Sep 27, 2023 <RxDotFilled /> 6 min read</p></Col>
                        <Col xs={4} > 
                        <div style={{display:"flex",gap:"12px"}} >
                        <FaTwitter onClick={handleTwitterClick} style={{fontSize:"26px",cursor:"pointer",color:"#44b1e4"}} />
                        <FaFacebook onClick={handleFacebookClick} style={{fontSize:"26px",cursor:"pointer",color:"#4661c5"}} />
                        <FaLinkedin onClick={handleLinkedinClick} style={{fontSize:"26px",cursor:"pointer",color:"#0073b0"}} />
                        </div>
                        </Col>
                    </Row>
                    <Row>
                    <div>
      <div>
        <h3 className={styles.heading}>
          Big data , a staunchful bridge to utopia
        </h3>
        <p className={styles.desc}>
          Can we reflect on a world where no sooner did you long for your
          desperate need than it is on your table in a trice ? it would have
          been incoceivable a couple of decades ago. But now it is no more an
          elusive one for each of us as fabulous business intelligence has been
          taking tremendous strides at the expense of traditional praxis,
          profusely pursuing and adamantly aggragating the behavioural pattern
          and broad spectrum of likes and dislikes of the souls of market
          economy; consumers . ent and design flaws many apps simply fail
          because of their pre-launch and post-launch shortcomings.
        </p>
        <p className={styles.desc}>
          You may be astonished by the undeniable fact that Big data is of
          ability to swiftly trace the hearts of customers and consumers . The
          Inscrutable veracity is that Every keyword you search, every click you
          make and every comment you write down in internet is data or
          information. Most strikingly A regular smartphone users would be
          uploading GBs of data to the servers of social networking sites each
          day in the form of text, photos and audio. Every time you add” feeling
          sad “or” feeling excited “ to your status facebook leverages nimbly
          these datas to get an edge for their algorithm for sentiment analysis
          and these data will undergird to spawn new innovative solutions to its
          customers despite it betrays the basic notion of privacy.
        </p>
      </div>
      <div>
        <h3 className={styles.heading}>
          Big data and AI ; two panacea to get the world Yonger
        </h3>
        <p className={styles.desc}>
          Will it be a misconception and illusion to believe that the prevailing
          world has been going through a 4th unprecedented industrial revolution
          that apportioned a novel lease of life to the entire world? Well, the
          scholarly exposures are of the same opinion as humanity are taking by
          paralysis before booming of big data and AI.
        </p>
        <p className={styles.desc}>
          Booming of technological breakthrough and subsequent unimaginable
          inventions of business intelligence was inctrovertibly a game changer
          for not only business but also the entire world. It has breeded
          unimaginable and catch napping advantages and benefits in areas as
          diverse as medicine, agriculture, gambling environmental protection ,
          business and security .Data now plays a huge role in comprehending and
          discriminating invaluable and precisious insights about target
          demographics and customer preferences.
        </p>
      </div>
      <div>
        <h3 className={styles.heading}>
          Big data analytics: a Waving magical wand
        </h3>
        <p className={styles.desc}>
          A paradigm shift of traditional trends in business sector has sent
          astonishing waves to the world as it ushered in an upward trajectory
          of sweeping elevation for the concurrent order . From merely being a
          knowing business organisation to the learning organization, bridging
          the deepening and widening hiatus between customers and companies .
        </p>
        <p className={styles.desc}>
          Today big data analytics and cloud computing are integral parts of
          business organisation as it vital to stay abreast with all the latest
          trends of the world.when Junctures are constantly taking meandering
          zigzags and precipitous steeps in businesses world having on the toes
          to figure out what the future trends are is a must.
        </p>
        <p className={styles.desc}>
          To our great bewilderment, some utopian world orders have been coming
          true with magic wand; big data and AI strategies. It is likely to defy
          your imagination to figure out how Dubai has been in its strenuous bid
          to win a jealous position of being a smartest city of the world ,
          where undoubtedly big datas have a crucial role to play as the city
          will make its presence felt in the world to be a wholly digitalized
          and a paperless frabjous and elegant city , pandering to the needs of
          people as soon as stuffs flash through their mind and it also
          projected that it will garner an expected USD 9.2 billion by just
          couple of years
        </p>
      </div>
    </div>
                    </Row>
                </Col>
                <Col md={4} >
                <div style={{
                position: 'sticky',
                top: '100px', 
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
                marginTop: '80px',
              }} >
                        <div><FaArrowRightLong /><NavLink to="/blog/future-of-ai-and-robotics" className={styles.sublinks} > The Future of Robotics & AI </NavLink></div>
                        <div><FaArrowRightLong /><NavLink to="/blog/things-to-be-aware-of-before-starting-an-ecommerce-business" className={styles.sublinks} > Things To Be Aware of Before Starting An Ecommerce Business </NavLink></div>
                        <div><FaArrowRightLong /><NavLink to="/blog/competitive-data-analytics" className={styles.sublinks} > Competitive Data Analytics </NavLink></div>
                        <div><FaArrowRightLong /><NavLink to="/blog/data-driven-decision-making" className={styles.sublinks} > Data-Driven Decision Making </NavLink></div>
                        <div><FaArrowRightLong /><NavLink to="/blog/driving-sales-and-profits-why-your-retail-business-requires-a-well-constructed-pricing-strategy" className={styles.sublinks} > Driving Sales and Profits: Why your Retail Business Requires a Well-constructed Pricing Strategy? </NavLink></div>
                        <div><FaArrowRightLong /><NavLink to="/blog/strategic-pricing-intelligence-enhancing-business-strategies-with-competitor-pricing-analytics" className={styles.sublinks} > Strategic Pricing Intelligence</NavLink></div>
                        <div><FaArrowRightLong /><NavLink to="/blog/the-rising-importance-of-price-management-and-optimization-for-online-retailers-of-today-and-tomorrow" className={styles.sublinks} > The rising importance of price management and optimization for online retailers of today and tomorrow.</NavLink></div>
                        <div><FaArrowRightLong /><NavLink to="/blog/e-commerce-insights-trends-challenges-winning-strategies" className={styles.sublinks} > E-Commerce Insights: Trends, Challenges, and Winning Strategies</NavLink></div>
                        <div><FaArrowRightLong /><NavLink to="/blog/headless-woocommerce" className={styles.sublinks} > Headless WooCommerce</NavLink></div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
    </div>
  );
}

export default BigDataBlog;
