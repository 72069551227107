import React from "react";
import styles from "./iotCaseStudy.module.css";
import { Container, Row } from "react-bootstrap";
import CommonBannerImage from "../../../components/CommonBannerImage";

const IotDevelpmentCaseStudy = () => {
  return (
    <>
      <CommonBannerImage backgroundImageURL="/assets/iot-background.WebP">
        <div className={styles.overlay}></div>
        <div className={styles.textContainer}>
          <h1>Case-Study: DUBAI EQUINE HOSPITAL</h1>
        </div>
      </CommonBannerImage>
      <div className="mt-5">
        <Container>
          <Row>
            <h4 className="fw-bold">
              Elevating Operations with Expert IoT Consulting and Integration
              with Existing ERP Systems
            </h4>
            <h6 className="fw-bold mt-3">Company Profile:</h6>
            <p>
              The DEH (DUBAI EQUINE HOSPITAL) offers advanced services in equine
              medicine and surgery. Cutting-edge facilities and qualified and
              experienced staff have enabled the hospital to provide excellence
              in the diagnosis and treatment of equine patients (horses) in the
              region.
            </p>
            <h6 className="fw-bold">Situation:</h6>
            <p>
              To enhance operational efficiency, DEH sought to digitize its
              laboratory workflow. The hospital utilizes the Hospital and
              Veterinary Management Software (HVMS) across various departments.
              The Lab Information System (LIS) was identified as a key component
              for seamlessly handling core data transactions, including patient
              information, test details, and invoicing.
            </p>

            <h6 className="fw-bold">Task:</h6>
            <p>
              The hospital faced significant challenges in the manual extraction
              of printouts for all entries, followed by the labor-intensive task
              of manually inputting details into the Enterprise Resource
              Planning (ERP) system. This was a tedious, low-efficiency process
              that was all human-driven that not only consumed valuable time but
              also introduced the risk of errors due to the manual nature of the
              process. Furthermore, losses due to frequent yet less-accurate
              quality checks and loss of quality data meant the existing process
              was far from being fool proof.
            </p>
            <h6 className="fw-bold">Action:</h6>
            <p>
              Orbio Solutions, a technology provider, partnered with DEH to
              restructure the current process for superior accuracy and freedom
              from manual intervention so resources can be used for high-
              priority tasks. By conducting interview deep dives, needs and
              requirements were well understood. The architecture and IoT
              integration work flows were discussed to ensure the client and
              Orbio Solutions travelled on the same boat. A bidirectional
              communication link was established between the DEH’s Lab
              Management System (HVMS) and the testing devices (Advia, Konelab
              and iStat). Furthermore, Orbio Solutions developed a TCP server to
              communicate with Siemens Advia and Thermofisher KoneLab, iStat
              devices for data retrieval.
            </p>

            <h6 className="fw-bold">Result:</h6>
            <p>
              By partnering with Orbio Solutions for consulting and IoT
              integration, our client benefitted from running a simple,
              efficient and intelligent process. Results encompassed:
            </p>
            <li className="fw-bold">
              Reduced data entry time:{" "}
              <span className="fw-normal">
                As Orbio Solutions automated the data transfer from paper
                records to the ERP system; data processing speed was
                significantly improved.
              </span>{" "}
            </li>
            <li className="fw-bold">
              Improved data accuracy:{" "}
              <span className="fw-normal">
                By eliminating manual data entry and ensuring direct transfer of
                information from source documents, human error was reduced to a
                zero.
              </span>
            </li>
            <li className="fw-bold">
              Enhanced efficiency:{" "}
              <span className="fw-normal">
                Streamlined workflows, elimination of redundant tasks and
                paper-based processes all meant human resources were freed from
                mundane, low- efficiency tasks to focus on what really mattered
                the most for DEH.
              </span>
            </li>
            <li className="fw-bold">
              Integrating LIS with existing HVMS:{" "}
              <span className="fw-normal">
                IoT integration provided a cost-effective and efficient solution
                for laboratory workflow digitization.
              </span>
            </li>

            <p className="mt-4">
              Explore the possibilities of seamlessly incorporating new IoT
              devices into your current systems. Reach out to us today to
              initiate the journey towards effectively tackling your IoT
              challenges and realizing tangible results.
            </p>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default IotDevelpmentCaseStudy;
